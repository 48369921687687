var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_c('loader-component')],1):(_vm.numberFilteredSortedFolderBrique > 0)?_c('div',[_c('div',{staticClass:"carousel-title-div"},[_c('div',{staticClass:"carousel-title"},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),_c('div',[(_vm.bfp_acces && _vm.name === 'Échelles d\'évaluation')?_c('bfpComponent'):_vm._e(),(_vm.bfp_acces && _vm.name === 'Échelles d\'évaluation')?_c('edasComponent',{attrs:{"idPatientTherapeute":_vm.idPatientTherapeute},on:{"TerminerExerciceQCM":_vm.handleTerminerExerciceQCM}}):_vm._e()],1),_c('div',{staticClass:"carousel-wrap"},[_c('div',[_c('div',{ref:"carousel",staticClass:"owl-carousel owl-carousel-folder owl-theme"},[_vm._l((_vm.filteredSortedFolderBrique),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"item-folders",class:{
              ActiveImageCarousel:
                _vm.idActive === folderBrique.id && _vm.name === 'Ressources libres',
              InactiveImageCarousel:
                _vm.idActive !== folderBrique.id &&
                _vm.firstBriqueOpen &&
                _vm.name === 'Ressources libres',
            },style:(_vm.name === 'Ressources libres'
                ? { 'background-color': folderBrique.color }
                : {}),on:{"click":function($event){return _vm.recupId(folderBrique)}}},[(_vm.idActive === folderBrique.id)?_c('div',{staticClass:"CroixActiveBrique"},[_vm._v(" X ")]):_vm._e(),(
                folderBrique.name === 'Biofeedback de cohérence cardiaque' || folderBrique.name ==='Indice de Lâcher-Prise'
              )?_c('div',{staticClass:"IconBriqueMoniteur",on:{"click":function($event){return _vm.goToSymbiosensor()}}},[_c('img',{attrs:{"src":require("../../../../assets/img/IconMoniteur.png"),"alt":"image-folders"}})]):_vm._e(),(
                _vm.name === 'Programmes' || _vm.name === 'Échelles d\'évaluation'
              )?_c('img',{class:{
                ActiveImageCarousel: _vm.idActive === folderBrique.id,
                InactiveImageCarousel:
                  _vm.idActive !== folderBrique.id && _vm.firstBriqueOpen,
              },attrs:{"src":folderBrique.resourceBg.url,"alt":"image-folders"}}):(_vm.name === 'Ressources libres')?_c('div',{staticClass:"ressourcesDivCarousel",attrs:{"alt":"image-folders"}}):_vm._e(),(_vm.name === 'Programmes')?_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")]),_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(folderBrique.protocoles[0].link.folder.author)+" ")])]):_vm._e(),(
                _vm.name === 'Ressources libres' ||
                _vm.name === 'Échelles d\'évaluation'
              )?_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(folderBrique.name)+" ")])]):_vm._e()])}),(
              _vm.name === 'Échelles d\'évaluation' &&
              _vm.numberOfEvaluationScales === 0
            )?_c('div',{staticClass:"no-evaluation-scales"},[_c('p',[_vm._v("Aucune échelle d'évaluation ne vous a été attribuée")])]):_vm._e()],2),_c('div',{staticClass:"owl-navigation"},[_c('div',{staticClass:"owl-nav"},[_c('div',{staticClass:"owl-prev",class:{ hidden: _vm.numberFilteredSortedFolderBrique === 1 },on:{"click":_vm.prevSlide}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('div',{staticClass:"owl-next",class:{ hidden: _vm.numberFilteredSortedFolderBrique === 1 },on:{"click":_vm.nextSlide}},[_c('i',{staticClass:"fas fa-chevron-right"})])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }