import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export async function getSuiviForUser(userId) {
  ParseConfig();
  var User = Parse.Object.extend("User");
  var queryUser = new Parse.Query(User);
  queryUser.equalTo("objectId", userId);
  var result = await queryUser.find();
  var fileType = Parse.Object.extend("file_type");
  var queryFileType = new Parse.Query(fileType);
  queryFileType.find();

  var FileDone = Parse.Object.extend("file_done");
  var queryFileDone = new Parse.Query(FileDone);
  queryFileDone.limit(500);
  queryFileDone.equalTo("user", result[0]);
  queryFileDone.include("file");
  queryFileDone.include("protocole");
  queryFileDone.include("module");
  queryFileDone.notEqualTo("ANI", true);
  var resultFileDone = await queryFileDone.find();
  var Link = Parse.Object.extend("link");
  var queryLink = new Parse.Query(Link);
  var Folder = Parse.Object.extend("folder");
  var queryFolder = new Parse.Query(Folder);
  // Créer un objet pour stocker les informations des fichiers
  var fileObjects = {};
  for (const element of resultFileDone) {
    console.log('------------')
    console.log('Element....',element)
    var LastDate = element.updatedAt;
    var ParentFolder = [];
    var timeElapsed = element.attributes.time_elapsed;
    // if (timeElapsed < 30) {
    //   continue;
    // }
    if (element.get("file") === undefined || element.get("file") === null) {
      continue;
    }
    var fileId = element.get("file").id;
    var protocole = element.get("protocole");
    if (protocole === undefined || protocole === null) {
      continue;
    }
    queryFolder.equalTo("objectId", protocole.id);
    queryLink.matchesQuery("folder", queryFolder);
    queryLink.include("parent_folder");
    var resultLink = await queryLink.find();
    for (const linkElement of resultLink) {
      ParentFolder.push(linkElement);
    }
    var module = element.get("module");
    console.log('File.......',element);
    var file = element.get("file");
    //inclure les attributes
    console.log("FileTest", file);
    if(file && file.attributes.type && file.attributes.type.attributes.name)
    {
      console.log(file.attributes.type.attributes.name)
    var typeExercice = file.attributes.type.attributes.name;
    }
    else{
      continue;
    }
    console.log('File yes....')
    console.log('module....',module)
    console.log('ParentFolder....',ParentFolder)

    if (
      module.attributes.name === "Entraînement libre" ||
      ParentFolder[0].attributes.parent_folder && ParentFolder[0].attributes.parent_folder.attributes.name ===
        "Entraînement libre"
    ) {
      if (file.attributes.type.attributes.name === "Exercice audio") {
        continue;
      }
      if (fileObjects[fileId]) {
        // Le fileId existe déjà, augmenter le compteur
        fileObjects[fileId].count++;
        // Ajouter le temps passé
        if (timeElapsed) {
          fileObjects[fileId].timeElapsed += timeElapsed;
        }
        // Vérifier si le temps passé est plus grand que le temps passé précédent
        if (LastDate > fileObjects[fileId].LastDate) {
          fileObjects[fileId].LastDate = LastDate;
        }
      } else {
        // Créer un nouvel objet pour le fileId avec un compteur initial de 1
        fileObjects[fileId] = {
          count: 1,
          file: file,
          protocole: ParentFolder[0].attributes.parent_folder,
          module: module,
          timeElapsed: timeElapsed,
          LastDate: LastDate,
          typeExercice: typeExercice,
        };
      }
    } else {
      continue;
    }
  }
  return fileObjects;
}
