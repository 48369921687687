<template>
  <div>
    <button @click="connect" :disabled="connexionWebSocket">
      {{ connexionWebSocket ? "Connecté" : "Se connecter" }}
    </button>
  </div>
</template>

<script>
import useSocketService from "../services/moniteur/socketService.js";

export default {
  name: "SocketConnectionButton",

  setup() {
    const { connexionWebSocket, connectSocket } = useSocketService();

    const connect = () => {
      if (!connexionWebSocket.value) {
        connectSocket();
      }
    };

    return {
      connexionWebSocket,
      connect,
    };
  },
};
</script>

<style scoped>
/* Ajoutez ici les styles pour le bouton */
</style>
