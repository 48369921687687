<template>
    <b-modal
      :width="1200"
      :active.sync="isBienEtre"
      @close="closeModal"
    >
      <div class="card" v-if="userSelected">
        <div>
          <div class="card editUser">
            <div class="card-content">
              <div class="content">
                <div class="overflow">
                  <h3 class="title has-text-black is-size-5">
                    {{ $t("Question à votre référent professionnel") }}
                  </h3>
                  <div>
                    <p>
                      Vous avez-une question sur votre pratique, vous souhaitez
                      partager avec votre professionnel référent ?
                    </p>
                    <b-field>
                      <b-input
                        v-model="mailBienEtre"
                        maxlength="2000"
                        type="textarea"
                      ></b-input>
                    </b-field>
                  </div>
                  <div>
                    <div class="is-pulled-left">
                      <a class="button is-cyan" @click="sendMailBienEtre()">
                        {{ $t("envoyer") }}
                      </a>
                    </div>
                    <div class="is-pulled-right">
                      <a
                        class="is-pulled-right button is-grey is-margin-r-10"
                        @click="closeModal"
                      >
                        {{ $t("annuler") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
  
  <script>
  
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");

  export default {
    data() {
      return {
        isBienEtre: true,
        userSelected: null,
        mailBienEtre: ""
      };
    },
    methods: {
      closeModal() {
        this.isBienEtre = false;
        this.userSelected = null;
      },
      async saveMail() {
      try {
        const Question = Parse.Object.extend("question_footer"); // Define the table
        const questionObject = new Question();

        questionObject.set("questionText", this.mailTechnique);
        questionObject.set("user", this.userSelected);
        questionObject.set("type", "bien-être");
        questionObject.set("statut", false);

        await questionObject.save();
        //console.log("Question submitted successfully");

        // Clear the form and close the modal
        this.mailTechnique = "";
        this.closeModal();
      } catch (error) {
        console.error(
          "An error occurred while submitting the question:",
          error
        );
      }
    },
      async sendMailBienEtre() {
      await Parse.Cloud.run("sendMailContactTherapeutique", {
        destinataire: this.therapeute.attributes.username,
        expediteur: this.user.username,
        messageEcrit: this.mailBienEtre,
      })
        .then((response) => {
          //console.log("E-mail envoyé avec succès.", response);
          this.$buefy.toast.open({
            message: "Votre message a bien été envoyé",
            type: "is-success",
            duration: 5000,
          });
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: "Une erreur est survenue lors de l'envoi de votre message",
            type: "is-danger",
            duration: 5000,
          });
          //console.log("Erreur lors de l'envoi de l'e-mail.", error);
        });
      this.isBienEtre = false;
      try{
        await this.saveMail();
      } catch (error) {
        console.error(
          "An error occurred while submitting the question:",
          error
        );
      }
    },
    },
    async mounted() {
    ParseConfig();
    const currentUser = Parse.User.current();
    this.userSelected = currentUser;
    const queryUser = new Parse.Query(Parse.User);
    const queryGroup = new Parse.Query("user_group");
    queryGroup.equalTo("objectId", currentUser.attributes.group.id);
    queryUser.matchesQuery("group", queryGroup);
    queryUser.equalTo("therapeute", true);
    this.therapeute = await queryUser.first();
  },
    computed: {
    ...User.mapGetters(["user", "group"]),
  },
  };
  </script>