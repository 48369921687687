<template>
  <div style="position: relative">
    <div class="loader-game" v-if="!isGameReadyLoading"></div>
    <VueUnity
      src="../../../assets/game/Build/Build_Unity_2020.loader.js"
      :unity="unityContext"
      width="800"
      height="600"
    />
    <div id="SendDataInfo"></div>
    <div style="display: none" id="ResultatJeux"></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import UnityWebgl from "unity-webgl";
import VueUnity from "unity-webgl/vue";

const User = createNamespacedHelpers("user");
const io = require("socket.io-client");
// remplacer const à la place de let
let Unity = null;

export default {
  components: {
    VueUnity,
  },
  data() {
    return {
      unityContext: null,
      tableau_game: [],
      element_game: [],
      tableau_file: [],
      element_file: [],
      connection: null,
      isGameReady: false,
      rrUnity: "",
      CC_RECUP: 0,
      RR_RECUP: 0,
      tableau_CC: [],
      listeRR: "",
      resultDataJeux: [],
      data_jeux: "",
      send_data_info: [],
      socketValues: [],
      receivedData: "", // Données reçues du serveur
      arrayTacho: [],
      TabCC: [],
      dataUnity: "",
      socket: null,
      isGameReadyLoading: false,
      ParamCustomGame: [],
    };
  },
  computed: {
    ...User.mapGetters(["user"]),
  },
  props: {
    Version: {
      required: true,
    },
    Difficulte: {
      required: true,
    },
    Distance: {
      required: true,
    },
    Timer: {
      required: true,
    },
    pcm: {
      required: true,
    },
    Flux: {
      required: true,
    },
    Parametre: {
      required: true,
    },
    Musique: {
      required: true,
    },
    tableauModification: {
      type: Array,
      default: () => [],
    },
    Capteur: {
      required: true,
    },
    Guide: {
      required: true,
    },
  },
  mounted() {
    //console.log('Entrée dans le mounted du composant JeuUnity..')
    window.vueComponent = this;
    ParseConfig();
    this.injectionScriptUnity();
    //console.log('Fin du mounted du composant JeuUnity..')
  },
  created() {
    //console.log('Création du composant JeuUnity..')
    if (!this.$store.state.unityInstance) {
      Unity = new UnityWebgl({
        loaderUrl:
          "https://www.symbiocenter.com/assets/game/Build/Build_Unity_2020.loader.js",
        dataUrl:
          "https://www.symbiocenter.com//assets/game/Build/Build_Unity_2020.data.unityweb",
        frameworkUrl:
          "https://www.symbiocenter.com/assets/game/Build/Build_Unity_2020.framework.js.unityweb",
        codeUrl:
          "https://www.symbiocenter.com/assets/game/Build/Build_Unity_2020.wasm.unityweb",
        memory: 1024 * 1024 * 1024, // Allouer 1024 Mo de mémoire
      });
      this.$store.commit("setUnityInstance", Unity);
    } else {
      Unity = this.$store.state.unityInstance;
    }
    // //console.log("Ouverture n°", this.keyGame);
    //console.log("Données Unity:", Unity);
    this.unityContext = Unity;
    //console.log('UnityContext:', this.unityContext);
    Unity.on("progress", (percent) => {
      if (percent === 1) {
      }
    });
    this.unityContext.on("destroyed", () => {
      console.log("Le jeu a bien été détruit");
    });
    //console.log('Fin de la création du composant JeuUnity..')
    //réafficher 30secondes après
    setTimeout(() => {
      //console.log("Données Unity 30sec:", Unity);
    }, 30000);
  },

  beforeDestroy() {
    this.onComponentClose();
    // //console.log("Fermeture du composant..");
    // Unity.send("CommServer", "Kill");
    // //console.log('beforeDestroy mise à null');
    // Unity.destroy();
    if (this.unityContext) {
      this.unityContext.destroy();
      this.unityContext = null;
    }
    // Unity = null;
    this.dataUnity = "";
    // Supprimer la référence au composant Vue pour éviter les fuites de mémoire
    delete window.vueComponent;
  },
  methods: {
    onComponentClose() {
      //fermer la socket
      if (this.socket) {
        //console.log(this.socket);
        this.socket.close();
      }
    },
    updateReceivedData(data) {
      console.log("Entrée dans la méthode updateReceivedData")
      this.receivedData = data;
      this.receivedData = JSON.parse(this.receivedData);
      //faire la moyenne du arrayCC avant de l'envoyer
      var sum = 0;
      for (var i = 0; i < this.receivedData.ArrayCC.length; i++) {
        sum += this.receivedData.ArrayCC[i];
      }
      this.receivedData.ArrayCC = sum / this.receivedData.ArrayCC.length;
      //arrondir la valeur à un nombre entier
      this.receivedData.ArrayCC = Math.round(this.receivedData.ArrayCC);
      this.dataUnity = {
        ArrayTacho: this.arrayTacho,
        TabCC: this.TabCC,
        AverageCC: this.receivedData.ArrayCC,
        Difficulty: this.receivedData.Difficulty,
        TimeCompletion: this.receivedData.TimeCompletion,
        Timer: this.receivedData.Timer,
        BpmCC: this.receivedData.AverageBPM,
        Pct1: this.Pct1,
        Pct2: this.Pct2,
        Pct3: this.Pct3,
        Cpm: this.pcm,
        Profil: this.Parametre,
        Inspi: this.receivedData.Inspi,
        PauseInspi: this.receivedData.InspiPause,
        Expi: this.receivedData.Expi,
        PauseExpi: this.receivedData.ExpiPause,
      };
      console.log("Data Unity:", this.dataUnity);
    },
    gameReady() {
      //console.log('Entrée dans la méthode gameReady')
      if (!this.isGameReady) {
        ParseConfig();
        this.socket = io.connect("https://www.symbiocenter.com", {
          resource: "/expert/socket.io",
        });
        this.socket.on("connect", async () => {
          // Remplacez 'async function ()' par 'async () =>'
          this.socket.emit("authentication", {
            username: await Parse.User.current().attributes.username,
          });
          this.socket.on("authenticated", function () {});
          this.socket.on("unauthorized", function () {});
          this.socket.on("rr", async (rr) => {
            if (rr !== undefined && rr !== "") {
              var rrJson = JSON.parse(rr);
              var fc = Math.round(60000 / rrJson.rr);
              rrJson.CC = parseFloat(rrJson.CC);
              var UnityCC = parseInt(rrJson.CC);
              if (this.listeRR === "") {
                this.listeRR = rrJson.rr;
              } else {
                this.listeRR = this.listeRR + "," + rrJson.rr;
              }
              if (UnityCC === "NaN") UnityCC = 0;
              if (!(fc > 40 && fc < 200)) rrJson.rr = 0;
              if (
                Number.isInteger(UnityCC) &&
                Number.isInteger(rrJson.rr) &&
                rrJson.rr !== 0 &&
                UnityCC !== 0
              ) {
                // //console.log('UnitySend en cours..');
                Unity.send("CommServer", "GetCC", UnityCC + "|" + rrJson.rr);
                // //console.log('UnitySend terminée..')
              }
              var dataArrayTacho = rrJson.traceTacho;
              var dataCC = rrJson.CC;
              if (rrJson != null) {
                if (dataArrayTacho != null) {
                  for (var i = 0; i < dataArrayTacho.length; i++) {
                    this.arrayTacho.push(dataArrayTacho[i]);
                    this.TabCC.push({ x: dataArrayTacho[i].x, y: dataCC });
                  }
                }
              }
              this.Pct1 = rrJson.PctT1;
              this.Pct2 = rrJson.PctT2;
              this.Pct3 = rrJson.PctT3;
            }
          });
        });
      }
    },

    callData() {
      ParseConfig();
      const ObjectFile = Parse.Object.extend("file");
      const ObjectGame = Parse.Object.extend("game_type");
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryGame = new Parse.Query(ObjectGame);
      try {
        QueryFile.equalTo("objectId", "K70jU7ctpU");
        QueryFile.find().then((e) => {
          return (this.element_file = e);
        });
        QueryGame.find().then((e) => {
          return (this.element_game = e);
        });
        tableau_file.push(element_file);
        tableau_game.push(element_game);
        this.$forceUpdate();
      } catch {
        ("ERROR");
      }
    },
    sendMessage: function () {},
    fullscreen() {
      Unity.setFullscreen(1);
    },
    LoadGame: function () {
      //console.log('Unity dans loadGame:', Unity);
      //console.log('Id:',1)
      //console.log('Version:',this.Version)
      //console.log('Difficulty:',this.Difficulte)
      //console.log('Distance:',this.Distance)
      //console.log('Timer:',this.Timer)
      //console.log('pcm:',this.pcm)
      //console.log('Flux:',this.Flux)
      //console.log('Musique:',this.Musique)
      //console.log('Parametre:',this.Parametre)
      //console.log('Capteur:',this.Capteur)
      //console.log('Guide:',this.Guide)
      //console.log('TableauModif:',TableauModif)
      //console.log('TableauModifText:',TableauModifText)

      this.isGameReadyLoading = true;
      var TableauModif = [];
      var TableauModifText = [];
      if (this.tableauModification.length > 0) {
        this.tableauModification.forEach((element) => {
          TableauModif.push(element.Time);
          TableauModif.push(element.Inspi);
          TableauModif.push(element.Expi);
          TableauModif.push(element.InspiPause);
          TableauModif.push(element.ExpiPause);
          TableauModifText.push(element.MessagePalier);
        });
        console.log(
          "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Tableau Modification :",
          TableauModif
        );
      }
      try {
        const DataUser = Parse.Object.extend("User");
        const queryUser = new Parse.Query(DataUser);
        if (this.idUserForSave) {
          queryUser.equalTo("objectId", this.idUserForSave);
        } else if (this.idPatientTherapeute) {
          queryUser.equalTo("objectId", this.idPatientTherapeute);
        } else {
          queryUser.equalTo("objectId", this.user.objectId);
        }
        var UserFirst = queryUser.first();
        UserFirst.then((result) => {
          console.log(" /!\ UserFirst", result);
          var DataUserParam = Parse.Object.extend("user_param_game");
          var queryUserParam = new Parse.Query(DataUserParam);
          queryUserParam.equalTo("user", result);
          queryUserParam.first().then((result) => {
            console.log(" /!\ queryUserParam", result);
            if (result) {
              this.ParamCustomGame = result.attributes.paramJeux;
              var ParamCustomGameOld = [];
              // transforme le string en array
              if (this.ParamCustomGame) {
                this.ParamCustomGame = JSON.parse(this.ParamCustomGame);
                ParamCustomGameOld.push(this.ParamCustomGame.Inspi);
                ParamCustomGameOld.push(this.ParamCustomGame.Expi);
                ParamCustomGameOld.push(this.ParamCustomGame.InspiPause);
                ParamCustomGameOld.push(this.ParamCustomGame.ExpiPause);
              }
            } else {
              var ParamCustomGameOld = [];
              this.ParamCustomGame = [];
            }

            console.log(" /----!-----\ ParamCustomGame", this.ParamCustomGame);

            Unity.send("CommServer", "GetInfo", {
              ID: 1,
              version: this.Version,
              difficulty: this.Difficulte,
              distance: this.Distance,
              timer: this.Timer,
              pcm: this.pcm,
              flux: this.Flux,
              timerCalibration: "",
              music: this.Musique,
              customParams: ParamCustomGameOld,
              arrayModification: TableauModif,
              arrayTextModification: TableauModifText,
              parameters: this.Parametre,
              useCapteur: this.Capteur,
              useGuide: this.Guide,
            });
          });
        });
      } catch {
        console.log(" /!\ UserFirst", "erreur");
      }
    },
    QuitGame: function () {
      Unity.send("CommServer", "Kill");
      //console.log('QuitGame mise à null');
      Unity = null;
      this.unityContext = null;
      this.dataUnity = "";
    },
    SaveGame: function () {
      console.log("Entrée dans la méthode SaveGame");
      console.log("Data Unity:", this.dataUnity);
      this.$emit("game-finish", this.dataUnity);
      // this.QuitGame();
    },
    dynamicallyAddScript: function (texte) {
      setTimeout(() => {
        const script = document.createElement("script"); // Créer un élément DOM de script
        script.text = texte; // Définir son texte à l'URL fournie

        // document.getElementById('scriptunity').appendChild(script);
        document.head.appendChild(script); // Ajouter le script à la fin de la section head de la page (peut être changé à 'body' pour ajouter à la fin de la section body)
      });
    },
    async saveCPM(data) {
      console.log("Data CPM reçu :", data);
      var user = Parse.User.current();
      const Data = Parse.Object.extend("user_param_game");
      const DataUser = Parse.Object.extend("User");
      const queryUser = new Parse.Query(DataUser);
      const query = new Parse.Query(Data);
      if (this.idUserForSave) {
        queryUser.equalTo("objectId", this.idUserForSave);
        const [user_cpm] = await Promise.all([queryUser.find()]);
        this.UtilisateurCPM = user_cpm[0];
        query.equalTo("user", this.UtilisateurCPM);
      } else if (this.idPatientTherapeute) {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
        const [user_cpm] = await Promise.all([queryUser.find()]);
        this.UtilisateurCPM = user_cpm[0];
        query.equalTo("user", this.UtilisateurCPM);
      } else {
        query.equalTo("user", user);
        this.UtilisateurCPM = user;
      }
      query
        .first()
        .then((result) => {
          if (result) {
            result.set("paramJeux", data);
            return result.save();
          } else {
            const data = { user: this.UtilisateurCPM, paramJeux: data };
            const dataDone = new Data();
            return dataDone.save(data);
          }
        })
        .then(
          (result) => {
            // this.isCardRedirectFront = false;
            // this.startTimerForNotif();
            // this.notificationMessage = "Paramètres sauvegardés";
            console.log("Data saved", result);
          },
          (error) => {
            console.error("Error while saving Data: ", error);
          }
        );
    },
    injectionScriptUnity() {
      // Définir la fonction SendData pour recevoir des données de Unity
      var scriptSendData = `function SendData(data) {
  console.log('SendData est appelé:', data);
  localStorage.setItem('ResultatJeux', data);
  document.getElementById('ResultatJeux').innerHTML = data;
  window.vueComponent.updateReceivedData(data);
  window.vueComponent.SaveGame();
}`;

      this.dynamicallyAddScript(scriptSendData);
    var scriptQuitGame = `function QuitGame() {
      console.log('Jeu terminé...');
     }`;
      this.dynamicallyAddScript(scriptQuitGame);
      var scriptGetInfo = `function GetInfo() {
      window.vueComponent.LoadGame();
    }`;
      this.dynamicallyAddScript(scriptGetInfo);
      var scriptGameReady = `function gameReady() {
      window.vueComponent.gameReady();
    }`;
      var scriptSaveParam =
        "function SaveCustomParams(data){window.vueComponent.saveCPM(data);}";
      this.dynamicallyAddScript(scriptSaveParam);
      this.dynamicallyAddScript(scriptGameReady);
    },
  },
};
</script>
<style>
.circleBPM {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #fff;
  border: 3px solid #000;
  color: #000;
  text-align: center;
  font: 32px Arial, sans-serif;
}
.buttonGame {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: lightgray;
  color: black;
  margin: 1%;
}
.loader-game {
  position: absolute;
  top: 40%;
  left: 44%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
