<template>
    <div class="modal-backdrop" @click="closeInfoModal">
      <div v-if="sortedEvaluationField.length > 0">
        <div class="modal-content" @click.stop>
          <h1 class="title">Évaluation du service</h1>
          <div v-for="evaluation in sortedEvaluationField" :key="evaluation.id" class="question-block">
            <h3 v-if="evaluation.attributes.text" class="title">
              {{ evaluation.attributes.text }}
            </h3>
            <div class="rating">
              <label v-for="star in [5, 4, 3, 2, 1]" :key="star">
                <input
                  type="radio"
                  :name="'rating-' + evaluation.id"
                  :value="star"
                  v-model="ratings[evaluation.id]"
                />
                <span class="star">★</span>
              </label>
            </div>
          </div>
          <!-- Conteneur pour centrer le bouton -->
          <div class="button-container">
            <button @click="submitRatings">Soumettre</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Parse from 'parse';
  
  export default {
    name: "PopUp",
    props: {
      evaluationField: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        ratings: {}, // Stocke les notes pour chaque évaluation
      };
    },
    computed: {
      sortedEvaluationField() {
        // Tri des évaluations par la propriété `number` de manière croissante
        return this.evaluationField.sort((a, b) => a.attributes.number - b.attributes.number);
      },
    },
    methods: {
      closeInfoModal() {
        this.$emit("close");
      },
      async submitRatings() {
        try {
          const user = Parse.User.current(); // Récupérer l'utilisateur actuel
          if (!user) {
            alert("Vous devez être connecté pour soumettre vos évaluations.");
            return;
          }
  
          // Initialiser les notes à 0 pour les questions non cochées
          this.sortedEvaluationField.forEach(evaluation => {
            if (!this.ratings[evaluation.id]) {
              this.ratings[evaluation.id] = 0;
            }
          });
  
          // Parcourir chaque évaluation et enregistrer le résultat
          for (const [evaluationId, rating] of Object.entries(this.ratings)) {
            const EvaluationResult = Parse.Object.extend('evaluation_result');
            const evaluationResult = new EvaluationResult();
  
            // Associer les données de la réponse
            evaluationResult.set('user', user);
            evaluationResult.set('evaluation_field', {
              __type: 'Pointer',
              className: 'evaluation_field',
              objectId: evaluationId
            });
            evaluationResult.set('rating', rating);
  
            // Enregistrer dans la base Parse
            await evaluationResult.save();
            console.log(`Évaluation ${evaluationId} enregistrée avec une note de ${rating}`);
          }
  
          alert("Toutes les réponses ont été enregistrées avec succès !");
          this.closeInfoModal();
        } catch (error) {
          console.error("Erreur lors de la soumission des évaluations :", error);
          alert("Une erreur est survenue lors de l'enregistrement des réponses.");
        }
      },
    },
    created() {
      // Initialiser les notes à 0 pour toutes les questions au chargement du composant
      this.evaluationField.forEach(evaluation => {
        this.$set(this.ratings, evaluation.id, 0);
      });
      console.log("Création du component popUpEval", this.evaluationField);
    },
  };
  </script>
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 10px;
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 650px;
    width: 100%;
    max-height: 85vh;
    overflow-y: auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .modal-content h3 {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: normal;
    color: #239380;
  }
  
  .modal-content h1 {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #239380;
    letter-spacing: 1.2px;
  }
  
  .rating {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    direction: rtl;
  }
  
  .rating label {
    cursor: pointer;
    font-size: 1.5rem;
    color: #ccc;
    transition: color 0.2s;
  }
  
  .rating input {
    display: none;
  }
  
  .rating input:checked ~ .star,
  .rating input:checked + label .star {
    color: #ffc107; /* Couleur jaune pour les étoiles sélectionnées */
  }
  
  .rating label:hover ~ label .star,
  .rating label:hover .star {
    color: #ffdd6b; /* Couleur plus claire au survol */
  }
  
  .question-block {
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: center; /* Centre le bouton horizontalement */
    margin-top: 20px; /* Ajoute un espace au-dessus du bouton */
  }
  
  .modal-content button {
    padding: 12px 24px;
    background-color: #239380;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .modal-content button:hover {
    background-color: #348b7d;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .modal-content button:active {
    background-color: #346e62;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transform: scale(0.98);
  }
  </style>
  