<template>
  <div>
    <div class="gauge-container">
      <div class="gauge">
        <div :class="['gauge-bar', { 'gauge-bar-true': gaugeEnabled, 'gauge-bar-false': !gaugeEnabled }]" :style="gaugeStyle"></div>
        <img
          v-if="!MoniteurInProgrammes"
          @click="openModal = true"
          src="../../../../assets/img/executable_active.png"
          alt="Logo"
          class="image-logo"
        />
      </div>
    </div>
    <!-- <button v-on:click="DisplayData()">Afficher données</button> -->
    <div v-if="openModal">
      <div class="modal-background" @click="openModal = false"></div>
      <div class="modal-content">
        <!-- Croix pour fermer la modale -->
        <button class="close-button" @click="openModal = false">&times;</button>

        <!-- Champ de sélection "CPM" -->
        <label>CPM</label>
        <br />
        <select v-model="cpm" @change="setInspirationExpiration">
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="10">10</option>
          <option value="12">12</option>
        </select>
        <br />
        <!-- Champ de sélection "Profile" -->
        <label>Profile</label>
        <br />
        <select v-model="profile" @change="setInspirationExpiration">
          <option value="profonde">Profonde</option>
          <option value="tonique">Tonique</option>
          <option value="basique">Basique</option>
        </select>
        <br />

        <!-- Inspiration et Expiration -->
        <div class="row-cpm">
          <div class="column-cpm">
            <!-- Champ de chiffres "Inspiration" -->
            <label>Inspiration</label>
            <div class="input-container">
              <!-- Bouton "moins" -->
              <button
                v-bind:class="{
                  disabled: inspiration === 0,
                  'opacity-50': inspiration === 0,
                }"
                class="minus-button"
                @click="
                  inspiration = parseFloat((inspiration - 0.1).toFixed(1))
                "
              >
                -
              </button>
              <!-- Champ de chiffres -->
              <input
                readonly
                type="number"
                v-model="inspiration"
                class="input"
                step="0.1"
              />

              <!-- Bouton "plus" -->
              <button
                class="plus-button"
                @click="
                  inspiration = parseFloat((inspiration + 0.1).toFixed(1))
                "
              >
                +
              </button>
            </div>
          </div>

          <div class="column-cpm">
            <!-- Champ de chiffres "Expiration" -->
            <label>Expiration</label>
            <div class="input-container">
              <!-- Bouton "moins" -->
              <button
                v-bind:class="{
                  disabled: expiration === 0,
                  'opacity-50': expiration === 0,
                }"
                class="minus-button"
                @click="expiration = parseFloat((expiration - 0.1).toFixed(1))"
              >
                -
              </button>
              <!-- Champ de chiffres -->
              <input
                readonly
                type="number"
                v-model="expiration"
                class="input"
                step="0.1"
              />

              <!-- Bouton "plus" -->
              <button
                class="plus-button"
                @click="expiration = parseFloat((expiration + 0.1).toFixed(1))"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <!-- Pause Inspiration et Pause Expiration -->
        <div class="row-cpm">
          <div class="column-cpm">
            <!-- Champ de chiffres "Pause inspiration" -->
            <label>Pause inspiration</label>
            <div class="input-container">
              <!-- Bouton "moins" -->
              <button
                v-bind:class="{
                  disabled: pauseInspiration === 0,
                  'opacity-50': pauseInspiration === 0,
                }"
                class="minus-button"
                @click="
                  pauseInspiration = parseFloat(
                    (pauseInspiration - 0.1).toFixed(1)
                  )
                "
              >
                -
              </button>
              <!-- Champ de chiffres -->
              <input
                readonly
                type="number"
                v-model="pauseInspiration"
                class="input"
                step="0.1"
              />

              <!-- Bouton "plus" -->
              <button
                class="plus-button"
                @click="
                  pauseInspiration = parseFloat(
                    (pauseInspiration + 0.1).toFixed(1)
                  )
                "
              >
                +
              </button>
            </div>
          </div>

          <div class="column-cpm">
            <!-- Champ de chiffres "Pause expiration" -->
            <label>Pause expiration</label>
            <div class="input-container">
              <!-- Bouton "moins" -->
              <button
                v-bind:class="{
                  disabled: pauseExpiration === 0,
                  'opacity-50': pauseExpiration === 0,
                }"
                class="minus-button"
                @click="
                  pauseExpiration = parseFloat(
                    (pauseExpiration - 0.1).toFixed(1)
                  )
                "
              >
                -
              </button>
              <!-- Champ de chiffres -->
              <input
                readonly
                type="number"
                v-model="pauseExpiration"
                class="input"
                step="0.1"
              />

              <!-- Bouton "plus" -->
              <button
                class="plus-button"
                @click="
                  pauseExpiration = parseFloat(
                    (pauseExpiration + 0.1).toFixed(1)
                  )
                "
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div class="buttons-container">
          <button
            v-on:click="recupDataCPM()"
            class="buttonChart"
            style="background-color: rgb(35, 147, 128); color: white"
          >
            Récupérer mes paramètres personnels
          </button>
          <button
            v-on:click="SaveDataCPM(), updateAnimationValues()"
            class="buttonChart"
            style="background-color: rgb(35, 147, 128); color: white"
          >
            Enregistrer mes paramètres personnels
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export default {
  data() {
    return {
      openModal: false, // État de la modale (ouverte ou fermée)
      cpm: 6, // Valeur du champ de sélection "CPM"
      profile: "basique", // Valeur du champ de sélection "Profile"
      inspiration: 4.5, // Valeur du champ de chiffres "Inspiration"
      expiration: 5.5, // Valeur du champ de chiffres "Expiration"
      pauseInspiration: 0, // Valeur du champ de chiffres "Pause inspiration"
      pauseExpiration: 0, // Valeur du champ de chiffres "Pause expiration"
    };
  },
  props: {
    inspirationParent: {
      type: Number,
      default: 4,
    },
    expirationParent: {
      type: Number,
      default: 6,
    },
    pauseExpirationParent: {
      type: Number,
      default: 0,
    },
    pauseInspirationParent: {
      type: Number,
      default: 0,
    },
    gaugeEnabled:
    {
      type: Boolean,
      default: true,
    },
    idUserForSave: String,
    idPatientTherapeute: String,
    MoniteurInProgrammes: Boolean,
  },
  computed: {
    animationDuration() {
      return (
        this.inspiration +
        this.pauseExpiration +
        this.expiration +
        this.pauseInspiration
      );
    },
    gaugeStyle() {
      const keyframes = `
      @keyframes breathing {
        0% {
          height: 0%;
        }
        ${(this.inspiration / this.animationDuration) * 100}% {
          height: 100%;
        }
        ${
          ((this.inspiration + this.pauseInspiration) /
            this.animationDuration) *
          100
        }% {
          height: 100%;
        }
        ${
          ((this.inspiration + this.pauseInspiration + this.expiration) /
            this.animationDuration) *
          100
        }% {
          height: 0%;
        }
        ${
          ((this.inspiration +
            this.pauseInspiration +
            this.expiration +
            this.pauseExpiration) /
            this.animationDuration) *
          100
        }% {
          height: 0%;
        }
      }
    `;

      return {
        animation: `breathing ${this.animationDuration}s infinite linear`,
        keyframes,
      };
    },
  },
  watch: {
    inspirationParent(newValue) {
      this.inspiration = newValue;
    },
    expirationParent(newValue) {
      this.expiration = newValue;
    },
    pauseInspirationParent(newValue) {
      this.pauseInspiration = newValue;
    },
    pauseExpirationParent(newValue) {
      this.pauseExpiration = newValue;
    },
    inspiration(newValue) {
      this.inspiration = parseFloat(newValue);
    },
    expiration(newValue) {
      this.expiration = parseFloat(newValue);
    },
    pauseInspiration(newValue) {
      this.pauseInspiration = parseFloat(newValue);
    },
    pauseExpiration(newValue) {
      this.pauseExpiration = parseFloat(newValue);
    },
  },
  methods: {
    DisplayData() {
    },
    updateAnimationValues() {
      this.$emit("inspiration-updated", this.inspiration);
      this.$emit("expiration-updated", this.expiration);
      this.$emit("pauseInspiration-updated", this.pauseInspiration);
      this.$emit("pauseExpiration-updated", this.pauseExpiration);
    },
    setInspirationExpiration() {
      if (this.cpm === "5" || this.cpm === 5) {
        if (this.profile === "basique") {
          this.inspiration = 5.4;
          this.expiration = 6.6;
        } else if (this.profile === "tonique") {
          this.inspiration = 6;
          this.expiration = 6;
        } else if (this.profile === "profonde") {
          this.inspiration = 4.8;
          this.expiration = 7.2;
        }
      } else if (this.cpm === "6" || this.cpm === 6) {
        if (this.profile === "basique") {
          this.inspiration = 4.5;
          this.expiration = 5.5;
        } else if (this.profile === "tonique") {
          this.inspiration = 5;
          this.expiration = 5;
        } else if (this.profile === "profonde") {
          this.inspiration = 4;
          this.expiration = 6;
        }
      } else if (this.cpm === "7" || this.cpm === 7) {
        if (this.profile === "basique") {
          this.inspiration = 4.29;
          this.expiration = 4.29;
        } else if (this.profile === "tonique") {
          this.inspiration = 3.86;
          this.expiration = 4.71;
        } else if (this.profile === "profonde") {
          this.inspiration = 3.43;
          this.expiration = 5.14;
        }
      } else if (this.cpm === "8" || this.cpm === 8) {
        if (this.profile === "basique") {
          this.inspiration = 3.38;
          this.expiration = 4.13;
        } else if (this.profile === "tonique") {
          this.inspiration = 3.75;
          this.expiration = 3.75;
        } else if (this.profile === "profonde") {
          this.inspiration = 3;
          this.expiration = 4.5;
        }
      } else if (this.cpm === "9" || this.cpm === 9) {
        if (this.profile === "basique") {
          this.inspiration = 3;
          this.expiration = 3.67;
        } else if (this.profile === "tonique") {
          this.inspiration = 3.33;
          this.expiration = 3.33;
        } else if (this.profile === "profonde") {
          this.inspiration = 2.67;
          this.expiration = 4;
        }
      } else if (this.cpm === "10" || this.cpm === 10) {
        if (this.profile === "basique") {
          this.inspiration = 2.7;
          this.expiration = 3.3;
        } else if (this.profile === "tonique") {
          this.inspiration = 3;
          this.expiration = 3;
        } else if (this.profile === "profonde") {
          this.inspiration = 2.4;
          this.expiration = 3.6;
        }
      } else if (this.cpm === "11" || this.cpm === 11) {
        if (this.profile === "basique") {
          this.inspiration = 2.7;
          this.expiration = 3.3;
        } else if (this.profile === "tonique") {
          this.inspiration = 2.73;
          this.expiration = 2.73;
        } else if (this.profile === "profonde") {
          this.inspiration = 2.18;
          this.expiration = 3.27;
        }
      } else if (this.cpm === "12" || this.cpm === 12) {
        if (this.profile === "basique") {
          this.inspiration = 2.7;
          this.expiration = 3.3;
        } else if (this.profile === "tonique") {
          this.inspiration = 2.5;
          this.expiration = 2.5;
        } else if (this.profile === "profonde") {
          this.inspiration = 2;
          this.expiration = 3;
        }
      }
    },
    recupDataCPM() {
      var data = Parse.Object.extend("user_param_game");
      var query = new Parse.Query(data);
      var objectUser = Parse.Object.extend("User");
      var queryUser = new Parse.Query(objectUser);
      queryUser.equalTo("objectId", Parse.User.current().id);
      queryUser.find().then((results) => {
        console.log(results);
      });
      query.matchesQuery("user", queryUser);

      query.find().then((results) => {
        if (results.length > 0) {
          var data_cpm = results[0].attributes.paramJeux;
          data_cpm = JSON.parse(data_cpm);
          this.inspiration = data_cpm.Inspi;
          this.expiration = data_cpm.Expi;
          this.pauseInspiration = data_cpm.InspiPause;
          this.pauseExpiration = data_cpm.ExpiPause;
        }
      });
    },
    async SaveDataCPM() {
      this.openModal = false;
      var data_cpm = {
        Inspi: this.inspiration,
        Expi: this.expiration,
        InspiPause: this.pauseInspiration,
        ExpiPause: this.pauseExpiration,
      };
      data_cpm = JSON.stringify(data_cpm);
      var user = Parse.User.current();
      const Data = Parse.Object.extend("user_param_game");
      const DataUser = Parse.Object.extend("User");
      const queryUser = new Parse.Query(DataUser);
      const query = new Parse.Query(Data);
      if (this.idUserForSave) {
        queryUser.equalTo("objectId", this.idUserForSave);
        const [user_cpm] = await Promise.all([queryUser.find()]);
        this.UtilisateurCPM = user_cpm[0];
        query.equalTo("user", this.UtilisateurCPM);
      } else if (this.idPatientTherapeute) {
        queryUser.equalTo("objectId", this.idPatientTherapeute);
        const [user_cpm] = await Promise.all([queryUser.find()]);
        this.UtilisateurCPM = user_cpm[0];
        query.equalTo("user", this.UtilisateurCPM);
      } else {
        query.equalTo("user", user);
        this.UtilisateurCPM = user;
      }
      query
        .first()
        .then((result) => {
          if (result) {
            result.set("paramJeux", data_cpm);
            return result.save();
          } else {
            const data = { user: this.UtilisateurCPM, paramJeux: data_cpm };
            const dataDone = new Data();
            return dataDone.save(data);
          }
        })
        .then(
          (result) => {
            this.isCardRedirectFront = false;
            console.log("Data saved: ", result);
            // this.startTimerForNotif();
            // this.notificationMessage = "Paramètres sauvegardés";
          },
          (error) => {
            console.error("Error while saving Data: ", error);
          }
        );
    },
  },
  created() {
    ParseConfig();

    this.inspiration = this.inspirationParent;
    this.expiration = this.expirationParent;
    this.pauseInspiration = this.pauseInspirationParent;
    this.pauseExpiration = this.pauseExpirationParent;
  },
  mounted() {
    const style = document.createElement("style");
    style.textContent = this.gaugeStyle.keyframes;
    document.head.appendChild(style);
  },
  beforeDestroy() {
    // Clean up the dynamically created style tag
    const styles = document.head.querySelectorAll("style");
    for (const style of styles) {
      if (style.textContent === this.gaugeStyle.keyframes) {
        document.head.removeChild(style);
      }
    }
  },
};
</script>

<style scoped>
.gauge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gauge {
  position: relative;
  width: 50px;
  height: 200px;
  background-color: #ccc;
  border-radius: 15px 15px 0 0;
}

.gauge-bar-true {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  background: linear-gradient(to top, #2196f3, #64b5f6);
  border-radius: 15px 15px 0 0;
  transition: height linear;
}

.gauge-bar-false {
}

.image-logo {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  z-index: 555;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 15px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: black;
  background: none;
  border: none;
}
.row-cpm {
  display: flex;
  margin-bottom: 1rem;
}

.column-cpm {
  flex: 1;
  margin-right: 1rem;
}

.column-cpm:last-child {
  margin-right: 0;
}

.input-container {
  display: flex;
}

.input > .input-container {
  flex: 1;
  height: 30px;
  font-size: 16px;
  text-align: center;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}
.plus-button,
.minus-button {
  width: 30px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: rgb(35, 147, 128);
  border: none;
  cursor: pointer;
}
.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}

.buttons-container > button {
  margin: 0 10px;
}
select {
  width: 100%;
  height: 30px;
  font-size: 16px;
  text-align: center;
  -moz-appearance: textfield;
}
</style>
