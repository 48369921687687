import Parse from "parse";
import moment from "moment";
import ParseConfig from "@/api/parse.config.js";
import { TYPE_PRATIQUE } from "@/configuration/userGroupConf.js";
import { getLastConnectionByArrayUser } from "@/services/utilisateurs/getLastConnectionByUserId.js";
import asyncForEach from "@/services/general/asyncForEach";

import store from "../../store/store";

export async function getUsersByGroupIdProfil(group) {
  ParseConfig();

  const userConnecte = store.getters["user/user"];

  const props = {
    usersInMyGroup: [],
    nb_users_licence: 0,
    nb_users_licence_not_expire: 0,
  };

  const ObjectUserGroup = Parse.Object.extend("user_group");
  const ObjectUser = Parse.Object.extend("User");

  const NewUserGroup = new ObjectUserGroup();
  NewUserGroup.id = group.objectId;

  const QueryUser = new Parse.Query(ObjectUser);

  QueryUser.equalTo("group", NewUserGroup);
  QueryUser.limit(1000000);
  const ResponseUser = await QueryUser.find();

  const listeSession = await getLastConnectionByArrayUser(ResponseUser);

  await asyncForEach(ResponseUser, async (user) => {
    if (!userConnecte) {
      return;
    }
    if (user.attributes.compte_desactive) return;

    if (user.id !== userConnecte.objectId) return;
    if (user.attributes.type_pratique === TYPE_PRATIQUE.affiliation) return;
    listeSession.filter((e) => {
      return e.attributes.user.id === user.id;
    });
    var dateDerniereConnexion = null;
    // if (listeDateDerniereConnexion.length > 0) dateDerniereConnexion = listeDateDerniereConnexion[0].createdAt;
    const userPush = {
      objectId: user.id,
      nom: user.attributes.lastname,
      prenom: user.attributes.firstname,
      mail: user.attributes.username,
      createdAt: user.attributes.createdAt,
      dureeAcces: user.attributes.duree_acces,
      dureeAccesInitial: user.attributes.duree_acces,
      fin_acces_plateforme: user.attributes.fin_acces_plateforme,
      derniere_connexion: user.attributes.derniere_connexion
        ? user.attributes.derniere_connexion
        : dateDerniereConnexion,
      activite: null,
      typePratique: user.attributes.type_pratique,
      propositionAffiliation: user.attributes.proposition_affiliation,
      date_debut_licence: user.attributes.date_debut_licence,
      acces_donnees_therapeute: user.attributes.acces_donnees_therapeute,
      cgu: user.attributes.cgu,
      withdrawal_credit_date: user.attributes.withdrawal_credit_date,
      LicenceActive: user.attributes.LicenceActive,
    };

    if (userPush.fin_acces_plateforme) {
      userPush.accesActif =
        moment().toDate() <
          moment(user.attributes.fin_acces_plateforme).toDate() &&
        user.attributes.fin_acces_plateforme;
    }
    if (user.attributes.type_pratique === TYPE_PRATIQUE.licence) {
      props.nb_users_licence++;
    }
    if (
      user.attributes.date_debut_licence <= new Date() &&
      user.attributes.fin_acces_plateforme >= new Date()
    ) {
      props.nb_users_licence_not_expire++;
    }
    if (!userPush.derniere_connexion) {
      userPush.activite = null;
    } else if (
      userPush.derniere_connexion &&
      moment().subtract(8, "d").toDate() <
        moment(userPush.derniere_connexion).toDate() &&
      moment(userPush.derniere_connexion).toDate() <= moment().toDate()
    ) {
      userPush.activite = "vert";
    } else if (
      userPush.derniere_connexion &&
      moment().subtract(16, "d").toDate() <
        moment(userPush.derniere_connexion).toDate() &&
      moment(userPush.derniere_connexion).toDate() <=
        moment().subtract(8, "d").toDate()
    ) {
      userPush.activite = "orange";
    } else if (
      userPush.derniere_connexion &&
      moment(userPush.derniere_connexion).toDate() <=
        moment().subtract(16, "d").toDate()
    ) {
      userPush.activite = "rouge";
    }
    props.usersInMyGroup.push(userPush);
  });

  return props;
}
