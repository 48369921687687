import Parse from "parse";

export async function createNotificationForBeginQuestionnaire(
  file,
  noteQuestionnaire
) {
  //console.log("createNotificationForBeginQuestionnaire");
  //console.log(file);Z
  console.log(noteQuestionnaire);

  const currentUser = Parse.User.current();
  const queryUser = new Parse.Query(Parse.User);
  const queryGroup = new Parse.Query("user_group");
  queryGroup.equalTo("objectId", currentUser.attributes.group.id);
  queryUser.matchesQuery("group", queryGroup);
  const therapist = await queryUser.first();

  if (currentUser.id === therapist.id) {
    console.log(
      "Le currentUser et le therapist sont le même utilisateur. Pas de notification créée."
    );
    return;
  }

  const NotifEvent = Parse.Object.extend("notification");
  const notifEvent = new NotifEvent();
  notifEvent.set("questionnaireEvent", file.attributes.questionnaire_);
  notifEvent.set("user", currentUser);
  notifEvent.set("date_event", new Date());
  notifEvent.set("type", "Questionnaire BFP");
  notifEvent.set(
    "description",
    " Votre patient a fait le Bilan Flash Psychologique, vous pouvez le consulter dans votre espace de gestion. " 
  );
  notifEvent.set("user_pro", therapist);
  notifEvent.set("isRead", false);
  notifEvent.set("isReadUser", false);
  notifEvent.set("isReadUserPro", false);

  try {
    await notifEvent.save();
    //console.log(notifEvent);
    //console.log("Notification sauvegardée");
  } catch (error) {
    console.error("Error while creating Data: ", error);
  }
}
