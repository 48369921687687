<template>
  <div>
    <div class="chart-result">
      <chartFcVue
        :valeurX="valeurX"
        :tabRowFc="TabRowFC"
        :defaultOpenedDetails="DefaultOpenedDetails"
      />
      <chartCcVue
        :valeurX="ValeurCCX"
        :tabRowCc="TabRowCC"
        :defaultOpenedDetails="DefaultOpenedDetails"
      />
    </div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-2">
          <div class="circleFCMoyenne1">
            <div class="circleFCMoyenne2">
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ fcMoyenne ? fcMoyenne : "0" }}
                </span>
                bpm
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            {{ $t("moyenneFrequenceCardiaque") }}
          </p>
        </div>
        <div class="column is-2">
          <div class="circleFCMoyenne1">
            <div
              class="circleFCMoyenne4"
              :style="{
                'background-color': couleurCCMoyenne,
              }"
            >
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ ccMoyenne ? ccMoyenne : "0" }}
                </span>
                %
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            {{ $t("moyenneCc") }}
          </p>
        </div>
        <div
          class="column is-5"
          style="
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: space-around;
            padding-bottom: 0%;
          "
        >
          <div class="columns">
            <div class="column is-size-4 has-text-centered">
              {{ valeurRouge ? valeurRouge : "0" }}%
              <div class="respi_progress_bar_result">
                <div
                  class="cc_red_result"
                  :style="{
                    width: valeurRouge ? valeurRouge + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div>
            <div class="column is-size-4 has-text-centered">
              {{ valeurOrange ? valeurOrange : "0" }}
              %
              <div class="respi_progress_bar_result">
                <div
                  class="cc_orange_result"
                  :style="{
                    width: valeurOrange ? valeurOrange + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div>
            <div class="column is-size-4 has-text-centered">
              {{ valeurVerte ? valeurVerte : "0" }}%
              <div class="respi_progress_bar_result">
                <div
                  class="cc_green_result"
                  :style="{
                    width: valeurVerte ? valeurVerte + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div>
          </div>
          <p class="has-text-black has-text-centered has-text-weight-semibold">
            % {{ $t("pourcentTempsPasseZoneCoherence") }}
          </p>
        </div>
        <div
          class="column is-3"
          style="
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: space-around;
            padding-bottom: 4%;
          "
        >
          <div>
            <span
              class="has-text-weight-bold has-text-black has-text-centered is-size-2"
            >
              {{ $t("duree") }}
            </span>
            <span
              style="
                border: 2px solid rgba(255, 255, 255, 0.5);"
              class="has-text-weight-bold has-text-centered is-size-2 labelChrono"
            >
              {{ timeOfExercice }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import suivieCc from "./tableauDeBord/SuivieCcExercice.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import chartFcVue from "./tableauDeBord/chartFc.vue";
import chartCcVue from "./tableauDeBord/chartCc.vue";
export default {
  data() {
    return {
      firstObject: {},
      ouvertureSuiviCcUtilisateur: "",
      fileId: "7rOgxcWOD4",
      protocole: "Entraînement libre",
      userSelected: "tous",
      DisplayRelecture: false,
      timeOfExercice: "",
    };
  },
  props: [
    "valeurX",
    "fcMoyenne",
    "ccMoyenne",
    "couleurCCMoyenne",
    "TabRowFC",
    "DefaultOpenedDetails",
    "ValeurCCX",
    "TabRowCC",
    "labelChrono",
    "valeurRouge",
    "valeurVerte",
    "valeurOrange",
  ],
  created() {
    //console.log('LabelChrono', this.labelChrono);
    this.timeOfExercice = this.labelChrono;
  },
  components: {
    suivieCc,
    chartFcVue,
    chartCcVue,
  },
  computed: {
    ...User.mapState(["user"]),
  },
  methods: {},
  mounted() {
    ParseConfig();
    const objectFolder = Parse.Object.extend("folder");
    const QueryFolders = new Parse.Query(objectFolder);
    QueryFolders.equalTo("objectId", "ALoaWJgV7l");
    QueryFolders.find().then((results) => {
      this.folders = results;
      this.firstObject = this.folders.shift();
      return this.folders, this.firstObject;
    });
  },
};
</script>
