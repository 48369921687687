<template>
    <div v-if="isValidProtocol" class="buttonProtocol">
      <button
        v-if="canShowShortProgram && DisplayCourtButton"
        class="buttonProg"
        @click="$emit('showExerciceCourt')"
        :class="{ colorButton: isExercicecourt }"
      >
        Programme Court
      </button>
      <button
        v-if="canShowLongProgram && DisplayLongButton"
        class="buttonProg"
        @click="$emit('showExerciceLong')"
        :class="{ colorButton: isExercicelong }"
      >
        Programme Long
      </button>
    </div>
  </template>
  
  <script>
import { Parse } from 'parse/lib/browser/Parse';

  export default {
    props: {
      idProtocol: String,
      folderBriqueRecup: Object,
      linkedFilePatient: Array,
      isExercicecourt: Boolean,
      isExercicelong: Boolean,
      idPatientTherapeute: String
    },
    data() {
      return {
        DisplayCourtButton: true,
        DisplayLongButton: true,
        idToButton: null
      };
    },
    methods: {
      getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    findWhatProgramme()
    {
    var idUserForSave = this.getCookie("idUserForSave");
      if(idUserForSave)
      {
        this.idToButton = idUserForSave;
      }
      else if(this.idPatientTherapeute)
      {
        this.idToButton = this.idPatientTherapeute;
      }
      if(this.idToButton)
      {
      // console.log('linkedFilePatient', this.linkedFilePatient);
      // console.log('Brique récupéré :',this.folderBriqueRecup);
      // console.log('Protocoles[1]', this.folderBriqueRecup.protocoles[1]);
      // console.log('Protocoles[2]', this.folderBriqueRecup.protocoles[2]);
      // console.log('Protocoles[1] ÏD :', this.folderBriqueRecup.protocoles[1].id);
      // console.log('Protocoles[2] ÏD :', this.folderBriqueRecup.protocoles[2].id);
      var User = Parse.Object.extend('User');
      var queryUser = new Parse.Query(User);
      queryUser.equalTo('objectId', this.idToButton);
      queryUser.include('link');
      queryUser.find().then((user) => {
        console.log('user', user);
        const linkedFilePatientRelation = user[0].relation('link');
         // Utiliser .query() pour récupérer les objets liés
         linkedFilePatientRelation.query().limit(1000).find().then((linkedFiles) => {
           console.log('linkedFilePatient:', linkedFiles);

            // Vérifier la présence des IDs dans les objets liés
        const protocole1Id = this.folderBriqueRecup.protocoles[1].id;
        const protocole2Id = this.folderBriqueRecup.protocoles[2].id;

        const isProtocole1Present = linkedFiles.some((file) => file.attributes.folder && file.attributes.folder.id === protocole1Id);
        const isProtocole2Present = linkedFiles.some((file) => file.attributes.folder && file.attributes.folder.id === protocole2Id);

        console.log('Protocole 1 présent dans linkedFiles:', isProtocole1Present);
        console.log('Protocole 2 présent dans linkedFiles:', isProtocole2Present);

        // Si le protocole 1 est présent, on peut afficher le programme court
        if (isProtocole1Present) {
          this.DisplayCourtButton = true;
        } else {
          this.DisplayCourtButton = false;
        }

        // Si le protocole 2 est présent, on peut afficher le programme long
        if (isProtocole2Present) {
          this.DisplayLongButton = true;
        } else {
          this.DisplayLongButton = false;
        }

        // si le protocole 2 est présent mais pas le 1, on affiche le programme long
        if (isProtocole2Present && !isProtocole1Present) {
          this.$emit('showExerciceLong');
        }

         }).catch((error) => {
           console.error('Erreur lors de la récupération des fichiers liés:', error);
         });
           });
          }
        }
    },
    created() {
      console.log('ID PROTOCOL :', this.idProtocol);
      this.findWhatProgramme();
    },
    computed: {
      isValidProtocol() {
        return !['VOrYKcDSo0', '4ydeuBgcuu', 'Ei2QLEat9q', '6UrWEHmx2k'].includes(this.idProtocol);
      },
      canShowShortProgram() {
        return this.folderBriqueRecup &&
               this.folderBriqueRecup.protocoles &&
               this.folderBriqueRecup.protocoles[1] &&
               this.folderBriqueRecup.protocoles[1].deverrouille &&
               (this.linkedFilePatient && this.linkedFilePatient.length > 0
                 ? this.linkedFilePatient.some(
                     (linkedObject) => linkedObject.id === this.folderBriqueRecup.id && linkedObject.court)
                 : true);
      },
      canShowLongProgram() {
        return this.folderBriqueRecup &&
               this.folderBriqueRecup.protocoles &&
               this.folderBriqueRecup.protocoles[2] &&
               this.folderBriqueRecup.protocoles[2].deverrouille &&
               (this.linkedFilePatient && this.linkedFilePatient.length > 0
                 ? this.linkedFilePatient.some(
                     (linkedObject) => linkedObject.id === this.folderBriqueRecup.id && linkedObject.long)
                 : true);
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .buttonProtocol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonProg {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: lightgray;
  color: black;
  margin: 8px;
  font-size: 80%;
}
.buttonProg.colorButton {
  transform: scale(1.1);
}

.buttonProg {
  transition: transform 0.5s ease;
}
  </style>