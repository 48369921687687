<template>
  <div class="preferences-page">
    <h1 class="page-title">Préférences d'exercices</h1>

    <!-- <p class="page-description">
      Afin d'activer le cadencement des exercices par notifications et mails,
    </p>
    <p class="page-description">
      Veuillez sélectionner le ou les jours de préférences pour vous informer du
      nouvel exercice à faire:
    </p>

    <div class="day-preferences">
      <div class="days">
        <div class="day">
          <input type="checkbox" id="monday" v-model="selectedDays.lundi" />
          <label for="monday">Lundi</label>
        </div>
        <div class="day">
          <input type="checkbox" id="tuesday" v-model="selectedDays.mardi" />
          <label for="tuesday">Mardi</label>
        </div>
        <div class="day">
          <input
            type="checkbox"
            id="wednesday"
            v-model="selectedDays.mercredi"
          />
          <label for="wednesday">Mercredi</label>
        </div>
        <div class="day">
          <input
            type="checkbox"
            id="thursday"
            v-model="selectedDays.jeudi"
          />
          <label for="thursday">Jeudi</label>
        </div>
        <div class="day">
          <input type="checkbox" id="friday" v-model="selectedDays.vendredi" />
          <label for="friday">Vendredi</label>
        </div>
        <div class="day">
          <input
            type="checkbox"
            id="saturday"
            v-model="selectedDays.samedi"
          />
          <label for="saturday">Samedi</label>
        </div>
        <div class="day">
          <input type="checkbox" id="sunday" v-model="selectedDays.dimanche" />
          <label for="sunday">Dimanche</label>
        </div>
      </div>
    </div> -->

    <div class="authorization">
      <div class="authorization-item">
        <input
          type="checkbox"
          id="professional"
          v-model="allowProfessionalAccess"
        />
        <label for="professional"
          >J'autorise mon professionnel référent à avoir accès à mes avancements
          et pratiques</label
        >
      </div>
      <div class="authorization-item">
        <input type="checkbox" id="statistics" v-model="allowStatistics" />
        <label for="statistics"
          >J'autorise l'utilisation de mes avancements et pratiques à des fins
          statistiques pour aider la communauté d'utilisateurs</label
        >
      </div>
      <div class="authorization-item">
        <input type="checkbox" id="newsletter" v-model="subscribeNewsletter" />
        <label for="newsletter">Je souhaite recevoir notre newsletter</label>
      </div>
    </div>
    <button @click="continueIfAccepted" class="continue-button">
      Continuer
    </button>
  </div>
</template>

<script>
import Parse from "parse";

export default {
  data() {
    return {
      selectedDays: {
        lundi: false,
        mardi: false,
        mercredi: false,
        jeudi: false,
        vendredi: false,
        samedi: false,
        dimanche: false,
      },
      allowProfessionalAccess: false,
      allowStatistics: false,
      subscribeNewsletter: false,
    };
  },
  methods: {
    continueIfAccepted() {
      var User = Parse.Object.extend("User");
      var query = new Parse.Query(User);
      query.get(Parse.User.current().id).then((user) => {
        user.set("suivi_exercice", this.allowProfessionalAccess);
        user.set("acces_donnees_therapeute", this.allowProfessionalAccess);
        user.set("acces_donnees_symbiocenter", this.allowStatistics);
        user.set("newsletters", this.subscribeNewsletter);
        user.set("newsletter_jour", this.getDaysSelected());
        //console.log(this.getDaysSelected());
        user.save();
      });
      this.$emit("continue");
    },
    getDaysSelected() {
      return Object.keys(this.selectedDays).filter(
        (day) => this.selectedDays[day]
      );
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .day-preferences .days {
    /* display: -webkit-box; */
    display: -ms-flexbox;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.preferences-page {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
}

.page-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #239380;
}

.page-description {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.day-preferences {
  display: flex;
  margin: 20px 0;
  padding-bottom: 20px;
  border-bottom: 2px solid #239380;
  width: 40%;
  margin-left: auto;
  margin-right: auto;

  .days {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    label {
      font-size: 18px;
      margin-top: 10px;
      color: #555;
    }

    input[type="checkbox"] {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }
  }
}

.authorization {
  margin-top: 40px;

  .authorization-item {
    // display: flex;
    // align-items: center;
    margin-top: 20px;

    label {
      font-size: 18px;
      margin-left: 10px;
      color: #555;
    }

    input[type="checkbox"] {
      margin-top: 5px;
    }
  }
}

.continue-button {
  background-color: #239380;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #239380;
  }
}
</style>
