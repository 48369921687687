<template>
  <transition name="fade">
    <div v-if="showNotification" class="update-notification">
      <div class="notification-header">
        <div class="title-with-icon">
          <i class="fas fa-sync"></i>
          <h3>Mise à jour</h3>
        </div>
        <button @click="closeNotification"><i class="fas fa-times"></i></button>
      </div>
      <div class="notification-body">
        <p>
          <strong style="color: white">Version:</strong>
          {{ maj[0].attributes.version }}
        </p>
        <ul>
          <li v-for="item in maj[0].attributes.features" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="notification-footer">
        <button
          class="acknowledge-button acknowledge-icon"
          @click="acknowledgeUpdate"
        >
          <i class="fas fa-check"></i> Compris
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export default {
  data() {
    return {
      showNotification: true,
      version: "2.0.1",
      newFeatures: [
        "Ajout de la fonctionnalité X",
        "Correction du bug Y",
        "Amélioration de la performance Z",
      ],
    };
  },
  props: {
    maj: {
      default: true,
    },
  },
  created()
  {
    this.checkIfAcknowledged();
  },
  methods: {
    closeNotification() {
      this.showNotification = false;
    },
    acknowledgeUpdate() {
      ParseConfig();
      const MajStatut = Parse.Object.extend("maj_user_statuts");
      const majStatut = new MajStatut();
      majStatut.set("user", Parse.User.current());
      majStatut.set("maj", this.maj[0]);
      majStatut.set("hasViewed", true);
      try {
        majStatut.save();
        //console.log("Statut de la mise à jour sauvegardé");
        this.showNotification = false;
      } catch (error) {
        //console.log(error);
      }
    },
    async checkIfAcknowledged() {
      const MajStatut = Parse.Object.extend("maj_user_statuts");
      const query = new Parse.Query(MajStatut);
      query.equalTo("user", Parse.User.current());
      query.equalTo("maj", this.maj[0]);

      try {
        const results = await query.first();
        if (results) {
          this.showNotification = false;
        }
      } catch (error) {
        //console.log(
        //   "Erreur lors de la vérification du statut de la mise à jour :",
        //   error
        // );
      }
    },
  },
};
</script>

<style scoped>
/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Notification */
.update-notification {
  background-color: #489181;
  color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 320px;
  z-index: 1000;
  font-family: "Arial", sans-serif;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.notification-body ul {
  margin-top: 10px;
  margin-left: 20px;
  list-style-type: disc;
}

.notification-body li {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.4;
}
.update-notification:hover {
  background-color: #58a190;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
}

/* Header */
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.title-with-icon {
  display: flex;
  align-items: center;
}

.title-with-icon i {
  margin-right: 8px;
  font-size: 24px;
}

/* Body */
.notification-body {
  font-size: 18px;
  line-height: 1.5;
}

/* Button */
button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
}

button:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}
.notification-footer {
  display: flex;
  justify-content: center; /* Centre le contenu horizontalement */
  margin-top: 10px;
}

/* Bouton Compris */
/* Bouton Compris */
.acknowledge-button {
  background-color: #58a190;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Réduit la taille du texte du bouton */
  transition: background-color 0.3s ease-in-out;
}

/* Icône dans le bouton */
.acknowledge-icon {
  font-size: 14px; /* Réduit la taille de l'icône */
  margin-right: 4px; /* Espacement entre l'icône et le texte */
}

.acknowledge-button:hover {
  background-color: #67b1a0;
}
/* Responsive design */
@media (max-width: 768px) {
  .update-notification {
    width: 280px; /* Réduit la taille de la notification sur petits écrans */
    padding: 15px;
  }

  .title-with-icon i {
    font-size: 20px; /* Réduit la taille de l'icône du titre */
  }

  .title-with-icon h3 {
    font-size: 1.2rem; /* Réduit la taille du texte du titre */
  }

  .notification-body {
    font-size: 16px; /* Réduit la taille du texte du corps */
  }

  .notification-body li {
    font-size: 14px; /* Réduit la taille du texte de la liste */
  }

  .acknowledge-button {
    font-size: 14px; /* Réduit la taille du texte du bouton */
    padding: 6px 12px; /* Réduit le padding du bouton */
  }

  .acknowledge-icon {
    font-size: 12px; /* Réduit la taille de l'icône du bouton */
  }
}
</style>
