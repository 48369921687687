<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-16">
          <div class="chart-result">
            <!-- <chartFcVue
                :valeurX="valeurX"
                :tabRowFc="TabRowFC"
                :defaultOpenedDetails="DefaultOpenedDetails"
              /> -->

            <chartBiofeedback
              :valeurX="valeurX"
              :tabRowFc="TabRowFC"
              :tabRowCC="TabRowCC"
              :defaultOpenedDetails="DefaultOpenedDetails"
            />
            <!-- <chartBiofeedbackCC
                :valeurX="ValeurCCX"
                :tabRowFc="TabRowFC"
                :tabRowCC="TabRowCC"
                :defaultOpenedDetails="DefaultOpenedDetails"
              /> -->
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="circleFCMoyenne1">
            <div class="circleFCMoyenne2">
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ fcMoyenne ? fcMoyenne : "0" }}
                </span>
                bpm
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            {{ $t("moyenneFrequenceCardiaque") }}
          </p>
        </div>

        <!-- <div class="column is-4 placementCenter">
          <div class="placementInfoBpm">
            <div class="divplacementInfoBpm">
              <span class="is-size-4">
                {{ fcMoyenne ? fcMoyenne : "0" + " bpm" }}
              </span>
              <p
                class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
              >
                {{ $t("moyenneFrequenceCardiaque") }}
              </p>
            </div>
          </div>
        </div> -->
        <div class="column is-4">
          <div class="columns centerDivColumn">
            <!-- <div class="column is-size-4 has-text-centered">
              {{ valeurRouge ? valeurRouge : "0" }}%
              <div class="respi_progress_bar_result">
                <div
                  class="cc_red_result"
                  :style="{
                    width: valeurRouge ? valeurRouge + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div>
            <div class="column is-size-4 has-text-centered">
              {{ valeurOrange ? valeurOrange : "0" }}
              %
              <div class="respi_progress_bar_result">
                <div
                  class="cc_orange_result"
                  :style="{
                    width: valeurOrange ? valeurOrange + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div>
            <div class="column is-size-4 has-text-centered">
              {{ valeurVerte ? valeurVerte : "0" }}%
              <div class="respi_progress_bar_result">
                <div
                  class="cc_green_result"
                  :style="{
                    width: valeurVerte ? valeurVerte + '%' : 0 + '%',
                  }"
                ></div>
              </div>
            </div> -->
            <div class="card-relecture" style="margin-top: 10px">
              <p>% de temps passé dans chaque zone de cohérence</p>
              <div class="progress-relecture">
                <div
                  class="progress-bar-relecture progress-bar-danger-relecture"
                  role="progressbar"
                  :style="{ width: valeurRouge + '%' }"
                  :class="{ 'black-percentage': valeurRouge === 0 }"
                  aria-valuenow="redPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ valeurRouge }}%
                </div>
              </div>
              <div class="progress-relecture">
                <div
                  class="progress-bar-relecture progress-bar-warning-relecture"
                  role="progressbar"
                  :style="{ width: valeurOrange + '%' }"
                  :class="{ 'black-percentage': valeurOrange === 0 }"
                  aria-valuenow="yellowPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ valeurOrange }}%
                </div>
              </div>
              <div class="progress-relecture">
                <div
                  class="progress-bar-relecture progress-bar-success-relecture"
                  role="progressbar"
                  :style="{ width: valeurVerte + '%' }"
                  :class="{ 'black-percentage': valeurVerte === 0 }"
                  aria-valuenow="greenPercentage"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ valeurVerte }}%
                </div>
              </div>
            </div>
          </div>
          <div class="card-relecture is-size-4 has-text-centered">
            <p>Durée de l'exercice</p>
            <div>
              <p>
                <span class="duration">{{ formatDuration(timeElapsed) }}</span>
                <i class="fas fa-clock logoRelecture"></i>
              </p>
            </div>
          </div>
          <!-- <div class="column is-size-4 has-text-centered">
              <div class="card-relecture">
                <p>Temps de calibrage</p>
                <div>
                  <p>
                    <span class="duration">{{ labelChrono / 60 }}</span>
                    <span class="unit">min</span>
                    <i class="fas fa-stopwatch logoRelecture"></i>
                  </p>
                </div>
              </div>
            </div> -->
        </div>
        <div class="column is-4">
          <div class="circleFCMoyenne1">
            <div
              class="circleFCMoyenne4"
              :style="{
                'background-color': couleurCCMoyenne,
              }"
            >
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ ccMoyenne ? ccMoyenne : "0" }}
                </span>
                %
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            {{ $t("moyenneCc") }}
          </p>
        </div>

        <!-- <div class="column is-4 placementCenter">
          <div
            class="placementInfoBpm"
            :style="{
              'border-color': couleurMoyenne,
              'border-width':
                '8px' /* spécifiez la largeur de la bordure selon vos besoins */,
              'border-style':
                'solid' /* spécifiez le style de la bordure selon vos besoins */,
            }"
          >
            <div class="divplacementInfoBpm">
              <span class="is-size-4">
                {{ ccMoyenne ? ccMoyenne : "0" }}
              </span>
              <p
                class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
              >
                Moyenne générale de l'indice de bien-être
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="column is-size-4 has-text-centered">
         
        </div> -->
        <div class="column is-16">
          <!-- Bouton stylé avec icône de flèche qui change selon l'état du commentaire -->
          <button @click="toggleCommentaire" class="toggle-button">
            <span v-if="showCommentaire">&#9660; Masquer le commentaire</span>
            <!-- Flèche vers le bas -->
            <span v-else>&#9658; Ajouter un commentaire</span>
            <!-- Flèche vers la droite -->
          </button>
          <!-- Input conditionnellement affiché avec une transition -->
            <b-field label="Commentaire :" v-if="showCommentaire">
              <b-input
                @input="emitCommentaire"
                maxlength="200"
                type="textarea"
                v-model="commentaireMoniteur"
              ></b-input>
            </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import chartBiofeedback from "../tableauDeBord/chartBiofeedback.vue";

export default {
  data() {
    return {
      firstObject: {},
      ouvertureSuiviCcUtilisateur: "",
      fileId: "7rOgxcWOD4",
      protocole: "Entraînement libre",
      userSelected: "tous",
      DisplayRelecture: false,
      commentaireMoniteur: "",
      showCommentaire: false,
    };
  },
  props: [
    "valeurX",
    "fcMoyenne",
    "ccMoyenne",
    "couleurCCMoyenne",
    "TabRowFC",
    "DefaultOpenedDetails",
    "ValeurCCX",
    "TabRowCC",
    "labelChrono",
    "timeElapsed",
    "valeurRouge",
    "valeurVerte",
    "valeurOrange",
  ],
  components: {
    // suivieCc,
    chartBiofeedback,
  },
  computed: {
    ...User.mapState(["user"]),
  },
  methods: {
    test() {
      ////console.log all props
      //console.log(this.$props);
    },
    emitCommentaire() {
      this.$emit("commentaire-change", this.commentaireMoniteur);
    },
    toggleCommentaire() {
      this.showCommentaire = !this.showCommentaire;
    },
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}min${remainingSeconds}s`;
    },
  },
  mounted() {
    ParseConfig();
    const objectFolder = Parse.Object.extend("folder");
    const QueryFolders = new Parse.Query(objectFolder);
    QueryFolders.equalTo("objectId", "ALoaWJgV7l");
    QueryFolders.find().then((results) => {
      this.folders = results;
      this.firstObject = this.folders.shift();
      return this.folders, this.firstObject;
    });
  },
  created() {
    console.log("valeurCCX", this.ValeurCCX);
    console.log("TabRowCC", this.TabRowCC);
    console.log("TabRowFC", this.TabRowFC);
    console.log("DefaultOpenedDetails", this.DefaultOpenedDetails);
    if (this.ccMoyenne > 0 && this.ccMoyenne < 30) {
      this.couleurMoyenne = "#ff0000";
    } else if (this.ccMoyenne > 30 && this.ccMoyenne < 60) {
      this.couleurMoyenne = "#ff7f00";
    } else if (this.ccMoyenne > 60 && this.ccMoyenne < 90) {
      this.couleurMoyenne = "#e9e935";
    } else if (this.ccMoyenne > 90 && this.ccMoyenne < 120) {
      this.couleurMoyenne = "#78e20d";
    } else if (this.ccMoyenne > 120 && this.ccMoyenne < 150) {
      this.couleurMoyenne = "#29c029";
    }
  },
};
</script>
<style>
.card-relecture {
  padding: 10px;
  margin-bottom: 5px;
}
.encadrement {
  margin: 0 auto;
  padding: 8px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.buttonToggleCommentaire {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.unit {
  font-size: 0.8em;
  color: #888;
}
.logoRelecture {
  margin-left: 10px;
}
.animated-timer {
  font-size: 3em;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.card-relecture {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.placementInfoBpm {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.divplacementInfoBpm {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.placementCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerDivColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button {
  background-color: #f5f5f5; /* Couleur de fond légère */
  border: 1px solid #ccc; /* Bordure subtile */
  padding: 8px 16px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 10px;
}

.toggle-button:hover {
  background-color: #e2e2e2; /* Effet de survol plus sombre */
  color: #000;
}

.toggle-button i {
  margin-right: 8px; /* Espace entre l'icône et le texte */
}

/* Animation pour la transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
