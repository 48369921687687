<template>
<div v-if="affichageText" class="infoBox activeState">
    <div class="flexWrapper">
      <div class="textContent">
        <p>Introduction</p>
      </div>
      <div class="iconContent">
        <img
          class="toggleArrow activeArrow"
          src="../../../../assets/img/ChevronDownBlancV2.png"
        />
      </div>
    </div>
  </div>
  <!-- OTHERWISE SHOW THIS BLOCK -->
  <div v-else class="infoBox">
    <div class="flexWrapper">
      <div class="textContent">
        <p>Introduction</p>
      </div>
      <div class="iconContent">
        <img
          class="toggleArrow"
          src="../../../../assets/img/ChevronDownV2.png"
        />
      </div>
    </div>
  </div>
</template>
<script> 
export default {
  name: "IntroductionComponent",
  props: {
    affichageText: Boolean,
  },
  methods: {
  },
};
</script>
<style scoped lang="scss">
.infoBox {
  width: 80%;
  margin: 1% auto 0 auto;
  padding: 1rem;
  background-color: #FFF;
  border: 1px solid #00800069;
  border-radius: 6px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .infoBox {
    width: 95%; /* plus de largeur sur un petit écran */
    padding: 1rem; /* Moins d'espacement intérieur sur les petits écrans */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .infoBox {
    width: 85%; /* largeur intermédiaire pour les tablettes */
  }
}

@media (min-width: 1025px) {
  .infoBox {
    width: 80%; /* largeur fixe sur un grand écran */
  }
}

.infoBox.activeState,
.infoBox:hover {
  background-color: #239380;
  color: white;
}

.infoBox:hover .toggleArrow {
  content: url("../../../../assets/img/ChevronDownBlancV2.png");
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textContent, .iconContent {
  display: flex;
  align-items: center;
}

.textContent p {
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  .textContent p {
    font-size: 1.2rem; /* taille du texte plus petite pour les petits écrans */
  }
}

.toggleArrow {
  width: 24px;
  transition: all 0.3s ease;
}

.toggleArrow.activeArrow {
  transform: rotate(180deg);
}

/* Responsive */
@media (max-width: 768px) {
  .toggleArrow {
    width: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .toggleArrow {
    width: 24px; /* taille intermédiaire pour les tablettes */
  }
}

@media (min-width: 1025px) {
  .toggleArrow {
    width: 24px;
  }
}</style>