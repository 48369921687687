<template>
  <div>
    <div v-if="actualites.length === 0">Aucune Actualite</div>
    <section
      style="margin-top: 10px"
      v-for="actualite in actualites"
      :key="actualite.id"
      :paginated="true"
      :per-page="2"
    >
      <b-message :type="actualite.attributes.Category">
        <div>
          <strong>{{ actualite.attributes.title }}</strong>
        </div>
        <!-- <div>{{ actualite.attributes.type_pratique }}</div> -->
        <div v-if="actualite.attributes.resource">
          <img :src="actualite.attributes.resource.url()" />
        </div>
        <div v-html="actualite.attributes.text"></div>
        <div
          v-if="actualite.attributes.piecejointe"
          class=""
          style="margin-top: 5px"
        >
          <img
            class="icon-valide"
            src="../../../assets/img/trombone.svg"
            style="width: 1em; margin-right: 1%"
          />
          <a
            :href="actualite.attributes.piecejointe.url()"
            target="_blank"
            download
            >Pièce jointe</a
          >
        </div>
      </b-message>
    </section>
    <!-- <b-table class="is-size-6" ref="table" :data="actualites" :show-detail-icon="false" :paginated="true" :per-page="5">
                <template slot-scope="props">
                    <b-table-column label="Categorie">
                        <b-icon pack="fa" icon="circle" size="is-small" :style="getCategoryColor(props.row.attributes.Category)"></b-icon>
                    </b-table-column>
                    <b-table-column label="Titre">
                        {{ props.row.attributes.title }}
                    </b-table-column>
                    <b-table-column label="Description">
                        <div v-html="props.row.attributes.text"></div>
                    </b-table-column>
                    <b-table-column label="Date de réalisation">
                        {{ new Date(props.row.createdAt).toLocaleDateString() }}
                    </b-table-column>
                    <b-table-column label="Heure de création">
                        <span>
                            {{ new Date(props.row.createdAt).getHours() }}:{{
                                new Date(props.row.createdAt).getMinutes()
                            }}
                        </span>
                    </b-table-column>
                </template>
            </b-table> -->
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getObjectById } from "@/services/parse/getObject.js";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");
export default {
  name: "NewsProfil",
  props: ["group"],
  data() {
    return {
      actualites: [],
      actualite: [],
      type_pratique: [],
      colorCategory: null,
    };
  },
  computed: {
    ...User.mapGetters(["user"]),
  },
  methods: {
    async getLastNews() {
      try {
        const group = await getObjectById("user_group", this.group.objectId);
        const ObjectNews = Parse.Object.extend("news");
        const QueryNews = new Parse.Query(ObjectNews);
        QueryNews.equalTo("group", group);
        QueryNews.descending("updatedAt");
        QueryNews.containedIn("type_pratique", [this.user.type_pratique]);
        this.actualites = await QueryNews.find();
        this.type_pratique = this.actualites.map(
          (actualite) => actualite.attributes.type_pratique
        );
      } catch (error) {
        console.error(
          `Erreur lors de la récupération des actualités : ${error.message}`
        );
      }
    },
    getCategoryColor(Category) {
      if (Category === "is-warning") {
        return { color: "#FFDD57" };
      } else if (Category === "is-danger") {
        return { color: "#FF3860" };
      } else {
        return { color: "#DBDBDB" };
      }
    },
  },
  async mounted() {
    ParseConfig();
    await this.getLastNews();
  },
};
</script>
