import ParseConfig from "@/api/parse.config";
import Parse from "parse";
// Récupérer les questionnaires déja fais
export async function getFilesDonesID(id_user) {
  ParseConfig();
  const ObjectFileDone = Parse.Object.extend("file_done");
  const ObjectUser = Parse.Object.extend("User");
  const QueryFileDone = new Parse.Query(ObjectFileDone);
  const QueryUser = new Parse.Query(ObjectUser);
  QueryUser.equalTo("objectId", id_user);
  QueryFileDone.matchesQuery("user", QueryUser);
  
  QueryUser.find().then((user) => {
    console.log("user", user);
  });
  try {
    const ResponseFileDone = await QueryFileDone.find();
    const foldersDoneIds = ResponseFileDone.map((fileDone) =>
      fileDone.attributes.protocole ? fileDone.attributes.protocole.id : null
    );
    return foldersDoneIds;
  } catch (e) {
    return null;
  }
}
