<template>
  <div class="DivButtonGraph">
    <button class="ButtonGraph" @click="toggleChartAverageCC(idBrique)">
      Afficher le graphique d'avancement
      <i
        :class="[
          'fas',
          displayChartAverageCC ? 'fa-chevron-up' : 'fa-chevron-down',
        ]"
      ></i>
    </button>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  data() {
    return {
      displayChartAverageCC: false,
    };
  },
  props: {
    idBrique: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleChartAverageCC(idBrique) {
      console.log("idBrique", idBrique);
      this.displayChartAverageCC = !this.displayChartAverageCC;
      if (this.displayChartAverageCC) {
        this.getData(idBrique);
      }
    },
    async getData(idBrique) {
      try {
        console.log("getData", idBrique);

        const currentUser = Parse.User.current();
        if (!currentUser) {
          throw new Error("No current user found");
        }
        console.log("Current User ID", currentUser.id);

        const [userResult, folderResult] = await Promise.all([
          new Parse.Query(Parse.User).get(currentUser.id),
          new Parse.Query("folder").equalTo("objectId", idBrique).first(),
        ]);

        console.log("User Result", userResult);
        console.log("Folder Result", folderResult);

        if (!folderResult) {
          throw new Error("Folder not found");
        }
        const linkQuery = new Parse.Query("link").equalTo(
          "parent_folder",
          folderResult
        );
        //récupère celui ayant comme order 2
        linkQuery.equalTo("order", 2);
        const linkResult = await linkQuery.first();
        console.log("Link Result", linkResult);

        const linkQueryFile = new Parse.Query("link").equalTo(
          "parent_folder",
          linkResult.attributes.folder
        );
        //inclure le folder
        linkQueryFile.include("folder");
        const fileResult = await linkQueryFile.find();
        console.log("File Result", fileResult);
        //récupère dans les filesResult seulement ceux n'ayant pas l'attribut VisibleFront
        const fileResultFiltered = fileResult.filter(
          (file) => !file.attributes.folder.attributes.VisibleFront
        );
        console.log("File Result Filtered", fileResultFiltered);
        var files = [];
        for (let i = 0; i < fileResultFiltered.length; i++) {
          const fileQuery = new Parse.Query("link").equalTo("parent_folder", fileResultFiltered[i].attributes.folder);
          const fileResult = await fileQuery.find();
          console.log("File Result", fileResult);
          for (let j = 0; j < fileResult.length; j++) {
            files.push(fileResult[j]);
          }
        }
        console.log("Files", files);

        const fileDoneQuery = new Parse.Query("file_done")
          .equalTo("user", userResult)
          .equalTo("brique", folderResult);

        const fileDoneResult = await fileDoneQuery.find();

        console.log("File Done Result", fileDoneResult);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    },
  },
};
</script>
<style scoped>
.DivButtonGraph {
  display: flex;
  justify-content: center;
}

.ButtonGraph {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 8px 16px; /* Padding around the text */
  font-size: 16px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
}

.ButtonGraph:hover {
  background-color: #1e7c6d; /* Darker shade of green on hover */
  transform: translateY(2px); /* Slightly move the button down */
}

.ButtonGraph i {
  margin-left: 8px; /* Space between text and icon */
}
</style>
