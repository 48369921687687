<template>
  <div class="gauge-container">
    <div class="gauge" :style="{ width: gaugeValue + '%' }">
      <span class="label" style="left: 5%">Douleur</span>
      <span class="label" style="left: 25%">Tension</span>
      <span class="label" style="left: 50%">Neutral</span>
      <span class="label" style="left: 75%">Confort</span>
      <span class="label" style="left: 94%">Lâcher-prise</span>
    </div>
    <div class="textDescription">
      <span>Indice de lâcher-prise psycho-physiologique, </span>
      <span>selon la théorie Polyvagale de Stephen W. Porges</span>
    </div>
    <div class="legend">
      <div class="arrow-left" style="left: 0%">
        <span>
          Zone de mobilisation / Stress
        </span>
      </div>
      <!-- <span class="legend-label" style="right: 2%"
        >Sécurité / Engagement social</span
      > -->
      <div class="arrow-right" style="right: 0%">
        <span>Zone de sécurité / Engagement social</span>
      </div>
    </div>
    <div v-if="isConnected" class="value-bubble" :style="{ left: gaugeWidth + '%' }">
      <small>Indice de lâcher-prise moyenné</small>
      <span>{{ value }}</span>
    </div>
    <div v-if="isConnected" class="needle" :style="{ left: gaugeWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      internalValue: this.value, // Utilisez une propriété interne pour la valeur dynamique
      gaugeValue: 100,
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    isConnected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gaugeWidth() {
      const scaledValue = (Math.max(0, Math.min(this.value, 150)) / 150) * 100;
      return scaledValue;
    },
  },
  mounted() {},
  beforeUnmount() {
    clearInterval(this.interval); // Nettoyez l'intervalle lorsque le composant est détruit
  },
};
</script>
<style scoped>
.gauge-container {
  width: 90%;
  height: 50px;
  background-color: #eee;
  border-radius: 10px;
  position: relative;
  margin: 40px auto;
  margin-bottom: 70px;
  margin-top: 90px;
}

.gauge {
  position: relative;
  height: 100%;
  background: linear-gradient(to right, red, yellow 50%, green);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center; /* Centre les étiquettes verticalement */
}

.label {
  position: absolute;
  color: black; /* Assurez-vous que la couleur du texte se démarque sur le fond */
  font-size: 14px; /* Ajustez selon vos besoins */
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
}

@media (max-width: 600px) {
  .label {
    font-size: 6px; /* Ajustez selon vos besoins */
  }
}

@media (max-width: 400px) {
  .label {
    font-size: 8px; /* Ajustez selon vos besoins */
  }
}

@media (max-width: 900px) {
  .label {
    font-size: 8px; /* Ajustez selon vos besoins */
  }
}

.needle {
  position: absolute;
  top: 10%;
  left: 50%;
  border: 20px solid transparent;
  border-bottom-color: black; /* La couleur de l'aiguille */
  transform: translateX(-50%) translateY(-50%) rotate(180deg); /* Rotation de 180 degrés */
  width: 0;
  height: 0;
}
.value-bubble {
  position: absolute;
  top: -180%; /* Ajustez la position verticale selon vos besoins */
  transform: translateX(-50%);
  background-color: #fff; /* Couleur de fond de la bulle */
  color: #333; /* Couleur du texte */
  padding: 10px 20px 10px 20px;
  border-radius: 25px; /* Arrondit les bords pour l'effet de bulle */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Ombre portée légère pour la profondeur */
  font-size: 14px; /* Ajustez selon vos besoins */
  white-space: nowrap; /* Assurez-vous que le texte reste sur une seule ligne */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  border: 2px solid black;
  font-weight: bold;
  font-size: 1.3rem;
}
@media (max-width: 600px) {
  .value-bubble {
    font-size: 12px; /* Taille de police plus petite pour les petits écrans */
    padding: 3px 8px; /* Ajustement du padding pour les petits écrans */
  }
}
@media (max-width:1300px)
{
  .value-bubble {
    top: -130%; /* Ajustez la position verticale selon vos besoins */
    font-size:10px;
  }

}
.legend {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Cette propriété n'est pas nécessaire avec les positions absolues spécifiques, mais elle est utile pour la structure */
}
@media screen and (max-width: 1300px) {
  .legend {
    display: none; /* Masque la légende sur les petits écrans */
  }
}
.legend-label {
  position: absolute;
  color: #333; /* Couleur du texte pour les étiquettes de légende */
  font-size: 14px; /* Taille de la police pour la légende */
  padding: 2px 5px; /* Espacement intérieur pour la légende */
  border-radius: 10px; /* Bords arrondis pour un look moderne */
  white-space: nowrap; /* Gardez la légende sur une seule ligne */
  font-weight: bold; /* Texte en gras pour se démarquer */
}

/* responsive du legend-label */
@media (max-width: 600px) {
  .legend-label {
    font-size: 12px; /* Taille de police plus petite pour les petits écrans */
    padding: 2px 5px; /* Ajustement du padding pour les petits écrans */
  }
}

@media (max-width: 400px) {
  .legend-label {
    font-size: 10px; /* Taille de police plus petite pour les petits écrans */
    padding: 2px 5px; /* Ajustement du padding pour les petits écrans */
  }
}

.arrow-box {
  position: relative;
  background: #f4f4f4;
  padding: 10px 20px; /* Ajustez l'espacement autour du texte ici */
  border: 1px solid #ccc;
}

.arrow-box:after,
.arrow-box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow-box:after {
  border-color: rgba(244, 244, 244, 0);
  border-right-color: #f4f4f4;
  border-width: 10px; /* Ajustez la taille de la flèche ici */
  margin-top: -10px;
}

.arrow-box:before {
  border-color: rgba(204, 204, 204, 0);
  border-right-color: #ccc;
  border-width: 11px; /* Cette valeur doit être légèrement plus grande que celle du :after pour créer l'effet de bordure */
  margin-top: -11px;
}

.arrow-right {
  position: absolute;
  width: 30%; /* Ajustez la largeur selon vos besoins */
  height: 50px; /* Ajustez la hauteur selon vos besoins */
  background-color: #dadada; /* Couleur de fond de la flèche */
  text-align: center; /* Centrer le texte */
  line-height: 50px; /* Ajuster avec la hauteur pour centrer le texte verticalement */
  /*margin-right: 30px; /* Espace pour la pointe de la flèche */
}

@media (max-width: 1300px) {
  .arrow-right {
    width: 300px; /* Ajustez la largeur selon vos besoins */
    height: 40px; /* Ajustez la hauteur selon vos besoins */
  }
}

.arrow-right::after {
  content: "";
  position: absolute;
  right: -30px; /* Ajustez pour changer la position de la pointe */
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 30px; /* Crée la pointe de la flèche */
  border-color: transparent transparent transparent #dadada; /* Ajustez la couleur de la pointe */
}

.arrow-left {
  position: relative;
  width: 30%; /* Ajustez la largeur selon vos besoins */
  height: 50px; /* Ajustez la hauteur selon vos besoins */
  background-color: #dadada; /* Couleur de fond de la flèche */
  text-align: center; /* Centrer le texte */
  line-height: 50px; /* Ajuster avec la hauteur pour centrer le texte verticalement */
}
@media (max-width: 130px) {
  .arrow-left {
    width: 300px; /* Ajustez la largeur selon vos besoins */
    height: 40px; /* Ajustez la hauteur selon vos besoins */
  }
}

.arrow-left::before {
  content: "";
  position: absolute;
  left: -30px; /* Déplace la pointe vers la gauche */
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 30px 25px 0; /* Crée la pointe de la flèche */
  border-color: transparent #dadada transparent transparent; /* Ajustez la couleur de la pointe pour qu'elle corresponde au corps */
}

.textDescription {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style:italic;
  display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 600px) {
  .textDescription {
    font-size: 10px; /* Taille de police plus petite pour les petits écrans */
    margin-top:15px;
  }
}
.value-bubble small {
  font-size: 0.8rem; 
  font-weight: normal; 
  margin-bottom: 5px;
}
</style>
