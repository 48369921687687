<template>
  <div>
    <template v-if="presentation && (file.completed || !file.completed)">
      <div v-if="resultatQCM_Object">
        <p class="info-text">
          Vous avez déjà complété ce questionnaire. Voici votre dernier résultat
          :
        </p>
        <div class="data-container">
          <div class="data-item">
            <i class="fas fa-star"></i>
            <span>Note : {{ resultatQCM_Object.note }}</span>
          </div>
          <div class="data-item">
            <i class="fas fa-clock"></i>
            <span>Temps écoulé : {{ resultatQCM_Object.time_elapsed }}s</span>
          </div>
          <div class="data-item">
            <i class="fas fa-calendar-alt"></i>
            <span>Date : {{ formatDate(resultatQCM_Object.date) }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p v-html="file.attributes.text"></p>
        <div class="PlacementBoutonQuestionnaire">
          <button
            class="buttonDemarrerQuestionnaire"
            @click="recupIdLink(file.attributes.questionnaire_.id)"
          >
            COMMENCER
          </button>
        </div>
      </div>
    </template>

    <template v-if="question">
      <div v-for="(n, i) in element_questionnaire2">
        <div v-for="(n, i) in element2" class="question-container">
          <div class="QuestionTitleQCM">
            <div class="numberCircle">
              {{
                element2[i].attributes.number +
                1 +
                "/" +
                element_questionnaire2[i].attributes.number_question
              }}
            </div>
            <div class="QuestionQCM">
              {{ element2[i].attributes.text }}
            </div>
          </div>
          <div v-for="(n, i) in element_answer2">
            <label :for="element_answer2[i].attributes.points">
              <input
                :id="element_answer2[i].attributes.points"
                type="radio"
                :name="element_answer2[i].attributes.objectId"
                :value="element_answer2[i].attributes.points"
                v-model="localPicked"
              />
              {{ " " + element_answer2[i].attributes.text }}
            </label>
          </div>
          <div class="question-buttons-flex">
            <div class="PlacementButtonFlex">
              <button
                class="buttonChoix"
                style="color: white, backgroundColor:#239380';"
                v-if="number + 1 > 0"
                @click="PreviousQuestion(localPicked)"
              >
                QUESTION PRECEDENTE
              </button>
              <button class="buttonChoix" style="opacity: calc(0.5)" v-else>
                QUESTION PRECEDENTE
              </button>
              <button
                class="buttonChoix"
                style="color: white, backgroundColor:#239380'"
                v-if="
                  number + 1 <
                    element_questionnaire2[i].attributes.number_question &&
                  localPicked != undefined
                "
                @click="NextQuestion(localPicked, element2[i])"
              >
                QUESTION SUIVANTE
              </button>
              <button
                class="buttonChoix"
                style="opacity: calc(0.5)"
                v-if="
                  number + 1 <
                    element_questionnaire2[i].attributes.number_question &&
                  localPicked == undefined
                "
              >
                QUESTION SUIVANTE
              </button>
              <button
                class="buttonChoix"
                style="
                  color: white !important;
                  background-color: #239380 !important;
                "
                @click="FinalResultat(element_questionnaire2[i])"
                v-if="
                  number + 1 ==
                  element_questionnaire2[i].attributes.number_question
                "
              >
                VOIR LE RÉSULTAT
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="resultatQCM">
      <p class="ResultatScore">
        {{ "Score de " + resultat + " points" }}
      </p>
      <p v-if="nameQuestionnaire">
        {{
          "Nous vous remercions de vous être prêté(e) à notre test :" +
          nameQuestionnaire
        }}
      </p>
      <div class="PlacementBoutonTerminer">
        <button
          style="margin-left:auto;margin-right:auto;color: white !important; backgroundColor:#239380 !important;'"
          class="buttonChoix"
          @click="TerminerExerciceQCM(file.id, resultat)"
        >
          Enregistrer et continuer
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localPicked: null,
    };
  },
  watch: {
    picked: {
      immediate: true,
      handler(newVal) {
        this.localPicked = newVal;
      },
    },
    localPicked(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("update:picked", newVal);
      }
    },
  },
  props: {
    presentation: {
      required: true,
      type: Boolean,
    },
    file: {
      required: true,
      type: Object,
    },
    Questionnairedate: {},
    question: {
      required: true,
      type: Boolean,
    },
    element_questionnaire2: {
      required: true,
      type: Array,
    },
    element2: {
      required: true,
      type: Array,
    },
    element_answer2: {
      required: true,
      type: Array,
    },
    number: {
      required: true,
      type: Number,
    },
    picked: {
      required: true,
    },
    resultatQCM: {
      required: true,
      type: Boolean,
    },
    resultat: {
      required: true,
      type: Number,
    },
    nameQuestionnaire: {
      required: true,
      type: String,
    },
    resultatQCM_Object: {
      type: Object,
    },
  },
  created() {
    //afficher les props
    console.log("presentation", this.presentation);
    console.log("file", this.file);
    console.log("question", this.question);
    console.log("element_questionnaire2", this.element_questionnaire2);
    console.log("element2", this.element2);
    console.log("element_answer2", this.element_answer2);
    console.log("number", this.number);
    console.log("picked", this.picked);
    console.log("resultatQCM", this.resultatQCM);
    console.log("resultat", this.resultat);
    console.log("nameQuestionnaire", this.nameQuestionnaire);
    console.log("resultatQCM_Object", this.resultatQCM_Object);
  },
  methods: {
    recupIdLink(id) {
      this.$emit("recupIdLink", id);
    },
    PreviousQuestion(picked) {
      this.$emit("PreviousQuestion", picked);
      this.localPicked = null;
    },
    NextQuestion(picked, element2) {
      this.$emit("NextQuestion", picked, element2);
      this.localPicked = null;
    },
    FinalResultat(element_questionnaire2) {
      this.$emit("FinalResultat", element_questionnaire2);
    },
    TerminerExerciceQCM(id, resultat) {
      this.$emit("TerminerExerciceQCM", id, resultat);
    },
    formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    },
  },
};
</script>
<style scoped>
.question-buttons-flex {
  display: flex;
}
.PlacementButtonFlex {
  margin-left: auto;
  margin-right: auto;
}

.PlacementBoutonTerminer {
  display: flex;
}

.data-container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.data-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.data-item i {
  margin-right: 10px;
  color: rgb(35, 147, 128);
  font-size: 20px;
}

.info-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  font-style: italic;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonDemarrerQuestionnaire {
  padding: 10px 20px;
  background-color: rgb(35, 147, 128);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonDemarrerQuestionnaire:hover {
  background-color: rgb(35, 147, 128);
}
</style>
