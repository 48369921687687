<template>
  <div>
    <!-- Autre contenu du composant -->
    <div class="popup" v-if="showModal">
      <div class="popup-content">
        <h3 class="title">Mode limité</h3>
        <p>
          Vous êtes actuellement en mode limité. Pour accéder aux exercices,
          veuillez vous abonner.
        </p>
        <button class="button is-primary" @click="subscribe">S'abonner</button>
        <button class="button" @click="closePopup">Fermer</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    subscribe() {
      // Redirection vers la page d'abonnement to: "/abonnement"
      window.location.href =
        "https://www.symbiocenter.com/gestion/#/abonnement";
      this.showModal = false; // Fermer la pop-up après l'action
    },
    closePopup() {
      this.showModal = false; // Fermer la pop-up
      this.$emit("close"); // Émettre un événement pour informer le parent
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  text-align: center;
}

.title {
  margin-bottom: 10px;
  font-size: 20px;
}

.button {
  margin-top: 20px;
}

.button + .button {
  margin-left: 10px;
}
</style>
