<template>
  <div class="video-player">
    <div class="player-controls">
      <!-- <div class="stopVideo">
            <button class="close-button" title="Close" @click="emitClose">
              <svg width="32"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#239380"
              >
                <path
                  fill="#239380"
                  d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                />
                <path
                  fill="#239380"
                  d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                />
              </svg>
            </button>
          </div> -->
      <div class="button_play">
        <!-- <button class="close-button" title="Close" @click="emitClose">
              <svg width="32"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#239380"
              >
                <path
                  fill="#ffffff"
                  d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                />
                <path
                  fill="#ffffff"
                  d="M14.293 14.293a.999.999 0 01-1.414 0L10 11.414l-2.879 2.879a.999.999 0 11-1.414-1.414L8.586 10 5.707 7.121a.999.999 0 111.414-1.414L10 8.586l2.879-2.879a.997.997 0 011.414 0 .999.999 0 010 1.414L11.414 10l2.879 2.879a.999.999 0 010 1.414z"
                />
              </svg>
            </button> -->
        <button class="play-button" title="Play">
          <svg
            v-if="videoPlay"
            width="42"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#239380"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            width="42"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="#239380"
            v-else
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="video-progress">
        <!-- <p v-if="indexVideo" class="titleVideo">
          {{ indexVideo + " " + titleVideo }}
        </p> -->
        <p class="titleVideo">{{ titleVideo }}</p>
        <div class="video-progress-filled"></div>
      </div>
      <div :class="screenWidth < 769 ? 'times' : 'time'" id="duree">
        <span class="current"> 0:00</span> / <span class="duration">0:00</span>
      </div>
      <div :class="screenWidth < 769 ? 'times' : 'time'" id="dureeapple">
        <span class="currents"> 0:00</span> /
        <span class="durations">0:00</span>
      </div>

      <img
        src="../assets/img/volume2.png"
        class="imgVolume"
        id="VolSituation1"
        style="display: none"
      />
      <input
        name="rangeVideo"
        type="range"
        class="volume"
        id="VolSituation2"
        min="0"
        max="1"
        step="0.01"
        value="1"
        style="display: none"
      />
    </div>
    <div class="VideoMiddlePlacement">
      <div>
        <div>
          <!-- <volume-control :volume="volume" @update:volume="volume = $event" /> -->
          <video
            @ended="VideoEnd"
            id="myVideo"
            ref="video"
            controlsList="nodownload"
            @click="toggleFullscreen"
            :src="'https://www.symbiocenter.com/assets/video/' + sourceVideo"
            class="video"
            autoplay
            :volume="volume"
          ></video>
          <div class="buttonFullScreen">
            <button class="playerFullScreens" @click="toggleFullscreen">
              Plein écran <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>
        <div class="bandeauvideo" style="height: 15px">
          <div>{{ titleVideo }}</div>
          <div>
            <button class="playerFullScreen" @click="toggleFullscreen">
              <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    videoPlay: true,
    message: "Titre",
    screenWidth: 0,
    showText: false,
    deviceType: null,
    os: null,
    volume: 0.5,
    isFullscreen: false,
  }),
  components: {},
  computed: {
    playerFullScreen() {
      return document.querySelector(".playerFullScreen");
    },
  },
  props: {
    titleVideo: {
      required: true,
    },
    sourceVideo: {
      required: true,
    },
    indexVideo: {
     type: Number,
    },
  },
  watch: {
    screenWidth: function (newWidth) {
      if (newWidth < 400) {
        this.$el.classList.remove("class1");
        this.$el.classList.add("class2");
      } else {
        this.$el.classList.remove("class2");
        this.$el.classList.add("class1");
      }
    },
  },

  methods: {
    toggleFullscreen() {
      if (!this.isFullscreen) {
        this.$refs.video.requestFullscreen();
      }
      this.isFullscreen = !this.isFullscreen;
    },

    playVideo() {
      this.$refs.video.play();
      this.$refs.video.volume = this.volume;
    },
    changeMessage() {
      this.message = this.titleVideo;
    },
    resetMessage() {
      this.message = "Titre";
    },
    VideoEnd() {
      this.$emit("video-finish", true);
    },
    detectDevice() {
      let userAgent = navigator.userAgent;
      let platform = navigator.platform;

      if (userAgent.indexOf("Windows NT 10.0") != -1) this.os = "Windows 10";
      else if (userAgent.indexOf("Windows NT 6.2") != -1) this.os = "Windows 8";
      else if (userAgent.indexOf("Windows NT 6.1") != -1) this.os = "Windows 7";
      else if (userAgent.indexOf("Windows NT 6.0") != -1)
        this.os = "Windows Vista";
      else if (userAgent.indexOf("Windows NT 5.1") != -1)
        this.os = "Windows XP";
      else if (userAgent.indexOf("Windows NT 5.0") != -1)
        this.os = "Windows 2000";
      else if (userAgent.indexOf("Mac") != -1) this.os = "Mac/iOS";
      else if (userAgent.indexOf("X11") != -1) this.os = "UNIX";
      else if (userAgent.indexOf("Linux") != -1) this.os = "Linux";
      else this.os = "Unknown";

      if (this.os === "Mac/iOS" && userAgent.indexOf("Mobile") != -1) {
        this.deviceType = "iPhone";
      } else if (this.os === "Mac/iOS" && userAgent.indexOf("iPad") != -1) {
        this.deviceType = "iPad";
      } else if (this.os === "Mac/iOS" && userAgent.indexOf("iPod") != -1) {
        this.deviceType = "iPod";
      } else if (this.os === "Android" && userAgent.indexOf("Mobile") != -1) {
        this.deviceType = "Android Phone";
      } else if (this.os === "Android" && userAgent.indexOf("Tablet") != -1) {
        this.deviceType = "Android Tablet";
      } else if (this.os === "Windows" && platform === "Win32") {
        this.deviceType = "Windows Desktop";
      } else {
        this.deviceType = "Unknown";
      }

      return this.deviceType, this.os;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    this.detectDevice();
    if (this.os === "Mac/iOS" || this.os === "UNIX" || this.os === "Linux") {
      document.getElementById("duree").style.display = "none";
    } else {
      document.getElementById("dureeapple").style.display = "none";
    }

    const videoPlayer = document.querySelector(".video-player");
    const video = videoPlayer.querySelector(".video");
    const playButton = videoPlayer.querySelector(".play-button");
    const volume = videoPlayer.querySelector(".volume");
    const currentTimeElement = videoPlayer.querySelector(".current");
    const durationTimeElement = videoPlayer.querySelector(".duration");
    const currentTimeElements = videoPlayer.querySelector(".currents");
    const durationTimeElements = videoPlayer.querySelector(".durations");
    const progress = videoPlayer.querySelector(".video-progress");
    const progressBar = videoPlayer.querySelector(".video-progress-filled");
    const playerFullScreen = this.playerFullScreen;

    playerFullScreen.addEventListener("click", openFullscreen);

    function openFullscreen() {
      if (video.requestFullscreen) {
        video.requestFullscreen();
        video.play();
      } else if (video.webkitRequestFullscreen) {
        /* Safari */
        video.webkitRequestFullscreen();
        video.play();
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen();
        video.play();
      }
    }

    //Play and Pause button
    playButton.addEventListener("click", () => {
      if (video.paused) {
        video.play();
        this.videoPlay = true;
      } else {
        video.pause();
        this.videoPlay = false;
      }
    });

    //volume
    volume.addEventListener("mousemove", (e) => {
      video.volume = e.target.value;
    });

    //current time and duration
    const currentTime = () => {
      let currentMinutes = Math.floor(video.currentTime / 60);
      let currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
      let durationMinutes = Math.floor(video.duration / 60);
      let durationSeconds = Math.floor(video.duration - durationMinutes * 60);

      currentTimeElement.innerHTML = `${currentMinutes}:${
        currentSeconds < 10 ? "0" + currentSeconds : currentSeconds
      }`;
      durationTimeElement.innerHTML = `${durationMinutes}:${durationSeconds}`;

      currentTimeElements.innerHTML = `${currentMinutes}:${
        currentSeconds < 10 ? "0" + currentSeconds : currentSeconds
      }`;
      durationTimeElements.innerHTML = `${durationMinutes}:${durationSeconds}`;
    };

    video.addEventListener("timeupdate", currentTime);

    //Progress bar
    video.addEventListener("timeupdate", () => {
      const percentage = (video.currentTime / video.duration) * 100;
      progressBar.style.width = `${percentage}%`;
    });

    //change progress bar on click
    progress.addEventListener("click", (e) => {
      const progressTime = (e.offsetX / progress.offsetWidth) * video.duration;
      video.currentTime = progressTime;
    });
  },
};
</script>
<style scoped>
/* 
Écran de bureau : généralement de 1024 
Écran portable : généralement de 768
Tablette : généralement de 600
Écran mobile : généralement de 320
 */

.videoss {
  display: flex;
  flex-direction: space-between;
}

.bandeauvideo {
  display: none;
}

.playerFullScreens {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: lightgray;
  color: black;
  border: 2px solid #00800069;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  position: absolute;
  margin-bottom: 2em;
  margin-left: -2em;
  bottom: 0;
}

.playerFullScreen {
  padding: 3px 5px 3px 6px;
  border-radius: 20px;
  background-color: lightgray;
  border: 2px solid #00800069;
  -webkit-appearance: button;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px #666;
  box-shadow: 0 0 5px #666;
}

.titleVideo {
  color: black;
  position: absolute;
  margin-left: 5em;
  margin-top: 1.3em;
}

.notification {
  display: flex;
  justify-content: center;
  display: none;
}

@media (max-width: 768px) {
  .bandeauvideo {
    display:none;

  }

  .buttonFullScreen {
    display: none;
  }

  .titleVideo {
    display: none;
  }

  .times {
    margin-left: 4em;
    /* font-size: 1em; */
    color: black;
    /* width: 15%; */
    /* text-align: center; */
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    margin-top: 1.2em;
  }

  .imgVolume {
    width: 3em;
    margin-top: 0.7em;
    margin-left: 10em;
  }

  .notification {
    display: block;
  }

  .buttonFullScreen {
    display: none;
  }

  button.playerFullScreen img {
    /* masque le texte du bouton */
    content: url("../assets/img/expand.png");
  }
}

@media (min-width: 321px) and (max-width: 600px) {
}

@media (min-width: 601px) and (max-width: 768px) {
}

@media (min-width: 769px) and (max-width: 1024px) {
}

/*  */
.video-player {
  position: relative;
}

.video {
  width: 100%;
  max-width: 1000px;
}

.buttonVideo {
  margin-bottom: 100px;
}

.player-controls {
  display: flex;
  bottom: 0;
  width: 100%;
  flex-wrap: wrap;
  background: rgb(72, 145, 129) !important;
  height: 65px;
  border-radius: 6px;
  border: 0.5px solid #00800069;
  margin-bottom: 30px;
}

.video-player:hover .player-controls {
  transform: translateY(0);
}

.imgVolume {
  position: absolute;
  width: 4em;
  margin-top: 0.3em;
  margin-left: 74%;
}

.imgVolumevideo {
  width: 1em;
}

.play-button {
  border: none;
  background: transparent !important;
  color: blacks;
  padding: 10px;
  border-radius: 10px;
  font-size: large;
  position: absolute;
}
.close-button
{
  border: none;
  background: transparent !important;
  color: blacks;
  padding: 10px;
  border-radius: 10px;
  font-size: large;
  position: absolute;
}
.buttonFullScreen {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.play-button:focus {
  outline: none;
}

.time {
  font-size: 1em;
  color: black;
  width: 15%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 85%;
  margin-top: 1.2em;
}

.timess {
  font-size: 1em;
  color: black;
  width: 15%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 85%;
  margin-top: 1.2em;
}

input[type="range"] {
  -webkit-appearance: none;
  background-color: #e5e5e5;
  border: 1px solid #00800069;
  background-image: linear-gradient(#8bf0da, #6aafa0);
  height: 8px;
  border-radius: 5%;
  position: absolute;
  margin-top: 2em;
  margin-left: 79%;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #469281;
  height: 20px;
  width: 10px;
  border-radius: 20px;
  background: #56ae9b;
  cursor: pointer;
  margin-top: 0px;
}

.video-progress {
  display: flex;
  width: 100%;
  transition: 0.3s;
  background: #e5e5e5;
  cursor: pointer;
  border-radius: 6px;
}

.video-progress-filled {
  width: 0;
  background: linear-gradient(#8bf0da, #6aafa0);
  border-radius: 6px;
}

.VideoMiddlePlacement {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
