import Parse from 'parse';

export async function getResultatFromQCM(idQCM,userId) {
    const QueryUser = new Parse.Query(Parse.User);
    QueryUser.equalTo("objectId", userId);
    const user = await QueryUser.first();
    //console.log('User trouvé:',user)
    //console.log('Id du QCM:',idQCM)
    const queryFolder = new Parse.Query("file_done");
    queryFolder.equalTo("user", user);
    queryFolder.equalTo("module", Parse.Object.extend("folder").createWithoutData(idQCM));
    queryFolder.descending("createdAt");
    let result = await queryFolder.first();

    // Si aucun résultat n'est trouvé dans "folder", vérifiez dans "file"
    if (!result) {
        const queryFile = new Parse.Query("file_done");
        queryFile.equalTo("user", user);
        queryFile.equalTo("file", Parse.Object.extend("file").createWithoutData(idQCM));
        queryFile.descending("createdAt");
        result = await queryFile.first();
        //console.log(result);
    }

    if (result) {
        const mappedResult = {
            note: result.get("note"),
            time_elapsed: result.get("time_elapsed"),
            date: result.get("createdAt")
        };
        //console.log(mappedResult);
        return mappedResult;
    }
    return null;
}
