<template>
  <div
    style="
      display: flex;
      justify-content: space-around;
      width: 75%;
      margin: 0% 0% 2% 13%;
    "
    class="is-margin-b-20"
  >
    <b-table :data="listeUser" icon-pack="fas">
      <template slot-scope="props">
        <a
          class="button-mon-profil button is-cyan"
          @click="openSuivi(props.row)"
        >
          Charger le suivie
        </a>
      </template>
    </b-table>
  </div>
</template>

<script>
import Parse from "parse";

export default {
  name: "liste-utilisateurs",
  props: ["usersInMyGroup", "group"],
  data() {
    return {
      userSelected: null,
      isCardRedirectFront: false,
      listeUser: [],
    };
  },
  methods: {
    openSuivi(e) {
      this.$emit("openSuivi", e);
    },
  },
  async mounted() {
    var dt = new Date();
    dt.setDate(dt.getDate());

    this.usersInMyGroup.forEach(async function (user) {
      if (
        user.fin_acces_plateforme != null &&
        user.fin_acces_plateforme.getTime() < dt.getTime()
      ) {
        user.typePratique = "cabinet";
        // this.group.nb_users_licence -= 1;
        Parse.masterKey = "myMasterKey";
        const QueryUser = new Parse.Query(Parse.User);
        QueryUser.equalTo("objectId", user.objectId);
        const ResponseUser = await QueryUser.first();
        ResponseUser.set("fin_acces_plateforme", null);
        ResponseUser.set("date_debut_licence", null);
        ResponseUser.set("cgu", true);
        ResponseUser.set("duree_acces", null);
        ResponseUser.set("type_pratique", "cabinet");
        await ResponseUser.save({}, { useMasterKey: true });
      }
    });
    this.listeUser = this.usersInMyGroup.sort(function (a, b) {
      return b.createdAt.getTime() - a.createdAt.getTime();
    });
  },
};
</script>

<style>
.icon-tableau {
  max-width: 25px;
}
</style>
