import Parse from "parse";
import ParseConfig from "@/api/parse.config";

const findAnswersByQuestionId = async (questionId) => {
  const QueryAnswer = new Parse.Query("question_answer");
  const QueryQuestion = new Parse.Query("question");
  QueryQuestion.equalTo("objectId", questionId);
  QueryAnswer.matchesQuery("question", QueryQuestion);

  const results = await QueryAnswer.find();
  const answers = [];
  for (let i = 0; i < results.length; i++) {
    const answer = results[i];
    answers.push({
      id: answer.id,
      text: answer.get("text"),
      points: answer.get("points"),
    });
  }
  return answers;
};

const findQuestionsByQuestionnaireId = async (questionnaireId) => {
  ParseConfig();
  const QueryQuestion = new Parse.Query("question");
  const QueryQuestionnaire = new Parse.Query("questionnaire");
  QueryQuestionnaire.equalTo("objectId", questionnaireId);
  QueryQuestion.matchesQuery("questionnaire", QueryQuestionnaire);
  QueryQuestion.ascending("number");
  QueryQuestion.include("category");
  const questions = await QueryQuestion.find();

  const questionsWithAnswers = await Promise.all(
    questions.map(async (question) => {
      const answers = await findAnswersByQuestionId(question.id);
      const category = question.get("category");
      return {
        id: question.id,
        name: question.get("text"),
        number: question.get("number"),
        category: category
          ? {
              id: category.id,
              name: category.get("name"),
            }
          : null,
        answers,
      };
    })
  );

  return questionsWithAnswers;
};

export { findQuestionsByQuestionnaireId };
