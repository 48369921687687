<template>
  <div class='ProfilPlacement'>
    <!-- DEBUT INFOPERSO-->
    <button class="home-btn" @click="backToHome()">
      <i class="fa fa-home"></i> Retourner à la page d'activité
      </button>    
      <div class="test">
      <div class="Accordion__panel">
        <div @click="toggleDiv('infosperso'), affinfosperso = !affinfosperso">
          <div v-if="affinfosperso" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between;">
            <div class="test1">
              <i class="fas fa-user" style="margin-right: 0.5rem;"></i>              Informations personnelles
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-user" style="margin-right: 0.5rem;"></i>              Informations personnelles
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDownV2.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="infosperso" ref="targetDiv" class='ProfilPlacement'
        v-bind:style="{ display: this.$store.state.user.cgu  ? 'none' : 'block' }"
        style="margin-left:65px;margin-right: 50px;">
        <div class="column">
          <div class="is-margin-t-20">
            <b-checkbox data-cy="cgu-checkbox" v-model="cguValue" style="font-size: 0.9em;">
              {{ $t("cgu2") }} (<a href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                target="_blank">{{
                  $t("cgu2_cgu") }}</a>,<a href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                target="_blank">
                {{ $t("cgu2_rgpd") }}</a>)<sup style="color:red"><b> *</b></sup>
            </b-checkbox>
            <div class="is-size-6 has-text-danger" v-if="errorCgu && !user.cgu">
              {{ $t("erreurCgu") }}
            </div>
          </div>
        </div>
        <div class="column cgu-alert" v-if="!cguValue">
        <b-message type="is-danger" aria-close-label="Close message">
            <span class="icon-text">
                <span class="icon">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <span>Veuillez cocher les CGU pour continuer sur la plateforme.</span>
            </span>
        </b-message>
    </div>

        <div class="column">
          <div>
            <div style="display: flex;width: 50%;justify-content: space-between;">
              <div><b class="is-padding-l-10">
                  {{ $t("email") }}
                </b></div>
              <div> <a v-if="emailDisable" class="button is-small is-cyan is-outlined" @click="emailDisable = false">
                  {{ $t("modifierMonEmail") }}
                </a></div>
            </div>
            <div>
              <b-input :disabled="emailDisable" name="email" expanded id="copy" v-model="mailUser"
                @focus="doNotCopyPast('copy')"></b-input>
            </div>
            <div v-if="!emailDisable">
              <b class="is-padding-l-10">{{ $t("recopierEmail") }}</b><sup style="color:red"><b> *</b></sup>
            </div>
            <div v-if="!emailDisable">
              <b-field :type="{
                'is-danger':
                  (!mailCopy && error) ||
                  errorSameMail ||
                  errorEmailAlreadyExist,
              }" :message="{
  'Veuillez recopier votre mail professionel':
    !mailCopy && error,
  'Il faut que votre adresse email soit identique à la précédente':
    errorSameMail && error,
  'L\'adresse email existe déjà': errorEmailAlreadyExist,
}">
                <b-input name="recopieremail" expanded id="past" v-model="mailCopy"
                  @focus="doNotCopyPast('past')"></b-input>
              </b-field>
            </div>
            <div v-if="!emailDisable"></div>
          </div>
          <div>
            <div style="display: flex;width: 50%;justify-content: space-between;">
              <div> <b class="is-padding-l-10">{{ $t("motDePasse") }}</b></div>
              <div>
                <a v-if="passwordDisable" class="button is-small is-cyan is-outlined" @click="passwordDisable = false">
                  {{ $t("modifierMotDePasse") }}
                </a>
              </div>
            </div>
            <div>
              <b-input :disabled="passwordDisable" type="password" name="password" expanded id="copy"
                v-model="passwordUser" @focus="doNotCopyPast('copy')"></b-input>
            </div>
            <div v-if="!passwordDisable">
              <b class="is-padding-l-10">
                {{ $t("recopierMotDePasse") }}
              </b><sup style="color:red"><b> *</b></sup>
            </div>
            <div v-if="!passwordDisable">
              <b-field :type="{
                'is-danger':
                  (!passwordCopy && error) || errorSamePassword,
              }" :message="{
  'Veuillez recopier votre mail professionel':
    !passwordCopy && error,
  'Il faut que votre adresse email soit identique à la précédente':
    errorSamePassword && error,
}">
                <b-input name="recopierpassword" type="password" expanded id="past" v-model="passwordCopy"
                  @focus="doNotCopyPast('past')"></b-input>
              </b-field>
            </div>
          </div>



        </div>
        <div class="column ResponsiveButton">
          <a class="button-mon-profil button is-cyan is-margin-r-20" @click="saveCGU(cguValue)">
            {{ $t("mettreAJourInfoPerso") }}
          </a>
        </div>
      </div>

    </div>
    <!-- FIN INFOPERSO  -->
    <!-- DEBUT PREFERENCE -->

    <div class="test">
      <div class="Accordion__panel">
        <div @click="toggleDiv('preference'), affpreference = !affpreference">
          <div v-if="affpreference" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-sliders-h" style="margin-right: 0.5rem;"></i>
              Préférences
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-sliders-h" style="margin-right: 0.5rem;"></i>
              Préférences</div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="preference" ref="targetPref" style="width: 70%;margin: auto;display:none">
        <section>
          <div class="column is-margin-t-20">
  <label class="custom-checkbox">
    <input type="checkbox" v-model="suivi_exo">
    <span class="checkmark"></span>
    {{ $t("suiviePro") }}
  </label>
</div>
<div class="column">
  <label class="custom-checkbox">
    <input type="checkbox" v-model="accesDataSymbiocenter">
    <span class="checkmark"></span>
    {{ $t("suivieSymbiofi") }}
  </label>
</div>
<div class="column">
  <label class="custom-checkbox">
    <input type="checkbox" v-model="newsletter">
    <span class="checkmark"></span>
    {{ $t("newsletter") }}
  </label>
</div>
          <div class="column">
            <div>
              <p>Afin d'activer le cadencement des exercices par notifications et mails,</p>
              <p>Veuillez sélectionner le ou les jours de préférences pour vous informer du nouvel exercice à faire</p>
            </div>
            <div>
              <input type="checkbox" class='checkbox' id="lundi" v-model="newsletter_jour" value="lundi" />&nbsp;
              <label for="lundi">Lundi</label>&nbsp;&nbsp;
              <input type="checkbox" id="mardi" value="mardi" v-model="newsletter_jour" />&nbsp;
              <label for="mardi">Mardi</label>&nbsp;&nbsp;
              <input type="checkbox" id="mercredi" value="mercredi" v-model="newsletter_jour" />&nbsp;
              <label for="mercredi">Mercredi</label>&nbsp;&nbsp;
              <input type="checkbox" id="jeudi" value="jeudi" v-model="newsletter_jour" />&nbsp;
              <label for="jeudi">Jeudi</label>&nbsp;&nbsp;
              <input type="checkbox" id="vendredi" value="vendredi" v-model="newsletter_jour" />&nbsp;
              <label for="vendredi">Vendredi</label>&nbsp;&nbsp;
              <input type="checkbox" id="samedi" value="samedi" v-model="newsletter_jour" />&nbsp;
              <label for="samedi">Samedi</label>&nbsp;&nbsp;
              <input type="checkbox" id="dimanche" value="dimanche" v-model="newsletter_jour" />&nbsp;
              <label for="dimanche">Dimanche</label>
            </div>
          </div>
        </section>
        <div class="column ResponsiveButton">
          <a class="button-mon-profil button is-cyan is-margin-r-20" @click="savePreferenceDetails()">
            {{ $t("mettreAJourPreference") }}
          </a>
        </div>
      </div>
    </div>
    <!-- FIN PREFERENCE  -->
    <!--  DEBUT SUIVI-->
    <div>
      <div class="Accordion__panel">
        <div @click="openSuivi(), affsuivi = !affsuivi">
          <div v-if="affsuivi" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-history" style="margin-right: 0.5rem;"></i>
              Suivi et historique <label style="color:orange;font-size: 0.8em;" v-if="!suivi_exo">( ATTENTION, vous
                n'avez pas activé le partage avec votre
                professionnel référent )</label>
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-history" style="margin-right: 0.5rem;"></i>
              Suivi et historique <label style="color:orange;font-size: 0.8em;" v-if="!suivi_exo">( ATTENTION, vous
                n'avez pas activé le partage avec votre
                professionnel référent )</label>
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="suivi" style="width: 90%;margin: auto;display:none" v-show="affsuivi">
        <div>
          <div>
            <div>
              <div
                  v-if="!displaySuiviData"
                  class="loader-detail-relecture"
                ></div>
              <relectureUser v-else :filesDones="suiviData" :selectedUser="selectedUser"></relectureUser>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN SUIVI -->
    <!-- DEBUT ABONNEMENT -->
    <div class="test">
      <div class="Accordion__panel">
        <div @click="toggleDiv('abonnement'), affabonnement = !affabonnement">
          <div v-if="affabonnement" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-star" style="margin-right: 0.5rem;"></i>
              Abonnements
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-star" style="margin-right: 0.5rem;"></i>
              Abonnements
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="abonnement" class="containerAboInfo" style="width: 70%;margin: auto;display:none;">
        <div class="AboInfo" style="width: 60%;">
          <div class="box has-text-centered box-information-utilisateurs is-margin-t-10"
            v-if="group.type_abonnement_perso === undefined && user.type_pratique === 'licence'">
            <p class="has-text-weight-normal is-size-2 box-titre">
              {{ $t("Licence active") }}
            </p>
            <p>
              <strong>{{ user.duree_acces }}</strong> {{ $t("mois d'accès") }}
            </p>
            <p class="is-margin-b-10">
              {{ "Expiration le :" }}
            </p>
            <p class="button is-cyan buttonRadius is-margin-b-10" v-if="user.fin_acces_plateforme">
              {{ formatDate(user.fin_acces_plateforme) }}
            </p>
          </div>
          <div class="box has-text-centered box-information-utilisateurs is-margin-t-10"
            v-if="group.date_paiement_perso !== undefined && group.date_paiement_perso !== null">
            <p class="has-text-weight-normal is-size-2 box-titre">
              {{ $t("Offre C-Play") }}
            </p>
            <p>
              <span>
                {{ $t("Abonnement") }}
              </span>
              <span v-if="group.engagement_annuel_perso">
                {{ $t("annuel") }}
              </span>
              <span v-else>
                {{ $t("trimestriel") }}
              </span>
            </p>
            <p class="is-margin-b-10">
              {{ "Prochain Prélèvement:" }}
            </p>
            <p class="button is-warning buttonRadius is-margin-b-10" v-if="group.date_paiement_perso">
              {{ formatDateP(group.date_paiement_perso) }}
            </p>
          </div>
          <div class="box has-text-centered box-information-utilisateurs is-margin-t-10"
            v-if="group.date_paiement_perso !== undefined && group.date_paiement_perso !== null">
            <p class="has-text-weight-normal is-size-2 box-titre">
              {{ $t("Offre C-Play") }}
            </p>
            <p>{{ $t("Accéder a l'intégralité de nos protocoles et pratiques libres ") }}</p>
            <p>{{ $t("Entraînements adaptés : débutant,expert et libre") }}</p>
            <p>{{ $t("Accompagnement et suivi par votre professionel") }}</p>
            <p>{{ $t("Personnalisation du cadencement de vos pratiques") }}</p>
            <div class="is-margin-t-10">
              <button class=" button is-cyan" disabled>Souscrire </button>
            </div>
          </div>
        </div>
        <div class="AboInfo" style="width: 60%;margin-left: 10%;">
          <div class="box has-text-centered box-information-utilisateurs is-margin-t-10">
            <p class="has-text-weight-normal is-size-2 box-titre">
              Mon référent
            </p>
            <p>
              <strong>{{ group.name }}</strong>
            </p>
            <p class="button is-cyan buttonRadius is-margin-10" @click="isBienEtre = true;">
              Contactez par mail
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN ABONNEMENT -->
    <!-- DEBUT SYMBIOSENSOR -->
    <div class="test">
      <div class="Accordion__panel">
        <div @click="toggleDiv('symbiosensor'), affSymbiosensor = !affSymbiosensor">
          <div v-if="affSymbiosensor" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-heartbeat" style="margin-right:0.5rem;"></i>

              Symbiosensor
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-heartbeat" style="margin-right:0.5rem;"></i>

              Symbiosensor
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="symbiosensor" style="width: 70%;margin: auto;display:none">
        <div class="is-margin-b-20 is-margin-t-20">
          <p>Grâce à notre capteur, vous pourrez pratiquer l'intégralité de la brique de cohérence cardiaque (sur PC et
            Mac uniquement)</p>
          <p>Vous n'avez pas de capteurs ? Decouvrez nos capteurs compatibles <a
              href="https://symbiocenter.fr/capteurs-de-pouls-2/" target="_blank">ici</a></p>
        </div>
        <div class="containerlogo" style="display:flex;justify-content: space-around;" v-if="!exeSymbiosensor">
          <div class="logo1">
            <a v-bind:class="{ 'disabled': isLinkAvailable }" style="background: transparent" v-bind:href="lienDownload"
              target="_blank"><img src="../../../assets/img/apple_active.png"
                style="width: 100px; height: 100px; margin-left: 20px;" v-if="isapple"  @click="getIOS"/><img
                src="../../../assets/img/apple_inactive.png" style="width: 100px; height: 100px; margin-left: 20px;"
                v-if="!isapple" /></a>
          </div>
          <div class="logo2">
            <a v-bind:class="{ 'disabled': !isLinkAvailable }" style="background: transparent" v-bind:href="lienDownload"
              target="_blank"><img src="../../../assets/img/windows_active.png"
                style="width: 100px; height: 100px; margin-left: 20px;" v-if="isMicrosoft"  @click="getIOS"/><img
                src="../../../assets/img/windows_inactive.png" style="width: 100px; height: 100px; margin-left: 20px;"
                v-if="!isMicrosoft" /></a>
          </div>
        </div>
        <div class="containerlogo" style="display:flex;justify-content: space-around;" v-if="exeSymbiosensor">
          <div class="logo1">
            <a v-bind:class="{ 'disabled': true }" style="background: transparent" v-bind:href="lienDownload"
              target="_blank"><img src="../../../assets/img/apple_inactive.png"
                style="width: 100px; height: 100px; margin-left: 20px;" /></a>
          </div>
          <div class="logo2">
            <a v-bind:class="{ 'disabled': true }" style="background: transparent" v-bind:href="lienDownload"
              target="_blank"><img src="../../../assets/img/windows_inactive.png"
                style="width: 100px; height: 100px; margin-left: 20px;" /></a>
          </div>
          <div class="logo2">
            <a v-bind:href="lienDownload" target="_blank"><img src="../../../assets/img/executable_active.png"
                style="width: 100px; height: 100px; margin-left: 20px;" /></a>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN ABONNEMENT -->
    <!-- DEBUT ACTUALITE -->
    <div class="test">
      <div class="Accordion__panel">
        <div @click="toggleDiv('Actualites'), affActualites = !affActualites">
          <div v-if="affActualites" id="boxExercices"
            style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-newspaper" style='margin-right:0.5rem;'></i>
              Actualités
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
            </div>
          </div>
          <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
            <div class="test1">
              <i class="fas fa-newspaper" style='margin-right:0.5rem;'></i>
              Actualites
            </div>
            <div>
              <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
            </div>
          </div>
        </div>
      </div>
      <div id="Actualites" style="width: 70%;margin: auto;display:none">
        <div class="is-margin-b-20 is-margin-t-20">
          <app-dernieres-actualites :group="group" />
        </div>
      </div>
    </div>
    <!-- FIN ACTUALITE -->
    <!-- DEBUT ESPACE DIALOGUE -->
    <div class="test" v-if="!disableVisio">
  <div class="Accordion__panel">
    <div @click="toggleDiv('contact'), affEspaceDialogue = !affEspaceDialogue">
      <div v-if="affEspaceDialogue" id="boxExercices"
        style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
        <div class="test1">
          <i class="fas fa-video" style="margin-right: 0.5rem;"></i>
          Prise de rendez-vous
        </div>
        <div>
          <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
        </div>
      </div>
      <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
        <div class="test1">
          <i class="fas fa-video" style="margin-right: 0.5rem;"></i>
          Prise de rendez-vous
        </div>
        <div>
          <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
        </div>
      </div>
    </div>
  </div>
  <div id="contact" class="containerDialogueRdv" style="display:none">
    <meetingComponent/>
  </div>
</div>
<!-- FIN ESPACE DIALOGUE -->

<!-- ESPACE CHAT -->
<div class="test">
  <div class="Accordion__panel">
    <div @click=" affEspaceChat = !affEspaceChat, diplayPopUpContact = !diplayPopUpContact">
      <div v-if="affEspaceChat" id="boxExercices"
        style="margin-bottom:0% !important;background-color: #489181 !important;color:white;display:flex;justify-content: space-between">
        <div class="test1">
          <i class="fas fa-comments" style="margin-right: 0.5rem;"></i>
          Espace de dialogue
        </div>
        <div>
          <img class="arrowDown" src="../../../assets/img/ChevronUpBlancV2.png" />
        </div>
      </div>
      <div v-else id="boxExercices" style="margin-bottom:0% !important;display:flex;justify-content: space-between">
        <div class="test1">
          <i class="fas fa-comments" style="margin-right: 0.5rem;"></i>
          Espace de dialogue
        </div>
        <div>
          <img class="arrowDown" src="../../../assets/img/ChevronDown.png" />
        </div>
      </div>
    </div>
  </div>
  <div id="chatContact" class="containerChat">
    <popUpContact @close="handleClosePopUpContact" v-if="diplayPopUpContact"/>
  </div>
</div>
<!-- FIN ESPACE CHAT -->
    <b-modal :width="1200" :active.sync="isBienEtre" @close="
      isBienEtre = false;">
      <div>
        <div>
          <div class="card editUser">
            <div class="card-content">
              <div class="content">
                <div class="overflow">
                  <h3 class="title has-text-black is-size-5">
                    {{ $t("Contacter mon référent professionnel") }}
                  </h3>
                  <div>
                    <p> Vous avez-une question sur votre pratique, vous souhaitez partager avec votre professionnel
                      référent ?</p>
                    <b-field>
                      <b-input v-model="mailBienEtre" maxlength="2000" type="textarea"></b-input>
                    </b-field>
                  </div>
                  <div>
                    <div class="is-pulled-left">
                      <a class="button is-cyan" @click="sendMailBienEtre()">
                        {{
                          $t("envoyez")
                        }}
                      </a>
                    </div>
                    <div class="is-pulled-right">
                      <a class="is-pulled-right button is-grey is-margin-r-10" @click="isBienEtre = false">
                        {{ $t("annuler") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { createNamespacedHelpers } from "vuex";
import * as types from "@/store/user-mutation-type";
import suiviUtilisateurComponent from "./utilisateur/suiviProfil.vue";
import listeUtilisateursComponent from "./utilisateur/utilisateurProfil.vue";
import DernieresActualites from "./newsProfil.vue";
import { getObjectById } from "@/services/parse/getObject.js";
const User = createNamespacedHelpers("user");
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import { getUsersByGroupIdProfil } from "@/services/utilisateurs/getUsersByGroupIdProfil.js";
import asyncForEach from "@/services/general/asyncForEach.js";
import CguRequired from "@/cguRequired";
import store from "@/store/store";
import relectureUser from "./relecture/relectureUser.vue";
import { getSuiviForUser } from "@/services/utilisateurs/getSuiviForUser.js";
import meetingComponent from "./visio/meetingComponent.vue";
import popUpContact from "./contact/contactPopUp.vue";
import { getUserFromTherapeute } from "@/services/chat/getUserFromTherapeute.js";

export default {
  name: "foldersProfil",
  props: ["userCNI"],
  components: {
    ListeUtilisateursComponent: listeUtilisateursComponent,
    SuiviUtilisateurComponent: suiviUtilisateurComponent,
    appDernieresActualites: DernieresActualites,
    relectureUser: relectureUser,
    meetingComponent,
    popUpContact,
  },
  data() {
    return {
      isBienEtre: false,
      therapeute: null,
      mailBienEtre: "",
      UserSelectedGroup: null,
      usersInMyGroup: [],
      isCardAddUserOpen: false,
      error: false,
      isCardSuiviOpen: false,
      loadDone: false,
      isapple: false,
      isMicrosoft: false,
      emailDisable: true,
      passwordDisable: true,
      mailCopy: null,
      mailUser: null,
      passwordCopy: null,
      passwordUser: null,
      errorSamePassword: false,
      errorSameMail: false,
      userCopy: null,
      groupCopy: null,
      errorCgu: false,
      newsletters: false,
      accesDataSymbiocenter: false,
      suivi_exo: false,
      suiviePro: false,
      newsletter_jour: [],
      newsletter: false,
      error: false,
      errorEmailFormat: false,
      errorEmailUse: false,
      errorMajuscule: false,
      errorSamePassword: false,
      mailInitial: null,
      mailUser: null,
      checked: false,
      affinfosperso: false,
      affpreference: false,
      affsuivi: false,
      affabonnement: false,
      affSymbiosensor: false,
      affActualites: false,
      affEspaceDialogue: false,
      affEspaceChat: false,
      isLinkAvailable: false,
      mail: "",
      User: null,
      utilisateurs: [],
      utilisateursAffilie: [],
      nbUsersInMyGroupWithActiveAccess: 0,
      filesDone: [],
      loadProtocoles: false,
      questionnaire: false,
      coherence: false,
      UserSelected: null,
      sessionsGroup: null,
      exeSymbiosensor: "",
      os: null,
      lienDownload: "",
      utilisateursSessionGroup: null,
      groupSelected: "tous",
      affiliation_en_attente: 0,
      loadChartConnection: false,
      loadFilesDone: false,
      ouvertureSuiviCcUtilisateur: "",
      refresh: false,
      indefinteToast: null,
      users: null,
      IUserSelected: "",
      fileCCId: "",
      message: '',
      mailvalue: this.userCNI.username,
      useronline: "",
      openDivId: null,
      userSelectedToProfil: null,
      selectedUser: null,
      displaySuiviData: false,
      suiviData:[],
      userEnCours: null,
      cguValue: false,
      disableVisio: false,
      diplayPopUpContact: false,
    };
  },
  computed: {
    ...User.mapGetters(["group", "abonnement", "user", "folders"]),
    currentUserRecup: {
      get() {
        return Parse.User.current();
      },
      isCguAccepted() {
      return Parse.User.current().attributes.cgu;
      },
    }
  },
  methods: {
    ...User.mapActions({
      actionsLoadFolder: "loadFolder",
      actionsUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
      actionsUpdateUser: "updateUser",
      actionsLogIn: "logIn",
      actionsCgu: "updateCgu",
    }),
    handleClosePopUpContact() {
      this.affEspaceChat = false;
      this.diplayPopUpContact = false;
    },
    formatDate(date) {
      return moment(date.iso).format('DD-MM-YYYY');
    },
    formatDateP(date) {
      if (this.group.engagement_annuel_perso) {
        var newDate = moment(date.iso);
        newDate.add(1, 'years');
        return newDate.format('DD-MM-YYYY');
      } else {
        var newDate = moment(date.iso);
        newDate.add(3, 'months');
        return newDate.format('DD-MM-YYYY');

      }
    },
    async getIOS() {
      ParseConfig();
      this.useronline = await Parse.User.current().id;
      var querytest = new Parse.Query("_User");
      querytest.equalTo("objectId", this.useronline);
      var resultattest = await querytest.first();
      if (this.os === 'Macosx') {
        resultattest.set("isApple", true);
        Parse.masterKey = "myMasterKey";
        await resultattest.save({}, { useMasterKey: true });
      }
      else {
        resultattest.set("isWindows", true);
        Parse.masterKey = "myMasterKey";
        await resultattest.save({}, { useMasterKey: true });
        
      }
    },
    backToHome()
    {
      window.location.reload();
    },
    async getMyUsers() {
      this.loadDone = false;
      this.usersInMyGroup = [];
      const props = await getUsersByGroupIdProfil(this.group);
      var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryGroup.equalTo("objectId", this.group.objectId);
      var ResponseGroup = await QueryGroup.first();
      this.group.nb_users_licence = props.nb_users_licence;
      this.usersInMyGroup = props.usersInMyGroup;
      const ObjectSession = Parse.Object.extend("session_detail");
      var QuerySessionGroup = new Parse.Query(
        Parse.Object.extend("session_group")
      );
      QuerySessionGroup.equalTo("user_group", ResponseGroup);
      var ResponseSessionGroup = await QuerySessionGroup.find();

      await asyncForEach(ResponseSessionGroup, async (seance_group) => {
        if (seance_group.attributes.date_end >= new Date()) {
          var RelationSeanceGroup = seance_group.relation("users");
          let QueryUsersFromRelation = RelationSeanceGroup.query();
          QueryUsersFromRelation.limit(1000);
          var ResponseUsersFromRelation = await QueryUsersFromRelation.find();
          await ResponseUsersFromRelation.forEach((userFromRelation) => {
            var user = this.usersInMyGroup.find((e) => {
              return e.objectId === userFromRelation.id;
            });
            if (user) {
              user.seance_group = {
                name: seance_group.attributes.name,
                date: seance_group.attributes.date_start,
              };
            }
          });
        }
      });
      this.loadDone = true;
    },
    async sendMailBienEtre() {
      await Parse.Cloud.run("sendMailContactTherapeutique", {
        destinataire: this.therapeute.attributes.username,
        pro:
          this.user.group.name,
        expediteur: this.user.username,
        messageEcrit: this.mailBienEtre,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Mail << question bien être >> envoyé avec succès.",
        type: "is-success",
      });
      this.isBienEtre = false;
    },
    async saveCGU(cgu) {
  try {
    var user = Parse.User.current();
    user.set("cgu", cgu);
    await user.save();
    
    // this.$emit('displayProfil');
    this.$buefy.toast.open({
      duration: 5000,
      message: "Les CGU ont été mises à jour avec succès.",
      type: "is-success",
    });
    let targetDiv = this.$refs.targetPref;
      let divId = targetDiv.id;
      this.toggleDiv(divId);
    if(!this.affpreference)
    {
      this.affpreference = true;
    }
    this.$emit('displayHeader');

  } catch (error) {
    console.error("Erreur lors de la sauvegarde des CGU:", error);
    this.$buefy.toast.open({
      duration: 5000,
      message: "Une erreur est survenue lors de la mise à jour des CGU.",
      type: "is-danger",
    });
  }
},
    async openSuivi() {
      var User = Parse.User.current();
      this.selectedUser = null;
  this.displaySuiviData = false;
  this.suiviData = null;
  try {
    // Charger les données de suivi
    this.suiviData = await getSuiviForUser(User.id);
    this.selectedUser = User;
    this.displaySuiviData = true;
  } catch (error) {
    // Gérer les erreurs de chargement des données
    console.error("Erreur lors du chargement des données de suivi:", error);
  }

    },
    toggleDiv(id) {
      this.openDivId = this.openDivId === id ? null : id;
      let divs = document.getElementById(id);
      let div = null;
      switch (this.openDivId) {
        case "infosperso":
          div = "infosperso";
          break;
        case "preference":
          div = "preference";
          break;
        case "suivi":
          div = "suivi";
          break;
        case "abonnement":
          div = "abonnement";
          break;
        case "symbiosensor":
          div = "symbiosensor";
          break;
        case "Actualites":
          div = "Actualites";
          break;
        case "contact":
          div = "contact";
          break;
        case "chatContact":
          div = "chatContact";
          break;
        default:
          div = "null";
          break;
      }
      if (div === "abonnement") {
        if (div === this.openDivId) {
          if (divs.style.display === "flex")
            divs.style.display = "none";
          else
            divs.style.display = "flex";
        }
        else {
          divs.style.display = "none";
        }
      } else {
        if (div === this.openDivId) {
          if (divs.style.display === "block")
            divs.style.display = "none";
          else
            divs.style.display = "block";
        }
        else {
          divs.style.display = "none";
        }
      }
      // if (div === this.openDivId && div == "abonnement") {
      //   if (divs.style.display === "flex")
      //     divs.style.display = "none";
      //   else
      //     divs.style.display = "flex";
      // }
      // else {
      //   divs.style.display = "none";
      // }
     
    },
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    getOS() {
      var platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod']
      if (macosPlatforms.indexOf(platform) !== -1) {
        this.os = 'Macosx';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        this.os = 'Ios';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        this.os = 'Windows';
      }
      return this.os;

    },
    goToResultatcoherence(row) {
      this.$refs.table.toggleDetails(row);
      this.questionnaire = false;
      this.coherence = true;
    },
    async savePreference() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      this.errorCgu = false;
      ParseConfig();
      
      if (!this.user.cgu) {
        this.errorCgu = true;
        let targetDiv = this.$refs.targetDiv;
        let divId = targetDiv.id;
        this.toggleDiv(divId);
        if (!this.affinfosperso) {
          this.affinfosperso = true;
        } else {
          this.affinfosperso = false;
        }

        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Vous devez confirmer les conditions générales d'utilisation afin d'utiliser la plateforme.",
          type: "is-danger",
        });
        return;
      }
    

      try
      {
        const result = await Parse.Cloud.run("savePreference", {
          userId: this.userCopy.objectId,
          suivi_exo: this.suivi_exo,
          acces_donnees_therapeute: this.suivi_exo,
          acces_donnees_symbiocenter: this.accesDataSymbiocenter,
          newsletters: this.newsletter,
          newsletter_jour: this.newsletter_jour,
        },{
            success: function (result) {
              //console.log("Sauvegarde effectué !");
            },
            error: function (error) {
              console.error(
                "Une erreur s'est produite lors de la sauvegarde :",
                error
              );
            },
          }
        );
        Toast.fire({
          icon: "success",
          title: "Vos préférences ont été sauvegardée avec succès !",
        });
      }
      catch(error)
      {
        Toast.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la sauvegarde de vos préférences.",
        });
        console.error("Erreur lors de la mise à jour des préférences:", error);
      }
      // await this.actionsUpdateUser();
      this.refresh = true;
    },
    async savePreferenceDetails() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      console.log("savePreferenceDetails");
      console.log(this.userCopy);
      console.log(this.user);
      console.log(this.suivi_exo);
      console.log(this.accesDataSymbiocenter);
      console.log(this.newsletter);
      console.log(this.newsletter_jour);

      try {
  console.log('Test1111')
  const result = await Parse.Cloud.run("savePreference", {
    userId: this.userCopy.objectId,
    suivi_exo: this.suivi_exo,
    accesDataSymbiocenter: this.accesDataSymbiocenter,
    newsletters: this.newsletter,
    newsletter_jour: this.newsletter_jour,
  });

  console.log("Sauvegarde effectuée avec succès :", result);

  Toast.fire({
    icon: "success",
    title: "Vos préférences ont été sauvegardées avec succès !",
  });
} catch (error) {
  Toast.fire({
    icon: "error",
    title: "Une erreur est survenue lors de la sauvegarde de vos préférences.",
  });
  console.error("Erreur lors de la mise à jour des préférences:", error);
}
},

    async save() {
      this.errorEmailAlreadyExist = false;
      this.errorCgu = false;
      ParseConfig();
      if (!this.user.cgu) {
        this.errorCgu = true;
        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Vous devez confirmer les conditions générales d'utilisation afin d'utiliser la plateforme.",
          type: "is-danger",
        });
        return;
      }
      if (!this.emailDisable && this.errorSameMail) {
        this.error = true;
        return;
      }
      if (!this.passwordDisable && this.errorSamePassword) {
        this.error = true;
        return;
      }
      const ResponseUser = await getObjectById("User", this.userCopy.objectId);
      const ResponseGroup = await getObjectById(
        "user_group",
        this.groupCopy.objectId
      );

      if (!this.emailDisable) {
        const UserQuery = new Parse.Query(Parse.User);
        UserQuery.equalTo("username", this.userCopy.email);
        const userAlreadyExist = await UserQuery.first();
        if (userAlreadyExist) {
          this.errorEmailAlreadyExist = true;
          return;
        }
        ResponseUser.set("username", this.userCopy.email);
        ResponseUser.set("email", this.userCopy.email);
      }

      ResponseUser.set("cgu", this.user.cgu);
      this.actionsCgu();


      Parse.masterKey = "myMasterKey";
      await ResponseUser.save({}, { useMasterKey: true });
      await this.actionsUpdateUser();
      await ResponseGroup.save();
      await this.actionsUpdateUserGroup();
      if (!this.passwordDisable) {
        const currentUser = await getObjectById("User", this.userCopy.objectId);
        currentUser.setPassword(this.userCopy.password);
        await currentUser.save();
        var userCourant = await Parse.User.current();
        Parse.Cloud.run("passwordTherapeute", {
          destinataire: this.userCopy.email,
          utilisateur:
            userCourant.attributes.firstname +
            " " +
            userCourant.attributes.lastname,
          password: this.userCopy.password,
        });
        await Parse.User.logOut();
        
        await Parse.User.logIn(this.userCopy.email, this.userCopy.password);
      }
      this.$buefy.toast.open({
        duration: 5000,
        message:
          "Mis à jour de vos données personnelles effectuée avec succès.",
        type: "is-success",
      });
      let targetDiv = this.$refs.targetPref;
      let divId = targetDiv.id;
      this.toggleDiv(divId);
      if (!this.affpreference) {
        this.affpreference = true;
      } else {
        this.affpreference = false;
      }
     
      this.$emit('displayProfil');
      // this.refresh = true;
      // toggleDiv('preferences');
    },
    async getMyUser() {
      ParseConfig();
      var tmpUtilisateur = [];
      this.loadChartConnection = false;
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;

      const UsersFromGroupQuery = new Parse.Query(Parse.User);
      UsersFromGroupQuery.equalTo("group", UserGroupTemporary);
      UsersFromGroupQuery.limit(10000);
      const UsersInMyGroup = await UsersFromGroupQuery.find();

      const UsersAffiliesQuery = new Parse.Query(Parse.User);
      UsersAffiliesQuery.equalTo("affiliation", Parse.User.current());
      UsersAffiliesQuery.limit(10000);
      const UserAffiliated = await UsersAffiliesQuery.find();
      await UsersInMyGroup.forEach(user => {
        if (
          moment().toDate() <
          moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
        tmpUtilisateur.push(user);
      });
      await UserAffiliated.forEach(user => {
        var tmpUser = tmpUtilisateur.filter(usr => {
          return usr.id == user.id;
        });
        if (tmpUser.length == 0) tmpUtilisateur.push(user);
      });
      for (var i = 0; i < tmpUtilisateur.length; i++) {
        if (tmpUtilisateur[i].id == this.UserSelected.objectId) {
          return this.utilisateurs = tmpUtilisateur[i];
        }

      }
      this.loadChartConnection = true;
    },
    async editUser() {
      if (
        !this.UserSelected.mail ||
        this.errorSamePassword
      ) {
        this.error = true;
        return;
      }
      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.UserSelected.mail;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-unused-vars
        m.forEach((match, groupIndex) => {
          this.errorMajuscule = true;
        });
      }
      if (this.errorMajuscule) return;
      this.linksToAdd = await linkToAddToUser(this.foldersBrique);
      this.error = false;
      this.errorEmailFormat = false;
      this.errorEmailUse = false;
      const queryUser = new Parse.Query(Parse.User);
      queryUser.equalTo("objectId", this.UserSelected.objectId);
      const userResponse = await queryUser.first();
      if (!userResponse) {
        this.error = true;
        return;
      }
      const query = new Parse.Query(Parse.User);
      query.equalTo("username", this.UserSelected.mail);
      const userResponseTemp = await query.first();
      if (
        userResponseTemp &&
        userResponse &&
        userResponse.attributes.email !== userResponseTemp.attributes.email
      ) {
        this.errorEmailUse = true;
        return;
      }
      try {
        this.loaderFolderActive = false;
        if (this.mailInitial !== this.UserSelected.mail) {
          var userCourant = await Parse.User.current();
          await Parse.Cloud.run("modificationEmailUtilisateur", {
            destinataire: this.mailInitial,
            utilisateur: this.UserSelected.nom + " " + this.UserSelected.prenom,
            therapeute:
              userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname,
            nouveau: this.UserSelected.mail,
          });
        }
      } catch {
        this.errorEmailFormat = true;
        this.loaderFolderActive = false;
        return;
      }
      this.loaderFolderActive = false;
      this.$buefy.toast.open({
        duration: 3000,
        message: "Utilisateur modifié avec succès.",
        type: "is-success",
      });
      this.$emit("editUser");
    },
  },
  async created() {
    this.userEnCours = Parse.User.current();
    console.log('Valeur de userEnCours :',this.userEnCours)
    if(this.userEnCours.attributes.cgu)
    {
      this.cguValue=true;
    }
    else
    {
      this.cguValue=false;
    }
    if(this.userEnCours.attributes.suivi_exercice)
    {
      this.suivi_exo=true;
    }
    else
    {
      this.suivi_exo=false;
    }
    if(this.userEnCours.attributes.acces_donnees_symbiocenter)
    {
      this.accesDataSymbiocenter=true;
    }
    else
    {
      this.accesDataSymbiocenter=false;
    }
    if(this.userEnCours.attributes.newsletters)
    {
      this.newsletter=true;
    }
    else
    {
      this.newsletter=false;
    }
    if(this.userEnCours.attributes.newsletter_jour)
    {
      this.newsletter_jour=this.userEnCours.attributes.newsletter_jour;
    }
    else
    {
      this.newsletter_jour=[];
    }
    try{
    var currentUser = Parse.User.current();
    var result = await getUserFromTherapeute(
        currentUser.attributes.group,
        false
      );
      //console.log('Résultat obtenu :',result)
      if(result[0].attributes.meeting_availabilities)
      {
        this.disableVisio=false;
      }
      else
      {
        this.disableVisio=true;
      }
      //console.log('Valeur de disableVisio :',this.disableVisio)
    }catch(error){
      console.error('Erreur lors de la récupération des utilisateurs affiliés :',error)
    }
  },
  async mounted() {
    this.users = Object.assign({}, this.user);
    this.users.filesDone = await getFilesDoneByUserId(this.users.objectId);
    if (this.users.filesDone.length > 0) {
      this.isCardSuiviOpen = true;
    }

    if (!CguRequired(store)) {
      if (!this.affinfosperso) {
        this.affinfosperso = true;
      }
    }

    ParseConfig();


    const currentUsers = Parse.User.current();
    const queryUser = new Parse.Query(Parse.User);
    const queryGroup = new Parse.Query("user_group");
    queryGroup.equalTo("objectId", currentUsers.attributes.group.id);
    queryUser.matchesQuery("group", queryGroup);
    queryUser.equalTo("therapeute", true);
    this.therapeute = await queryUser.first();


    this.getOS();
    this.openSuivi(this.userCNI);
    this.useronline = await Parse.User.current().id;
    var querytest = new Parse.Query("_User");
    querytest.equalTo("objectId", this.useronline);
    var resultattest = await querytest.find();

    var QueryGroup = Parse.Object.extend("user_group");
    var QueryGroups = new Parse.Query(QueryGroup);
    QueryGroups.equalTo("objectId", this.user.group.objectId);
    this.UserSelectedGroup = await QueryGroups.first();

    this.exeSymbiosensor = resultattest[0].attributes.exeSymbiosensor;

    var query = new Parse.Query("app_desktop");
    var resultat = await query.find();
    if (this.exeSymbiosensor == false || this.exeSymbiosensor == undefined) {
      this.executable = false;
      if (this.os == 'Macosx') {
        this.lienDownload = resultat[1].attributes.chemin;
        this.isLinkAvailable = false;
        this.isapple = true;
        this.isMicrosoft = false;
      }
      else {
        this.lienDownload = resultat[0].attributes.chemin;
        this.isLinkAvailable = true;
        this.isapple = false;
        this.isMicrosoft = true;
      }
    } else {
      this.lienDownload = "https://www.symbiocenter.com/assets/symbiosensor/Symbiofi-Installeur-3.2.exe";
    }

    this.userCopy = JSON.parse(JSON.stringify(this.user));
    this.groupCopy = JSON.parse(JSON.stringify(this.group));
    // await this.getMyUsers();
    this.mailUser = this.userCopy.email;

    // await this.getMyUser();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        window.location.reload(); // refresh page
      }
    },
    mailCopy() {
      this.errorSameMail = false;
      if (this.mailCopy !== this.userCopy.email) {
        this.errorSameMail = true;
      }
    },
    mailUser() {
      this.userCopy.email = this.mailUser;
      this.errorSameMail = false;
      if (this.mailCopy !== this.userCopy.email) {
        this.errorSameMail = true;
      }
    },
    passwordCopy() {
      this.errorSamePassword = false;
      if (this.passwordCopy !== this.userCopy.password) {
        this.errorSamePassword = true;
      }
    },
    passwordUser() {
      this.userCopy.password = this.passwordUser;
      this.errorSamePassword = false;
      if (this.passwordCopy !== this.userCopy.password) {
        this.errorSamePassword = true;
      }
    },
  },
}
</script>
<style scoped lang="scss">
.home-btn {
  display: flex;
  align-items: center;
  background-color: #489181; // Couleur du thème
  color: #ffffff; // Texte blanc
  padding: 10px 20px;
  border: none;
  border-radius: 30px; // Coins arrondis pour un look moderne
  cursor: pointer;
  transition: all 0.3s; // Transition douce pour tous les effets
  font-weight: 600; // Texte en gras pour le rendre plus visible
  font-size: 16px;
  box-shadow: 0 4px 15px rgba(72, 145, 129, 0.2); // Ombre douce pour la profondeur

  &:hover {
    background-color: darken(#489181, 10%); // Assombrir légèrement au survol
    transform: translateY(-2px); // Effet de "soulèvement" au survol
  }

  i.fa {
    margin-right: 10px; // Espacement entre l'icône et le texte
    font-size: 20px; // Taille de l'icône
  }
}</style>
<style scoped>
.containerAboInfo{
  display: flex; /* Affichage en mode flexbox */
  justify-content: space-between; /* On répartit les éléments horizontalement */
}
.AboInfo{
  width: 45%; /* Largeur des div enfants */
  height: 200px; /* Hauteur des div enfants */

}
/* Style pour les étiquettes de checkbox personnalisées */
.custom-checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

/* Style pour la case à cocher cachée */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Style pour le marqueur de la case à cocher personnalisée */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* Style pour le marqueur de la case à cocher lorsque la case est cochée */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
  border: none;
}

/* Style pour le symbole (coche) de la case à cocher personnalisée */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style pour le symbole (coche) de la case à cocher lorsque la case est cochée */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style pour le symbole (coche) */
.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.containerlogo {
  display: flex;
  justify-content: space-around;
}

.buttonDemarrer {
float: right;
text-decoration: none;
text-transform: none;
padding: 10px;
min-height: 40px;
border-radius: 20px;
background-color: lightgray;
border: 0 none;
outline: 0 none;
-webkit-appearance: button;
cursor: pointer;
}

.buttonChoix {
text-decoration: none;
text-transform: none;
padding: 10px;
min-height: 40px;
border-radius: 20px;
background-color: lightgray;
border: 0 none;
outline: 0 none;
-webkit-appearance: button;
cursor: pointer;
margin: 1%;
}

.arrowDown_Profil {
float: right;
width: 40px;
position: relative;
}

#buttonCadence {
text-decoration: none;
text-transform: none;
padding: 10px;
min-height: 40px;
border-radius: 20px;
background-color: #D3D3D3;
border: 0 none;
outline: 0 none;
-webkit-appearance: button;
cursor: pointer;
margin: 1%;
color: white;
}
.active {
background-color: rgb(35, 147, 128);
color: white;
}

input[type="radio"] {
accent-color: rgb(72, 145, 129) !important;
-ms-transform: scale(1.5);
/* IE 9 */
-webkit-transform: scale(1.5);
/* Chrome, Safari, Opera */
transform: scale(1.5);
margin: 1%;
margin-left: 8%;
}

@media screen and (max-width: 768px) {
.ProfilPlacement{
  width: 100%;
  margin: auto;
}
}
@media screen and (max-width: 768px) {
.ProfilPlacement {
  width: 70%;
  margin: auto;
}
}
@media screen and (min-width: 768px) {
.ResponsiveButton {
  display: flex; justify-content: space-around;flex-direction: column;
}
}
@media screen and (min-width: 768px) {
.ResponsiveButton {
  display: flex; justify-content: space-around;
}
}

div:not(.open) {
  display: none;
}
.cgu-alert {
    margin-top: 20px;
}
</ style>
