var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_c('i',{staticClass:"fas fa-bell icon-valide",class:{ 'has-notifications': _vm.notifications.length !== 0 }})]),_c('div',{staticClass:"navbar-dropdown is-right"},[(_vm.notifications.length === 0)?_c('div',{staticClass:"navbar-item"},[_vm._v(" Aucune notification ")]):_vm._e(),_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id,staticClass:"navbar-item"},[(
          (_vm.user.therapeute && notification.attributes.questionnaireEvent) ||
          notification.attributes.meeting_event
        )?_c('b-message',{staticStyle:{"width":"100%"},attrs:{"type":"is-info"}},[(
            notification.attributes.meeting_event &&
            notification.attributes.meeting_event.attributes.is_accepted !==
              undefined
          )?_c('div',{staticClass:"notification-status",staticStyle:{"margin-right":"15px"}},[_c('i',{staticClass:"fas",class:{
              'fa-check-circle':
                notification.attributes.meeting_event.attributes.is_accepted,
              'fa-times-circle':
                !notification.attributes.meeting_event.attributes.is_accepted,
              'icon-accepted':
                notification.attributes.meeting_event.attributes.is_accepted,
              'icon-declined':
                notification.attributes.meeting_event.attributes
                  .is_accepted === false,
            }})]):(
            notification.attributes.questionnaireEvent && _vm.user.therapeute
          )?_c('div',{staticClass:"notification-status",staticStyle:{"margin-right":"15px"}},[_c('i',{staticClass:"fas fa-exclamation-triangle exercise-type-icon icon-red"})]):_vm._e(),(notification.attributes.type === 'Visio-conférence')?_c('div',{staticClass:"notification-content"},[_c('div',{staticClass:"notification-content"},[_c('i',{staticClass:"fas fa-video fa-2x"})]),(_vm.user.therapeute)?_c('div',{staticClass:"notification-content"},[_c('strong',[_vm._v(_vm._s(notification.attributes.type + " avec " + notification.attributes.user.attributes.lastname + " " + notification.attributes.user.attributes.firstname))]),_c('p',[_vm._v(" "+_vm._s(notification.attributes.description + " avec vous le " + _vm.formatDate( notification.attributes.meeting_event.attributes .date_meeting ))+" ")])]):_c('div',{staticClass:"notification-content"},[_c('strong',[_vm._v(_vm._s(notification.attributes.type + " avec " + notification.attributes.user_pro.attributes.lastname + " " + notification.attributes.user_pro.attributes.firstname))]),_c('p',[_vm._v(" "+_vm._s(notification.attributes.description + " avec vous le " + _vm.formatDate( notification.attributes.meeting_event.attributes .date_meeting ))+" ")])]),(
              notification.attributes.meeting_event.attributes.is_accepted ===
                undefined && _vm.user.therapeute
            )?_c('div',{staticClass:"notification-action"},[_c('div',{staticClass:"notification-action-div"},[_c('b-button',{staticClass:"notification-action-button",attrs:{"type":"is-success","icon-left":"check"},on:{"click":function($event){return _vm.acceptMeeting(notification)}}},[_vm._v(" Accepter ")]),_c('b-button',{staticClass:"notification-action-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.declineMeeting(notification)}}},[_vm._v(" X Refuser ")])],1)]):_vm._e(),(!_vm.user.therapeute)?_c('div',{staticClass:"notification-action"},[(
                notification.attributes.meeting_event.attributes
                  .is_accepted === undefined
              )?_c('p',{staticClass:"notification-text"},[_vm._v(" Votre rendez-vous est en attente. ")]):(
                notification.attributes.meeting_event.attributes.is_accepted
              )?_c('p',{staticClass:"notification-text-green"},[_vm._v(" Votre rendez-vous a été validé par votre professionnel. ")]):_c('p',{staticClass:"notification-text-red"},[_vm._v(" Votre rendez-vous a été refusé par votre professionnel. ")])]):_c('div',[(
                notification.attributes.meeting_event.attributes
                  .is_accepted === undefined
              )?_c('p'):(
                notification.attributes.meeting_event.attributes.is_accepted
              )?_c('p',{staticClass:"notification-text-green"},[_vm._v(" Vous avez validé le rendez-vous. ")]):_c('p',{staticClass:"notification-text-red"},[_vm._v(" Vous avez refusé le rendez-vous. ")])])]):_vm._e(),(
            notification.attributes.type === 'Questionnaire' &&
            _vm.user.therapeute
          )?_c('div',{staticClass:"notification-content"},[_c('div',{staticClass:"notification-content"},[_c('i',{staticClass:"fas fa-clipboard fa-2x exercise-type-icon"})]),(_vm.user.therapeute)?_c('div',{staticClass:"notification-content"},[_c('strong',[_vm._v(_vm._s(notification.attributes.type + " fait par " + notification.attributes.user.attributes.lastname + " " + notification.attributes.user.attributes.firstname))]),_c('div',{staticClass:"DivDescription"},[_c('p',{staticClass:"TexteDescription"},[_vm._v(" "+_vm._s(notification.attributes.description)+" ")])])]):_vm._e()]):_vm._e()]):_vm._e()],1)}),(_vm.notifications.length !== 0)?_c('div',{staticClass:"placementButtonNews"},[_c('b-button',{staticClass:"buttonNews",on:{"click":function($event){return _vm.afficheBloc('profil')}}},[_vm._v("Plus d'actualités")])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }