<template>
  <b-modal :width="1200" :active.sync="isTechnique" @close="closeModal">
    <div class="card" v-if="userSelected">
      <div class="card editUser">
        <div class="card-content">
          <div class="content">
            <div class="overflow">
              <h3 class="title has-text-black is-size-5">
                {{ $t("Question support technique") }}
              </h3>
              <div>
                <p>
                  Vous avez une question sur les fonctionnalités de la
                  plateforme ?
                </p>
                <b-field>
                  <b-input
                    v-model="mailTechnique"
                    maxlength="2000"
                    type="textarea"
                  ></b-input>
                </b-field>
              </div>
              <div>
                <div class="is-pulled-left">
                  <a class="button is-cyan" @click="sendMailTechnique()">
                    {{ $t("envoyer") }}
                  </a>
                </div>
                <div class="is-pulled-right">
                  <a
                    class="is-pulled-right button is-grey is-margin-r-10"
                    @click="isTechnique = false"
                  >
                    {{ $t("annuler") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");

export default {
  data() {
    return {
      isTechnique: true,
      userSelected: null,
      mailTechnique: "",
    };
  },
  computed: {
    ...User.mapGetters(["user", "group"]),
  },
  methods: {
    closeModal() {
      this.isTechnique = false;
      this.userSelected = null;
    },
    sendMailTechnique() {
      // Your logic to send the email goes here
      //console.log("Mail sent:", this.mailTechnique);
    },
    async sendMailTechnique() {
      Parse.Cloud.run("sendMailContactTechnique", {
        destinataire: "contact@symbiocenter.com",
        messageEcrit: this.mailTechnique,
        expediteur: this.user.username,
      })
        .then((response) => {
          //console.log("E-mail envoyé avec succès.", response);
          this.$buefy.toast.open({
            duration: 5000,
            message: "Votre message a été envoyé avec succès!",
            type: "is-success",
          });
        })
        .catch((error) => {
          //console.log("Erreur lors de l'envoi de l'e-mail.", error);
          this.$buefy.toast.open({
            duration: 5000,
            message: "Erreur lors de l'envoi de l'e-mail.",
            type: "is-danger",
          });
        });
      this.isTechnique = false;
      try{
        await this.saveMail();
      } catch (error) {
        console.error(
          "An error occurred while submitting the question:",
          error
        );
      }
      this.$emit("closeModal");
    },
    async saveMail() {
      try {
        const Question = Parse.Object.extend("question_footer"); // Define the table
        const questionObject = new Question();

        questionObject.set("questionText", this.mailTechnique);
        questionObject.set("user", this.userSelected);
        questionObject.set("type", "technique");
        questionObject.set("statut", false);

        await questionObject.save();
        //console.log("Question submitted successfully");

        // Clear the form and close the modal
        this.mailTechnique = "";
        this.closeModal();
      } catch (error) {
        console.error(
          "An error occurred while submitting the question:",
          error
        );
      }
    },
  },
  async mounted() {
    ParseConfig();
    const currentUser = Parse.User.current();
    this.userSelected = currentUser;
    const queryUser = new Parse.Query(Parse.User);
    const queryGroup = new Parse.Query("user_group");
    queryGroup.equalTo("objectId", currentUser.attributes.group.id);
    queryUser.matchesQuery("group", queryGroup);
    queryUser.equalTo("therapeute", true);
    this.therapeute = await queryUser.first();
  },
};
</script>
