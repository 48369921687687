<template>
  <div>
    <div class="circle-container">
      <div
        :class="{
          connected: isConnected,
          'connection-indicator-top': ExerciceReady,
          'connection-indicator': !ExerciceReady,
        }"
      >
        <i class="fas" :class="isConnected ? 'fa-plug' : 'fa-ban'"></i>
        {{ isConnected ? "Connecté au capteur" : "Déconnecté du capteur" }}
      </div>
      <div class="text-app-store" v-if="!isConnected && !ExerciceReady">
        <p>
          Si vous n'avez pas installé SymbioSensor, veuillez
          <a @click="openAppInAppStore" class="text-install-app"
            >cliquer ici pour l'installer.</a
          >.
        </p>
      </div>
      <div class="titleAni">
        <p>Indice de lâcher-prise psycho-physiologique</p>
        <span class="italic-text"
          >Selon la Théorie Polyvagale de Stephen W. Porges</span
        >
      </div>
      <div :class="{ 'circle-move-up': timerFinished }" class="circle"></div>
      <!-- Ajout de la sélection du temps et du bouton pour démarrer l'exercice -->
      <div class="exercise-control transition-slide-in" v-if="!ExerciceReady">
        <select v-model="selectedTime" class="select-style">
          <option value="calibrage">Temps de moyennage</option>
          <option v-for="time in [1, 2]" :key="time">{{ time }} minutes</option>
        </select>
        <button
          class="buttonStartAni"
          @click="startCountdown"
          :disabled="
            (!isConnected && selectedTime === 'calibrage') ||
            (isConnected && selectedTime === 'calibrage') ||
            !isConnected
          "
        >
          <i class="fas fa-play"></i> Démarrer
        </button>
        <!-- <button
          class="buttonTutorial"
          v-if="!showTutorial"
          @click="showTutorial = true"
        >
          <i class="fas fa-book-open"></i>
          Lancer le tutoriel
        </button> -->
        <button
          class="buttonRelectureAni"
          v-on:click="DisplayResult = !DisplayResult"
        >
          Afficher les résultats
        </button>
      </div>
      <div class="timer-container" v-if="ExerciceReady && TimerCalibrage">
        <div class="timer-circle">
          <span class="timer-text-2">{{ formattedTime }}</span>
        </div>
        <div class="timer-circle2">
          <span class="timer-text placementInstantanneeANI">{{ lastAni }}</span>
          <span class="timer-text2 placementTextTimer2">Instantané</span>
        </div>
      </div>
      <div class="timer-container" v-if="ExerciceReady && !TimerCalibrage">
        <div class="timer-circle">
          <span class="timer-text placementMoyenneANI">{{ MoyenneAniMinute }}</span>
          <span class="timer-text placementTextTimer">Moyennage sur {{ TimeValueCalibrage }} min</span>
          <div
            class="needle"
            :style="{ transform: `rotate(${degrees}deg)` }"
          ></div>
        </div>
        <div class="timer-circle2">
          <span class="timer-text placementInstantanneeANI">{{ lastAni }}</span>
          <span class="timer-text2 placementTextTimer2">Instantané</span>
        </div>
        <div class="timer-circle3">
          <span class="timer-text">{{ formatTime(elapsedExerciseTime) }}</span>
        </div>
        <div class="timer-circle4" :class="{ 'zero-bpm-style': lastBPM === 0 }">
          <img v-if="lastBPM === 0"
              src="../../assets/img/LogoPasDeSignal.png"
              id="petite-image"
            />
          <span v-if="lastBPM>0"  class="timer-text placementInstantanneeANI">{{ lastBPM }}</span>
          <span v-if="lastBPM>0" class="timer-text2 placementTextTimer2">BPM</span>
          
        </div>
        <div class="timer-circle5">
          <span class="timer-text placementInstantanneeANI">{{ MoyenneAni }}</span>
          <span class="timer-text2 placementTextTimer2">Moyenne générale</span>
        </div>
      </div>
      <button
        class="buttonFinishAni"
        @click="finishExercice"
        v-if="ExerciceReady && !TimerCalibrage"
      >
        Terminer l'exercice
      </button>

      <!-- Afficher lastAni-->
      <!-- <transition name="fade">
      <div class="center-circle" v-if="ExerciceReady && !TimerCalibrage">
        <span class="ValueAni">{{ MoyenneAni }}</span>
        <span class="average-index">Indice Moyenné</span>
        
        <div class="bottom-circle">
          <span class="average-index2">Instantanée</span>
          <span class="bottomValue">{{ lastAni }}</span>
        </div>
      </div>
    </transition> -->

      <ul class="chart-skills">
        <li v-for="(skill, index) in skills" :key="index">
          <span></span>
        </li>
      </ul>

      <div class="donut-container">
        <div class="donut2">
          <div class="circle-center"></div>
        </div>
      </div>
      <!-- <span class="textMobilisation">Mobilisation</span>
    <span class="textStress">Stress</span> -->
      <!-- <span class="textSecurite">Sécurité</span>
    <span class="textEngagementSocial">Engagement social</span> -->
      <svg
        class="transition-slide-in"
        width="496.0116"
        height="240"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 400 200"
        style="position: fixed; margin-bottom: 20px"
      >
        <!-- Définir le chemin pour le demi-cercle sans le rendre visible -->
        <path
          id="halfCirclePath"
          d="M 20,190 A 170,180 0 0,1 380,190"
          fill="none"
          stroke="none"
        />

        <!-- Placer le texte le long du demi-cercle -->
        <text font-size="14" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="8%">
            Mobilisation / Stress
          </textPath>
        </text>
        <text font-size="14" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="65%">
            Sécurité / Engagement social
          </textPath>
        </text>
      </svg>
      <svg
        class="transition-slide-in"
        width="336.5793"
        height="177.147"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 400 200"
        style="
          position: fixed;
          margin-top: 50px;
          z-index: 10;
          overflow: visible;
        "
      >
        <!-- Définir le chemin pour le demi-cercle sans le rendre visible -->
        <path
          id="halfCirclePath"
          d="M 20,190 A 170,180 0 0,1 380,190"
          fill="none"
          stroke="none"
        />

        <!-- Placer le texte le long du demi-cercle -->
        <text font-size="16" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="7%">
            Douleur
          </textPath>
        </text>
        <text font-size="16" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="26%">
            Tension
          </textPath>
        </text>
        <text font-size="16" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="45%">
            Neutral
          </textPath>
        </text>
        <text font-size="16" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="63%">
            Confort
          </textPath>
        </text>
        <text font-size="16" fill="white">
          <textPath xlink:href="#halfCirclePath" startOffset="81%">
            Lâcher-prise
          </textPath>
        </text>
      </svg>
      <svg
        class="transition-slide-in"
        width="519.75"
        height="200"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 400 200"
        style="
          position: fixed;
          z-index: 10;
          overflow: visible;
          margin-top: 10px;
        "
      >
        <!-- Définir le chemin pour le demi-cercle sans le rendre visible -->
        <path
          id="halfCirclePath"
          d="M 20,190 A 170,180 0 0,1 380,190"
          fill="none"
          stroke="none"
        />

        <!-- Placer le texte "Douleur" le long du demi-cercle -->
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="1%">0</textPath>
        </text>
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="19%">30</textPath>
        </text>
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="38%">60</textPath>
        </text>
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="58%">90</textPath>
        </text>
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="77%">
            120
          </textPath>
        </text>
        <text font-size="16" fill="black">
          <textPath xlink:href="#halfCirclePath" startOffset="95%">
            150
          </textPath>
        </text>
      </svg>

      <!-- <p class="Placement0">0</p>
      <p class="Placement30">30</p>
      <p class="Placement60">60</p>
      <p class="Placement90">90</p>
      <p class="Placement120">120</p>
      <p class="Placement150">150</p> -->
      <div v-if="showTutorial" class="tutorial-overlay-background"></div>
      <div v-if="showTutorial" class="tutorial-overlay">
        <!-- Contenu du tutoriel -->
        <p>
          <i class="fas fa-info-circle"></i>
          {{ " " + tutorialSteps[currentTutorialStep].text }}
        </p>
        <button
          v-if="currentTutorialStep < tutorialSteps.length - 1"
          @click="nextStep"
        >
          Suivant
        </button>
        <button class="FinishTutorialButton" v-else @click="endTutorial">
          <i class="fas fa-check-circle"></i>Terminer
        </button>
      </div>
      <div
        v-for="(step, index) in tutorialSteps"
        v-if="showTutorial && currentTutorialStep === index"
        :class="`tutorial-highlight tutorial-highlight-${index}`"
        :style="step.highlightStyle"
        key="index"
      ></div>
    </div>
    <!-- <div>
      <div style="display: flex">
        <button
          style="margin-left: auto; margin-right: auto"
          class="buttonDisplayResult"
          v-on:click="DisplayResult = !DisplayResult"
        >
          Afficher les résultats
        </button>
      </div>
    </div> -->
    <div v-if="DisplayResult" class="PlacementRelectureAni">
      <relectureAni />
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardRedirectFront"
      no-close-on-esc
      no-close-on-backdrop
      :can-cancel="false"
    >
      <div style="background-color: white; border-radius: 20px; padding: 20px">
        <AppelRelectureAni
          @commentaire-change="handleCommentaireChange"
          :valeurX="valeurX"
          :fcMoyenne="fcMoyenne"
          :ccMoyenne="ccMoyenne"
          :couleurCCMoyenne="couleurCCMoyenne"
          :TabRowFC="valeurY"
          :DefaultOpenedDetails="DefaultOpenedDetails"
          :ValeurCCX="valeurCCX"
          :TabRowCC="valeurCCY"
          :labelChrono="TimeCalibrage"
          :timeElapsed="elapsedExerciseTime"
        />
        <div class="PlacementBoutonActionMoniteur">
          <div class="divBoutonActionMoniteur">
            <button
              v-on:click="(isCardRedirectFront = false), refreshPage()"
              id="buttonAnnuler"
              class="buttonChart"
              style="background-color: gray !important; color: white"
            >
              Annuler
            </button>
            <button
              v-on:click="SaveDataInParse()"
              id="buttonSave"
              class="buttonChart"
              style="background-color: rgb(35, 147, 128); color: white"
            >
              Sauvegarder
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppelRelectureAni from "@/components/AppelRelectureAni.vue";
import io from "socket.io-client";
import Parse from "parse";
import relectureAni from "../relecture/relectureAni.vue";
import { th, tr } from "date-fns/locale";
// import 'shepherd.js/dist/css/shepherd.css';
// import Shepherd from 'shepherd.js';
export default {
  name: "HalfCircle",
  data() {
    return {
      skills: ["Douleur", "Tension", "Confort", "Bien-être", "Lâcher-prise"],
      skillValues: [30, 60, 90, 120, 150],
      selectedTime: "calibrage",
      countdown: null,
      countdownInterval: null,
      showTutorial: false,
      bottomValue: 75,
      tutorialSteps: [
        {
          text: "Ce moniteur affiche l'indice de l'ani en temps réel.C'est une mesure utilisée pour évaluer les niveaux de douleur, tension, confort, bien-être et lâcher-prise. L'aiguille du moniteur se déplace pour indiquer la valeur actuelle de l'ani, tandis que les couleurs autour du cercle représentent les différentes compétences.",
          highlightStyle: {
            top: "250px",
            left: "650px",
            width: "600px",
            height: "400px",
            border: "2px solid red", // Ajoutez une bordure rouge
            boxSizing: "border-box", // Assurez-vous que la bordure est incluse dans la taille de la boîte
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Ajoutez un fond rouge transparent
          },
        },
      ],
      currentTutorialStep: 0,
      ExerciceReady: false,
      TimerCalibrage: false,
      elapsedExerciseTime: 0,
      isFinish: false,
      isCardRedirectFront: false,
      TimeCalibrage: 0,
      DisplayResult: false,
      // isConnected: false,
      StopTimer: false,
      TimeValueCalibrage: 0,
      aniRecords: [],
      tabAni: [], // Pour stocker toutes les valeurs d'ANI
      lastAni: 0, // Pour stocker la dernière valeur d'ANI
      totalAni: 0, // Pour calculer la somme totale d'ANI
      numAni: 0, // Pour compter combien de fois ANI est reçu
      isConnected: false, // Pour vérifier si la connexion est établie
      connexionWebSocket: false, // Pour vérifier la connexion WebSocket
      valeurX: 0,
      tabRR: [],
      ccMoyenne: 0,
      fcMoyenne: 0,
      couleurCCMoyenne: "#FE0000",
      averageAniRecords: [],
      DefaultOpenedDetails: "5VBBk5J53k",
      commentaireANI: "",
      valeurY: [],
      valeurCCX: [],
      valeurCCY: [],
      timerFinished: false,
      tabBPM: [],
      MoyenneAniMinute: 0,
      os: "",
      lastBPM: 0,
    };
  },
  components: {
    AppelRelectureAni,
    relectureAni,
  },
  mounted() {
    // const tour = new Shepherd.Tour({
    //   defaultStepOptions: {
    //     cancelIcon: {
    //       enabled: true
    //     },
    //     classes: 'class-1 class-2',
    //     scrollTo: { behavior: 'smooth', block: 'center' },
    //     modalOverlayOpeningPadding: 10, // Ajoutez cette ligne
    //     modalOverlayOpeningRadius: '50%', // Ajoutez cette ligne
    //   },
    //   useModalOverlay: true, // Ajoutez cette ligne
    // });
    // console.log('CreatedAni')
    // tour.addStep({
    //   title: 'Explication de la div titleAni',
    //   text: `La div 'titleAni' affiche l'indice de lâcher-prise psycho-physiologique selon la Théorie Polyvagale de Stephen W. Porges.`,
    //   attachTo: {
    //     element: '.text-app-store',
    //     on: 'bottom'
    //   },
    //   arrow: true,
    //   buttons: [
    //     {
    //       action() {
    //         return this.back();
    //       },
    //       classes: 'shepherd-button-secondary',
    //       text: 'Retour'
    //     },
    //     {
    //       action() {
    //         return this.next();
    //       },
    //       text: 'Suivant'
    //     }
    //   ],
    //   id: 'explaining'
    // });

    // tour.start();
    setTimeout(() => {
      this.timerFinished = true;
    }, 10000); // Ici, le timer est défini pour 10 secondes (10000ms)
  },
  methods: {
    formatTime(seconds) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    async SaveDataInParse() {
      //créer l'objet résultat :
      var resultat = {
        ArrayTacho: this.aniRecords,
        TabCC: this.averageAniRecords,
        AverageCC: this.MoyenneAni,
        TimeCompletion: this.elapsedExerciseTime,
        Timer: this.TimeCalibrage,
        BpmCC: this.fcMoyenne,
      };
      //le passer en string
      var resultatString = JSON.stringify(resultat);
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectGroup = Parse.Object.extend("user_group");
      const ObjetLink = Parse.Object.extend("link");
      const ObjectUser = Parse.Object.extend("User");
      const QueryUser = new Parse.Query(ObjectUser);
      const QueryLink = new Parse.Query(ObjetLink);
      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryFolder2 = new Parse.Query(ObjectFolder);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryGroup = new Parse.Query(ObjectGroup);
      QueryFolder.equalTo("objectId", "4ydeuBgcuu");
      QueryFolder2.equalTo("objectId", "8hXg8RQPbd");

      QueryFile.equalTo("objectId", "ZoRAP8YFNC");
      QueryLink.include("file");
      QueryLink.matchesQuery("file", QueryFile);
      let brique, module, file, user, link;

      QueryUser.equalTo("objectId", Parse.User.current().id);
      [brique, module, file, user, link] = await Promise.all([
        QueryFolder.find(),
        QueryFolder2.find(),
        QueryFile.find(),
        QueryUser.find(),
        QueryLink.find(),
      ]);

      var timeCalibrage = Number(this.TimeCalibrage);
      const dataFileDone = {
        brique: brique[0],
        time_elapsed: this.elapsedExerciseTime,
        user: user[0],
        module: module[0],
        protocole: module[0],
        resultatJeux: resultatString,
        note: null,
        file: file[0],
        comment: this.commentaireANI,
        group: Parse.User.current().get("group"),
        timeCalibrage: timeCalibrage,
        ANI: true,
      };
      const Data = Parse.Object.extend("file_done");
      const dataDone = new Data();
      try {
        await dataDone.save(dataFileDone);
        this.isCardRedirectFront = false;
      } catch (error) {
        //console.log("Error while saving data", error);
      }
      this.refreshPage();
    },
    refreshPage() {
      this.$emit("refresh-component");
    },
    startCountdown() {
      this.ExerciceReady = true;
      this.TimerCalibrage = true;

      let timeValue;
      if (
        typeof this.selectedTime === "string" &&
        this.selectedTime.includes("minutes")
      ) {
        timeValue = parseInt(this.selectedTime.split(" ")[0]);
      } else {
        timeValue = parseInt(this.selectedTime);
      }
      this.TimeValueCalibrage = timeValue;

      this.countdown = timeValue * 60;
      this.countdownInterval = setInterval(() => {
        if (this.StopTimer) {
          clearInterval(this.countdownInterval);
          return; // sortez de la fonction pour ne pas exécuter le reste du code si le timer doit s'arrêter
        }

        if (this.countdown > 0) {
          this.countdown--;
        } else {
          if (this.TimerCalibrage) {
            this.TimerCalibrage = false;
          }
          this.elapsedExerciseTime++;
        }
      }, 1000);
    },
    handleCommentaireChange(commentaire) {
      this.commentaireANI = commentaire;
    },
    finishExercice() {
      this.TimeCalibrage = this.TimeValueCalibrage * 60;
      this.valeurX = this.aniRecords.map((item) => item.x);
      this.valeurY = this.aniRecords.map((item) => item.y);
      this.ccMoyenne = this.MoyenneAni;
      //arrondir la moyenne de l'ani
      this.ccMoyenne = Math.round(this.ccMoyenne);
      //calculer la moyenne du BPM avec le tabBPM
      //console.log('Tableau moyenne bpm:', this.tabBPM);
      const somme = this.tabBPM.reduce((acc, curr) => acc + curr, 0);
      const moyenne = Math.round(somme / this.tabBPM.length);
      //console.log('Moyenne BPM:', moyenne);
      this.fcMoyenne = moyenne;
      this.valeurCCX = this.averageAniRecords.map((item) => item.x);
      this.valeurCCY = this.averageAniRecords.map((item) => item.y);
      this.StopTimer = true;
      this.isCardRedirectFront = true;

      console.log('TimeCalibrage:', this.TimeCalibrage, 'elapsedExerciseTime:', this.elapsedExerciseTime, 'MoyenneAni:', this.MoyenneAni, 'fcMoyenne:', this.fcMoyenne, 'ccMoyenne:', this.ccMoyenne, 'couleurCCMoyenne:', this.couleurCCMoyenne, 'valeurX:', this.valeurX, 'valeurY:', this.valeurY, 'valeurCCX:', this.valeurCCX, 'valeurCCY:', this.valeurCCY);
    },
    startTutorial() {
      this.showTutorial = true;
    },
    openAppInAppStore() {
      if (navigator.platform === "MacIntel") {
        // Ouvre l'application SymbioSensor dans le Mac App Store si l'application VueJS est exécutée sur un Mac
        window.open(
          "https://apps.apple.com/fr/app/symbio/id1611896454",
          "_blank"
        );
      } else {
        // Ouvre l'application SymbioSensor dans le Microsoft Store si l'application VueJS est exécutée sur un autre système d'exploitation
        window.open("ms-windows-store://pdp/?productid=9NQ9QQ9T3R2W", "_blank");
      }
    },
    nextStep() {
      this.currentTutorialStep++;
    },
    endTutorial() {
      this.showTutorial = false;
      this.currentTutorialStep = 0;
    },
    getOperatingSystem() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

    if (macPlatforms.indexOf(platform) !== -1) {
      return 'Mac';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows';
    } else {
      return 'Inconnu';
    }
    },
    performActionBasedOnOS() {
    const os = this.getOperatingSystem();
    if (os === 'Mac') {
      this.os = "Mac";
    } else if (os === 'Windows') {
      this.os = "Windows";
    } else { 
     this.os = "Inconnu";
    }
  },
    connectSocket() {
      const socket = io.connect("https://www.symbiocenter.com", {});
      let windowData = []; // stocke les données de la fenêtre glissante
      let windowStart = Date.now(); // marque le début de la fenêtre glissante
      socket.on("connect", () => {
        this.connexionWebSocket = true;
        socket.emit("authentication", {
          username: Parse.User.current().attributes.username,
        });

        socket.on("authenticated", function () {});
        socket.on("unauthorized", function () {});
        socket.on("disconnect", function () {});

        socket.on("ani", (ani) => {
          this.isConnected = true;
          console.log('isConnected:', this.isConnected);
          this.tabAni.push(ani);
          var aniJson = JSON.parse(ani);
          // console.log("ani json reçu:", aniJson);
          if(this.os==='Mac')
          {
          this.lastBPM = Math.round(60000 / aniJson.RR);
          }
          else{
            this.lastBPM = aniJson.RR;
          }
          // console.log("LastBPM:", lastBPM);
          if (this.lastBPM > 0 && this.lastBPM < 200) {
            this.tabBPM.push(this.lastBPM);
          }
          this.lastAni = aniJson.aniValue;
          this.totalAni += aniJson.aniValue; // mise à jour de la somme totale
          this.numAni++; // mise à jour du compteur
          if (this.elapsedExerciseTime > 0) {
            this.aniRecords.push({
              x: this.elapsedExerciseTime,
              y: aniJson.aniValue,
            });
            this.averageAniRecords.push({
              x: this.elapsedExerciseTime,
              y: this.MoyenneAni,
            });
          }
          // Ajout de la nouvelle valeur à la fenêtre glissante
          windowData.push(aniJson.aniValue);
          console.log("windowData:", windowData);
          // Suppression des valeurs qui sont hors de la fenêtre glissante d'une minute
          while (Date.now() - windowStart > 60000) {
            windowData.shift();
            windowStart += 1000; // déplace la fenêtre d'une seconde
          }
          // Calcul de la moyenne sur la fenêtre glissante
          this.MoyenneAniMinute=Math.round(windowData.reduce((a, b) => a + b, 0) / windowData.length);
        });
      });
    },
  },
  computed: {
    degrees() {
      let calculatedDegrees = (this.MoyenneAni * 190) / 150 - 95;
      return calculatedDegrees;
    },
    MoyenneAni() {
      if (this.totalAni && this.numAni) {
        return Math.round(this.totalAni / this.numAni);
      }
      return 0;
    },
    newAni() {
      return this.lastAni;
    },

    formattedTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
  },
  created() {
    this.performActionBasedOnOS();
    console.log("Voici l'os:",this.os)
    this.connectSocket();
  },
};
</script>

<style lang="scss" scoped>
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  // margin: auto;
  transform: scale(1.15);
  /* Augmenter la taille */
}

.chart-skills {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills *,
.chart-skills::before {
  box-sizing: border-box;
}

.chart-skills {
  position: relative;
  width: 350px;
  height: 175px;
  overflow: hidden;
}

.chart-skills::before,
.chart-skills::after {
  position: absolute;
}

.chart-skills::before {
  content: "";
  width: inherit;
  height: inherit;
  border: 45px solid rgba(211, 211, 211, 0.3);
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

/* .chart-skills::after {
  content: "Top Skills";
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
} */

.chart-skills li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 45px solid;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}

.chart-skills li:nth-child(1) {
  z-index: 5;
  border-color: #ff0000;
  /* Rouge */
  animation-name: rotate-one;
}

.chart-skills li:nth-child(2) {
  z-index: 4;
  border-color: #ff7f00;
  /* Orange */
  animation-name: rotate-two;
  animation-delay: 0.4s;
}

.chart-skills li:nth-child(3) {
  z-index: 3;
  border-color: #e9e935;
  /* Jaune */
  animation-name: rotate-three;
  animation-delay: 0.8s;
}

.chart-skills li:nth-child(4) {
  z-index: 2;
  border-color: #78e20d;
  /* Vert clair */
  animation-name: rotate-four;
  animation-delay: 1.2s;
}

.chart-skills li:nth-child(5) {
  z-index: 1;
  border-color: #29c029;
  /* Vert */
  animation-name: rotate-five;
  animation-delay: 1.6s;
}

.chart-skills span {
  position: absolute;
  font-size: 0.85rem;
  backface-visibility: hidden;
  animation: fade-in 0.4s linear forwards;
}

.chart-skills li:nth-child(1) span {
  top: 30px;
  left: -40px;
  transform: rotate(-105deg);
  font-size: 1rem;
  color: white;
}

.chart-skills li:nth-child(2) span {
  top: 35px;
  left: -40px;
  transform: rotate(-110deg);
  animation-delay: 0.4s;
  font-size: 1rem;
  color: white;
}

.chart-skills li:nth-child(3) span {
  top: 35px;
  left: -40px;
  transform: rotate(-110deg);
  animation-delay: 0.8s;
  font-size: 1rem;
  color: white;
}

.chart-skills li:nth-child(4) span {
  top: 35px;
  left: -45px;
  transform: rotate(-110deg);
  animation-delay: 1.2s;
  font-size: 1rem;
  color: white;
}

.chart-skills li:nth-child(5) span {
  top: 35px;
  left: -55px;
  transform: rotate(-110deg);
  animation-delay: 1.6s;
  font-size: 1rem;
  color: white;
}

@keyframes rotate-one {
  100% {
    transform: rotate(36deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotate(36deg);
  }

  100% {
    transform: rotate(72deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotate(72deg);
  }

  100% {
    transform: rotate(108deg);
  }
}

.shepherd-modal-overlay-container {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  /* Changez cette couleur selon vos préférences */
}

@keyframes rotate-four {
  0% {
    transform: rotate(108deg);
  }

  100% {
    transform: rotate(144deg);
  }
}

@keyframes rotate-five {
  0% {
    transform: rotate(144deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes fade-in {
  0%,
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.center-circle {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff !important;
  border: 8px solid rgb(35, 147, 128);
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 50 !important;
  flex-direction: column;
}

.average-index {
  position: absolute;
  font-size: 0.6rem;
  top: 20px;
}

.average-index2 {
  position: absolute;
  font-size: 0.6rem;
  top: 15px;
}

.chart-skills .skill-value {
  position: absolute;
  /* Ajustez ces valeurs en fonction de l'endroit où vous voulez que les valeurs apparaissent */
  top: 10px !important;
  left: 10px !important;
  font-size: 0.75rem;
  color: black !important;
}

.ValueAni {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 130%;
  width: 120%;
  border-radius: 50px;
}

.tutorial-button {
  position: fixed;
  top: 210px;
  right: 210px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.tutorial-highlight {
  position: absolute;
  background-color: red;
  opacity: 0.5;
  z-index: 1000;
}

.tutorial-overlay h2,
.tutorial-overlay p {
  text-align: center;
  margin-bottom: 10px;
  width: 400px;
  right: 150px;
  position: absolute;
}

.tutorial-overlay button {
  padding: 10px 20px;
  background-color: #239380;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 150px;
}

.tutorial-overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
}

.bottom-circle {
  position: absolute;
  bottom: -30px;
  left: 103%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 4px solid rgb(35, 147, 128);
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  z-index: 2;
}

.exercise-control {
  margin-top: 110px;
  z-index: 50;
  position: absolute;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  /* animation douce pour le mouvement et l'opacité */
}

.exercise-control.transition-slide-in {
  animation: slideInFromBottom 1s forwards;
}

button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.needle {
  position: absolute;
  bottom: 50%;
  left: 50%;
  height: 100%;
  width: 3px;
  background-color: black;
  transform-origin: 50% 100%;
  z-index: -2 !important;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  /* Arrondir le haut de l'aiguille */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  /* Ajouter un peu d'ombre pour un effet 3D */
  ::after {
    /* Créer la pointe de l'aiguille */
    z-index: -2 !important;
    content: "";
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 5px;
    height: 15px;
    background: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

.buttonStartAni {
  margin-top: 6px;
  margin-bottom: 20px;
}

.buttonStartAni {
  margin-left: auto;
  margin-right: auto;
  background-color: #239380;
  color: white;
  padding: 8px 15px;
  /* Réduit pour un bouton plus petit */
  border: none;
  border-radius: 4px;
  /* Rayon de coin légèrement réduit pour correspondre à la taille du bouton */
  font-size: 14px;
  /* Taille de police réduite pour un bouton plus petit */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Espace réduit entre l'icône et le texte */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  outline: none;
}

.buttonDisplayResult:hover {
  background-color: #1e746a;
  /* Teinte légèrement plus foncée pour le survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.buttonDisplayResult:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buttonDisplayResult {
  position: fixed;
  background-color: #239380;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  /* Rayon de coin légèrement réduit pour correspondre à la taille du bouton */
  font-size: 14px;
  /* Taille de police réduite pour un bouton plus petit */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Espace réduit entre l'icône et le texte */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  outline: none;
  z-index: 100;
  right: 100px;
}

.buttonDisplayResult:hover {
  background-color: #1e746a;
  /* Teinte légèrement plus foncée pour le survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.buttonDisplayResult:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

////
.buttonFinishAni:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buttonFinishAni {
  position: fixed;
  background-color: #239380;
  color: white;
  padding: 8px 15px;
  /* Réduit pour un bouton plus petit */
  border: none;
  border-radius: 4px;
  /* Rayon de coin légèrement réduit pour correspondre à la taille du bouton */
  font-size: 14px;
  /* Taille de police réduite pour un bouton plus petit */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Espace réduit entre l'icône et le texte */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  outline: none;
  z-index: 100;
  margin-top: 500px;
}

.titleAni {
  position: fixed;
  margin-bottom: 360px;
  text-align: center;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
}

.titleAni p {
  font-size: 1.2em;
  color: #333;
  line-height: 1.5;
}

.italic-text {
  display: block;
  font-style: italic;
}

/* Media Query pour les écrans de moins de 768px de largeur */
@media only screen and (max-width: 768px) {
  .titleAni p {
    font-size: 1em;
    /* Font-size réduit pour les petits écrans */
  }
}

@media only screen and (max-width: 768px) {
  .buttonTutorial {
    display: none;
    /* Cacher le bouton pour les petits écrans */
  }
}

.buttonFinishAni:hover {
  background-color: #1e746a;
  /* Teinte légèrement plus foncée pour le survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.buttonFinishAni:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fas.fa-play {
  margin-right: 5px;
  /* Espace réduit pour équilibrer avec le texte */
}

.buttonTutorial:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buttonTutorial {
  background-color: #239380;
  color: white;
  padding: 8px 15px;
  /* Réduit pour un bouton plus petit */
  border: none;
  border-radius: 4px;
  /* Rayon de coin légèrement réduit pour correspondre à la taille du bouton */
  font-size: 14px;
  /* Taille de police réduite pour un bouton plus petit */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Espace réduit entre l'icône et le texte */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  outline: none;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
}

.buttonRelectureAni {
  margin-top: 10px;
  background-color: #239380;
  color: white;
  padding: 8px 15px;
  /* Réduit pour un bouton plus petit */
  border: none;
  border-radius: 4px;
  /* Rayon de coin légèrement réduit pour correspondre à la taille du bouton */
  font-size: 14px;
  /* Taille de police réduite pour un bouton plus petit */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Espace réduit entre l'icône et le texte */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  outline: none;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
}

.buttonRelectureAni:hover {
  background-color: #1e746a;
  /* Teinte légèrement plus foncée pour le survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.text-app-store {
  position: fixed;
  margin-bottom: 580px;
  z-index: 120;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 600px;
}

.text-app-store p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.text-install-app {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}

.text-install-app:hover,
.text-install-app:focus {
  border-bottom: 2px solid #007bff;
}

@media (max-width: 768px) {
  .text-app-store {
    padding: 15px;
    font-family: Arial, sans-serif;
  }

  .text-app-store p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .text-app-store {
    padding: 10px;
    font-family: Arial, sans-serif;
  }

  .text-app-store p {
    font-size: 0.8rem;
  }
}

.buttonTutorial:hover {
  background-color: #1e746a;
  /* Teinte légèrement plus foncée pour le survol */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.buttonTutorial:active {
  transform: scale(0.98);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style de base pour le select */
/* Style de base pour le select */
.select-style {
  margin-top: 100px;
  background-color: white;
  border: 1px solid #ddd;
  /* Bordure légère pour définir l'élément */
  padding: 8px 30px 8px 12px;
  /* Espace à droite pour l'icône */
  border-radius: 4px;
  font-size: 14px;
  /* Taille de police réduite pour correspondre à la taille du sélecteur */
  cursor: pointer;
  position: relative;
  /* Nécessaire pour positionner l'icône pseudo-élément correctement */
  transition: border-color 0.2s ease;
  /* Transition douce pour le survol */
}

/* L'icône de flèche vers le bas pour indiquer qu'il s'agit d'un menu déroulant */
.select-style:after {
  content: "\25BC";
  /* Flèche vers le bas */
  position: absolute;
  right: 10px;
  /* Positionnement à droite */
  top: 50%;
  transform: translateY(-50%);
  /* Centrage vertical */
  pointer-events: noZne;
  /* Assurez-vous que l'icône ne perturbe pas l'interaction */
  color: #888;
  /* Couleur de l'icône */
}

/* Style au survol */
.select-style:hover {
  border-color: #239380;
  /* La même couleur que le bouton pour la cohérence */
}

.buttonChart {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: rgb(35, 147, 128) !important;
  color: white;
  margin: 7px;
}

.timer-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: 150px;
  z-index: 100;
}

.donut {
  width: 400px;
  height: 400px;
  border: 50px solid;
  border-radius: 50%;
  box-sizing: content-box;
  background: transparent;
  position: relative;
  padding: 20px;
  border-color: #ff6347 transparent transparent transparent;
  /* La première partie du donut */
  position: fixed;
  margin-top: 170px;
  transform: rotate(-45deg);
}

/* Deuxième partie */
.donut::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 100% 0 0 0;
  transform: rotate(-45deg);
}

/* Troisième partie */
.donut::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 0 0 0 100%;
  transform: rotate(-45deg);
}

/* Quatrième partie */
.donut > div {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 0 100% 0 0;
  transform: rotate(-45deg);
}

.donut2 {
  width: 480px;
  height: 480px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  background: conic-gradient(
    transparent 0% 17%,
    #ff0000ab 17% 37%,
    transparent 37% 47%,
    #008000a8 47% 67%,
    transparent 67% 100%
  );
  /* Utilisez "white" pour l'espace entre chaque segment */
  margin-top: 170px;
  padding: 5px;
  transform: rotate(209deg);
}

.donut-container.transition-slide-in {
  animation: slideInFromTop 1.5s forwards;
}

.donut-container {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  /* animation douce pour le mouvement et l'opacité */
  position: fixed;
}

/* Si vous souhaitez ajouter un cercle central, utilisez ceci: */
.PlacementBoutonActionMoniteur {
  display: flex;
  text-align: center;
}

.divBoutonActionMoniteur {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.circle-center {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  background-color: #f3f3f3;
  /* La couleur du cercle central */
  border-radius: 50%;
}

.donut3 {
  width: 400px;
  height: 400px;
  border: 50px solid;
  border-radius: 50%;
  box-sizing: content-box;
  background: transparent;
  position: relative;
  padding: 20px;
  border-color: #ff6347 transparent transparent transparent;
  /* La première partie du donut */
  position: fixed;
  margin-top: 170px;
  transform: rotate(45deg);
}

/* Deuxième partie */
.donut3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 100% 0 0 0;
  transform: rotate(-45deg);
}

/* Troisième partie */
.donut3::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 0 0 0 100%;
  transform: rotate(-45deg);
}

/* Quatrième partie */
.donut3 > div {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  width: 50%;
  background: transparent;
  border-radius: 0 100% 0 0;
  transform: rotate(-45deg);
}

.timer-circle {
  background: white;
  /* Fond blanc */
  border: 5px solid rgb(35, 147, 128);
  /* Épaississez la bordure pour voir mieux le remplissage */
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.timer-circle2 {
  position: fixed;
  background: white;
  /* Fond blanc */
  border: 5px solid rgb(35, 147, 128);
  /* Épaississez la bordure pour voir mieux le remplissage */
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  margin-top: 130px;
}

.timer-circle3 {
  position: fixed;
  background: white;
  /* Fond blanc */
  border: 5px solid rgb(35, 147, 128);
  /* Épaississez la bordure pour voir mieux le remplissage */
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  margin-bottom: 650px;
}

// .timer-circle:hover {
//   transform: scale(1.05); /* Effet hover */
// }

.timer-text {
  font-size: 1.5rem;
  color: rgb(35, 147, 128);
  font-family: "Arial", sans-serif;
  /* Remplacez 'Arial' par n'importe quelle police d'écriture que vous préférez */
}
.timer-text-2 {
  font-size: 1.5rem;
  color: rgb(35, 147, 128);
  font-family: "Arial", sans-serif;
  margin-bottom: 35px;
  /* Remplacez 'Arial' par n'importe quelle police d'écriture que vous préférez */
}

.timer-text2 {
  font-size: 1.2rem;
  color: rgb(35, 147, 128);
  font-family: "Arial", sans-serif;
  /* Remplacez 'Arial' par n'importe quelle police d'écriture que vous préférez */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.Placement0 {
  position: fixed;
  margin-right: 380px;
  margin-top: 160px;
}

.Placement30 {
  position: fixed;
  margin-right: 320px;
  margin-bottom: 50px;
}

.Placement60 {
  position: fixed;
  margin-bottom: 190px;
  margin-right: 130px;
}

.Placement90 {
  position: fixed;
  margin-bottom: 190px;
  margin-left: 110px;
}

.Placement120 {
  position: fixed;
  margin-left: 320px;
  margin-bottom: 50px;
}

.Placement150 {
  position: fixed;
  margin-left: 390px;
  margin-top: 160px;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.chart-qualities {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: fixed;
  /* Ajustez la position comme nécessaire pour suivre la courbure des skills */
  top: 160px;
  right: 420px;
}

.chart-qualities li {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
  color: #239380;
  background-color: #ffffff;
  padding: 6px 14px;
  border: 2px solid #239380;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.chart-qualities li:hover {
  background-color: #239380;
  color: #ffffff;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.textMobilisation {
  position: fixed;
  margin-right: 450px;
  transform: rotate(-70deg);
  color: white;
}

.textStress {
  position: fixed;
  margin-right: 330px;
  margin-bottom: 200px;
  transform: rotate(-40deg);
  color: white;
}

.textSecurite {
  position: fixed;
  margin-left: 300px;
  margin-bottom: 220px;
  transform: rotate(45deg);
  color: white;
}

.textEngagementSocial {
  position: fixed;
  margin-left: 450px;
  transform: rotate(70deg);
  color: white;
}

.connection-indicator-top {
  display: inline-flex;
  align-items: center;
  background-color: red;
  padding: 5px 15px;
  border-radius: 25px; // Rend l'indicateur plus arrondi pour le style "chip"
  color: white;
  position: absolute;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 500; // Ajoute un peu de gras au texte pour le rendre plus visible
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Ombre douce pour un peu de profondeur
  z-index: 100;
  margin-bottom: 650px;

  i {
    margin-right: 8px;
    transition: transform 0.3s ease;
  }

  &.connected {
    background-color: green;

    i {
      transform: scale(
        1.1
      ); // Faites "pousser" légèrement l'icône pour attirer l'attention lors de la connexion
    }
  }

  &:hover {
    transform: translateY(-2px); // Un petit effet de "levage" au survol
  }
}

.connection-indicator {
  display: inline-flex;
  align-items: center;
  background-color: red;
  padding: 5px 15px;
  border-radius: 25px; // Rend l'indicateur plus arrondi pour le style "chip"
  color: white;
  position: absolute;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: 500; // Ajoute un peu de gras au texte pour le rendre plus visible
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Ombre douce pour un peu de profondeur
  z-index: 100;
  margin-bottom: 450px;

  i {
    margin-right: 8px;
    transition: transform 0.3s ease;
  }

  &.connected {
    background-color: green;

    i {
      transform: scale(
        1.1
      ); // Faites "pousser" légèrement l'icône pour attirer l'attention lors de la connexion
    }
  }

  &:hover {
    transform: translateY(-2px); // Un petit effet de "levage" au survol
  }
}

.buttonStartAni:disabled {
  background-color: #ababab;
  cursor: not-allowed;
}

.placementTextTimer {
  position: absolute;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.placementTextTimer2 {
  position: absolute;
  font-size: 11px;
  margin-top: 20px;
  text-align: center;
}

.placementMoyenneANI {
  margin-bottom: 60px;
}

.placementInstantanneeANI {
  margin-bottom: 30px;
}

.PlacementRelectureAni {
  position: absolute;
  width: 80%;
  top: 95%;
}

@keyframes moveUp {
  to {
    transform: translateY(-100px);
    /* ou la distance que vous souhaitez */
  }
}

.circle-move-up {
  animation: moveUp 1s forwards;
  /* 1s est la durée de l'animation */
}

.FinishTutorialButton {
}

.timer-circle4
{
  position: fixed;
    background: #fff;
    border: 3px solid #239380;
    border-radius: 10px;
    background-origin: border-box;
    background-clip: content-box,border-box;
    width: 80px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px rgba(0,0,0,.2);
    box-shadow: 0px 0px 15px rgba(0,0,0,.2);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    /* margin-bottom: 650px; */
    margin-right: 350px;
    margin-top: 150px;
}
.timer-circle5
{
  position: fixed;
    background: #fff;
    border: 3px solid #239380;
    border-radius: 10px;
    background-origin: border-box;
    background-clip: content-box,border-box;
    width: 80px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px rgba(0,0,0,.2);
    box-shadow: 0px 0px 15px rgba(0,0,0,.2);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    /* margin-bottom: 650px; */
    margin-left: 350px;
    margin-top: 150px;
}
.zero-bpm-style
{
  border: 3px solid red !important;
}
</style>
