<template>
  <div class="welcome-screen">
    <h1 class="title">Bienvenue sur le Symbiocenter</h1>
    <div class="features">
      <div class="feature">
        <i class="fas fa-book-open"></i>
        <p>
          Accès à des programmes, ressources libres et échelles d'évaluation
        </p>
      </div>
      <div class="feature">
        <i class="fas fa-user-md"></i>
        <p>Suivi complet du thérapeute</p>
      </div>
      <div class="feature">
        <i class="fas fa-comments"></i>
        <p>Chat et visio-conférence à votre disposition</p>
      </div>
    </div>
    <label for="acceptCGU" class="accept-label">
      <input
        type="checkbox"
        id="acceptCGU"
        v-model="acceptedCGU"
        class="accept-checkbox"
      />
      J'accepte l'ensemble des conditions d'utilisation du Symbiocenter (<a href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf" target="_blank">CGU</a>, <a href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf" target="_blank">RGPD</a>)
    </label>
    <p v-if="showErrorMessage" class="error-message">
      Vous devez accepter les conditions d'utilisation pour continuer.
    </p>
    <button @click="continueIfAccepted" class="continue-button">
      Continuer
    </button>
  </div>
</template>

<script>
import Parse from "parse";

export default {
  data() {
    return {
      acceptedCGU: false,
      showErrorMessage: false,
    };
  },
  created()
  {
    try{
   console.log('User connecté:', Parse.User.current());
   var dureeAcces = Parse.User.current().attributes.duree_acces;
   var groupUser = Parse.User.current().attributes.group;
   console.log('Durée d\'accès:', dureeAcces);
    console.log('Groupe:', groupUser);
    var Group = Parse.Object.extend("user_group");
    var queryGroup = new Parse.Query(Group);
    queryGroup.equalTo("objectId", groupUser.id);
    queryGroup.find().then((group) => {
      console.log('Groupe:', group);
      var NbCredits = group[0].attributes.credits;  
      console.log('Nombre de crédits', NbCredits);
      console.log('Durée d\'accès:', dureeAcces);
      // retirer les crédits au groupe par rapport à durée d'accès
      var newNbCredits = NbCredits - dureeAcces;
      console.log('Nouveau nombre de crédits', newNbCredits);
      group[0].set("credits", newNbCredits);
      group[0].save();
      console.log('Crédits retirés');
    });
  }
  catch(e)
  {
    console.log('Erreur:', e);
  }
    
  },
  methods: {
    continueIfAccepted() {
      if (this.acceptedCGU) {
        // Mettez ici le code pour continuer après avoir accepté les CGU.
        this.$emit("continue");
         var User = Parse.Object.extend("User");
         var query = new Parse.Query(User);
         query.get(Parse.User.current().id).then((user) => {
           user.set("cgu", true);
          user.save();
          //console.log("CGU acceptées");
         });
      } else {
        // Afficher un message d'erreur ou une notification pour inciter l'utilisateur à cocher la case.
        this.showErrorMessage = true;
      }
    },
  },
};
</script>

<style lang="scss">
.error-message {
  color: red;
}
.welcome-screen {
  text-align: center;
  margin: 20px;
  padding: 100px;
  border-radius: 10px;
  background-color: #f2f2f2;

  .title {
    font-size: 32px;
    margin-bottom: 20px;
    color: #239380;
  }

  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Permet à la grille de fonctionnalités de s'adapter à l'écran */

    .feature {
      flex-basis: calc(
        33.33% - 20px
      ); /* Répartit les fonctionnalités sur trois colonnes, avec un espacement de 20px entre elles */
      padding: 20px;
      i {
        font-size: 48px;
        color: #239380;
      }
      p {
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }

  .accept-label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    font-size: 20px;

    .accept-checkbox {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    .accept-label {
      font-size: 16px;
    }
  }

  .continue-button {
    background-color: #239380;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #239380;
    }
  }

  /* Media query pour les écrans plus petits (par exemple, les téléphones) */
  @media (max-width: 768px) {
    .features {
      .feature {
        flex-basis: calc(
          50% - 20px
        ); /* Deux colonnes par ligne avec un espacement de 20px entre elles */
      }
    }
  }
}
@media (max-width: 768px) {
  .welcome-screen {
    padding: 20px;
  }
}

</style>
