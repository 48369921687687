<template lang="html">
  <div class="is-main-content">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <div class="overflow2">
          <folders-component @displayHeader="handleDisplayHeader" style="padding: 0rem" :userCNI="user" :key="keyProfile"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
// import { Chrome } from "vue-color/";
import { createNamespacedHelpers } from "vuex";
// import user from "../store/user";
import foldersComponent from "./profilFolders.vue";
const User = createNamespacedHelpers("user");

export default {
  name: "mes-activites",
  data() {
    return {
      keyProfile: 0,
    };
  },
  computed: {
    ...User.mapGetters(["user"]),
  },
  components: {
    // "chrome-picker": Chrome,
    FoldersComponent: foldersComponent,
  },
  async mounted() {
    try {
      ParseConfig();
      await Parse.User.current();
    } catch (error) {
      return "";
    }
  },
  methods: {
    handleDisplayHeader() {
      this.$emit("displayHeader");
    },
  },
};
</script>

<style>
.steps .step-item.is-active .step-marker {
  color: #239380 !important;
  border-color: #239380 !important;
}

.steps .step-item.is-completed .step-marker {
  background-color: #239380 !important;
}

.steps.is-large .is-active:not(:first-child)::before {
  background: #239380 !important;
}

.steps.is-large .is-completed:not(:first-child)::before {
  background: #239380 !important;
}

.step-detailsDesc {
  font-size: medium;
  background-color: #239380;
  color: white;
  padding: 5px;
  border-radius: 9px;
}

.steps.is-large .step-item .step-details {
  margin-top: 4rem;
}

.cacherDetails {
  display: none;
}

.retourEspaceGestion {
  background-color: #b2b2b2;
  color: white;
  margin: 15px;
  padding: 30px;
  white-space: normal;
}

.retourEspacePersonnalisation {
  margin: 15px;
  padding: 30px;
  white-space: normal;
}

.decouvrirEspaceActivite {
  white-space: normal;
  padding: 30px;
  margin: 15px;
}

.decouvrirEspaceActivite img {
  width: 36px;
  margin-left: 20px;
}

.retourEspacePersonnalisation img {
  width: 306px;
  margin-left: 20px;
}

.retourEspaceGestion label {
  width: 250px;
}

.thisproto {
  opacity: 1;
  border: 2px solid #239380;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.thisproto-lg {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 540px) {
  .thisproto-lg {
    height: 100px !important;
    width: 360px !important;
  }
}
@media screen and (max-width: 585px) and (min-width: 540px) {
  .thisproto-lg {
    height: 120px !important;
    width: 185px !important;
  }
}
@media screen and (max-width: 720px) and (min-width: 585px) {
  .thisproto-lg {
    height: 120px !important;
    width: 200px !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 720px) {
  .thisproto-lg {
    height: 120px !important;
    width: 260px !important;
  }
}
@media screen and (min-width: 768px) {
  .thisproto-lg {
    height: 200px !important;
    width: 303px !important;
  }
}

.thisproto-sm {
  height: 115px;
  width: 115px;
}

.modules-list {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 480px) {
  .modules-list {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: start;
  }
}

.dir-col-mobile {
  flex-direction: column;
}

@media screen and (min-width: 480px) {
  .dir-col-mobile {
    flex-direction: row;
  }
}
</style>
