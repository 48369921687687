<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link">
      <i
        class="fas fa-bell icon-valide"
        :class="{ 'has-notifications': notifications.length !== 0 }"
      ></i>
    </a>
    <div class="navbar-dropdown is-right">
      <div class="navbar-item" v-if="notifications.length === 0">
        Aucune notification
      </div>
      <div
        class="navbar-item"
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-message
          type="is-info"
          style="width: 100%"
          v-if="
            (user.therapeute && notification.attributes.questionnaireEvent) ||
            notification.attributes.meeting_event
          "
        >
          <div
            style="margin-right: 15px"
            class="notification-status"
            v-if="
              notification.attributes.meeting_event &&
              notification.attributes.meeting_event.attributes.is_accepted !==
                undefined
            "
          >
            <i
              class="fas"
              :class="{
                'fa-check-circle':
                  notification.attributes.meeting_event.attributes.is_accepted,
                'fa-times-circle':
                  !notification.attributes.meeting_event.attributes.is_accepted,
                'icon-accepted':
                  notification.attributes.meeting_event.attributes.is_accepted,
                'icon-declined':
                  notification.attributes.meeting_event.attributes
                    .is_accepted === false,
              }"
            ></i>
          </div>
          <div
            style="margin-right: 15px"
            v-else-if="
              notification.attributes.questionnaireEvent && user.therapeute
            "
            class="notification-status"
          >
            <i
              class="fas fa-exclamation-triangle exercise-type-icon icon-red"
            ></i>
          </div>
          <div
            class="notification-content"
            v-if="notification.attributes.type === 'Visio-conférence'"
          >
            <div class="notification-content">
              <i class="fas fa-video fa-2x"></i>
            </div>
            <div v-if="user.therapeute" class="notification-content">
              <strong>{{
                notification.attributes.type +
                " avec " +
                notification.attributes.user.attributes.lastname +
                " " +
                notification.attributes.user.attributes.firstname
              }}</strong>
              <p>
                {{
                  notification.attributes.description +
                  " avec vous le " +
                  formatDate(
                    notification.attributes.meeting_event.attributes
                      .date_meeting
                  )
                }}
              </p>
            </div>
            <div v-else class="notification-content">
              <strong>{{
                notification.attributes.type +
                " avec " +
                notification.attributes.user_pro.attributes.lastname +
                " " +
                notification.attributes.user_pro.attributes.firstname
              }}</strong>
              <p>
                {{
                  notification.attributes.description +
                  " avec vous le " +
                  formatDate(
                    notification.attributes.meeting_event.attributes
                      .date_meeting
                  )
                }}
              </p>
            </div>
            <div
              class="notification-action"
              v-if="
                notification.attributes.meeting_event.attributes.is_accepted ===
                  undefined && user.therapeute
              "
            >
              <div class="notification-action-div">
                <b-button
                  @click="acceptMeeting(notification)"
                  type="is-success"
                  icon-left="check"
                  class="notification-action-button"
                >
                  Accepter
                </b-button>
                <b-button
                  @click="declineMeeting(notification)"
                  type="is-danger"
                  class="notification-action-button"
                >
                  X Refuser
                </b-button>
              </div>
            </div>
            <div v-if="!user.therapeute" class="notification-action">
              <p
                class="notification-text"
                v-if="
                  notification.attributes.meeting_event.attributes
                    .is_accepted === undefined
                "
              >
                Votre rendez-vous est en attente.
              </p>
              <p
                class="notification-text-green"
                v-else-if="
                  notification.attributes.meeting_event.attributes.is_accepted
                "
              >
                Votre rendez-vous a été validé par votre professionnel.
              </p>
              <p class="notification-text-red" v-else>
                Votre rendez-vous a été refusé par votre professionnel.
              </p>
            </div>
            <div v-else>
              <p
                v-if="
                  notification.attributes.meeting_event.attributes
                    .is_accepted === undefined
                "
              ></p>
              <p
                class="notification-text-green"
                v-else-if="
                  notification.attributes.meeting_event.attributes.is_accepted
                "
              >
                Vous avez validé le rendez-vous.
              </p>
              <p class="notification-text-red" v-else>
                Vous avez refusé le rendez-vous.
              </p>
            </div>
            <!-- <div class="notification-action">
              <b-button
                @click="showFullDescription(notification)"
                icon-left="plus"
                class="notification-action-button"
              ></b-button>
            </div> -->
          </div>
          <div
            v-if="
              notification.attributes.type === 'Questionnaire' &&
              user.therapeute
            "
            class="notification-content"
          >
            <div class="notification-content">
              <i class="fas fa-clipboard fa-2x exercise-type-icon"></i>
            </div>
            <div v-if="user.therapeute" class="notification-content">
              <strong>{{
                notification.attributes.type +
                " fait par " +
                notification.attributes.user.attributes.lastname +
                " " +
                notification.attributes.user.attributes.firstname
              }}</strong>
              <div class="DivDescription">
                <p class="TexteDescription">
                  {{ notification.attributes.description }}
                </p>
              </div>
            </div>
          </div>
        </b-message>
      </div>
      <div v-if="notifications.length !== 0" class="placementButtonNews">
        <b-button v-on:click="afficheBloc('profil')" class="buttonNews"
          >Plus d'actualités</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export default {
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  created() {
   
  },
  methods: {
    showFullDescription(notification) {
      this.$buefy.dialog.alert({
        title: notification.type,
        message: notification.description,
        confirmText: "Fermer",
        type: "is-info",
        hasIcon: true,
        icon: "info",
      });
    },
    declineMeeting(notification) {
      ParseConfig();
      var ObjectNotification = Parse.Object.extend("notification");
      var query = new Parse.Query(ObjectNotification);
      query.get(notification.id).then((object) => {
        var queryMeeting = new Parse.Query("meeting");
        queryMeeting
          .get(notification.attributes.meeting_event.id)
          .then((objectMeeting) => {
            objectMeeting.set("is_accepted", false);
            objectMeeting.save().then((response) => {
              this.$emit("meeting-accepted", response);
            });
          });
      });
    },
    acceptMeeting(notification) {
      ParseConfig();
      var ObjectNotification = Parse.Object.extend("notification");
      var query = new Parse.Query(ObjectNotification);
      query.get(notification.id).then((object) => {
        var queryMeeting = new Parse.Query("meeting");
        queryMeeting
          .get(notification.attributes.meeting_event.id)
          .then((objectMeeting) => {
            objectMeeting.set("is_accepted", true);
            objectMeeting.save().then((response) => {
              this.$emit("meeting-accepted", response);
            });
          });
      });
    },
    formatDate(date) {
      return format(new Date(date), "EEEE dd MMMM yyyy à HH:mm", {
        locale: fr,
      });
    },
    afficheBloc(bloc) {
      this.$emit("affiche-bloc", bloc);
    },
  },
};
</script>
<style>
.has-notifications {
  font-size: 1.5em; /* Ajustez la taille de l'icône selon vos préférences */
  position: relative;
}

.has-notifications::after {
  content: "";
  position: absolute;
  top: -5px; /* Ajustez la position verticale du point rouge */
  right: -5px; /* Ajustez la position horizontale du point rouge */
  width: 10px;
  height: 10px;
  background-color: red; /* Couleur du point rouge */
  border-radius: 50%;
}
.notification-action {
  display: flex;
  margin-top: 5px;
}
.notification-action-div {
  margin-left: auto;
  margin-right: auto;
}
.notification-action-button {
  margin-left: 5px;
}
.notification-content {
  text-align: center;
}
.placementButtonNews {
  display: flex;
}
.buttonNews {
  margin-left: auto;
  margin: 2px;
}
.notification-status {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px; /* Ajustez la taille de l'icône ici */
}
.notification-text-red {
  color: red;
}
.notification-text-green {
  color: green;
}
.icon-accepted {
  color: green;
}
.icon-declined {
  color: red;
}
.notification-orange {
  background-color: #ffcc99; /* Remplacez cette valeur par la couleur orange clair souhaitée */
  /* Ajoutez d'autres styles pour la notification si nécessaire */
}
.icon-red {
  color: red;
}
.TexteDescription {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  white-space: pre-line; /* Ajout de cette propriété */
}

.DivDescription {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 400px;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.IndexNavbar
{
  z-index:50;
}
</style>
