// socketService.js
import io from "socket.io-client";
import { ref } from "vue";
import Parse from "parse";

export default function useSocketService() {
  const connexionWebSocket = ref(false);
  const tachoXY = ref([]);

  const connectSocket = () => {
    const socket = io.connect("https://www.symbiocenter.com", {});
    socket.on("connect", function () {
      connexionWebSocket.value = true;

      socket.emit("authentication", {
        username: Parse.User.current().attributes.username,
      });

      socket.on("authenticated", function () {});
      socket.on("unauthorized", function () {});
      socket.on("disconnect", function () {});

      socket.on("rr", function (rr) {
        const RRJson = JSON.parse(rr);
        const traceTacho = RRJson.traceTacho;

        // Extraire les coordonnées x et y des objets traceTacho et les stocker dans le tableau tachoXY
        traceTacho.forEach((item) => {
          tachoXY.value.push({ x: item.x, y: item.y });
        });
      });
    });
  };

  return { connexionWebSocket, connectSocket, tachoXY };
}
