<template>
  <div v-if="loadFilesDone">
    <div
      v-if="UserSelected.filesDone.length > 0"
      @click="toggleDiv('symbiosensor')"
    >
      <b-table
        id="suivie"
        class="is-size-6"
        ref="table"
        :data="UserSelected.filesDone"
        :bordered="true"
        :narrowed="true"
        :striped="true"
        detailed
        detail-key="id"
        :show-detail-icon="false"
        :paginated="true"
        :pagination-rounded="true"
        :per-page="4"
      >
        <template slot-scope="props" slot="header">
          <div>{{ props.column.label }}</div>
        </template>

        <template slot-scope="props">
          <b-table-column field="brique" label="Brique" searchable>
            <template #searchable="props">
              <b-input
                v-model="props.filters['brique']"
                placeholder="Filtrer..."
              />
            </template>
            {{ props.row.brique }}
          </b-table-column>
          <b-table-column field="protocole" label="Programme" searchable>
            <template #searchable="props">
              <b-input
                v-model="props.filters['protocole']"
                placeholder="Filtrer..."
              />
            </template>
            {{ props.row.protocole }}
          </b-table-column>
          <b-table-column field="moduleP" label="Seance" searchable>
            <template #searchable="props">
              <b-input
                v-model="props.filters['moduleP']"
                placeholder="Filtrer..."
              />
            </template>
            {{ props.row.moduleP }}
          </b-table-column>
          <b-table-column field="file" label="Exercice" searchable>
            <template #searchable="props">
              <b-input
                v-model="props.filters['file']"
                placeholder="Filtrer..."
              />
            </template>
            {{ props.row.file }}
          </b-table-column>
          <b-table-column label="Temps passé">
            <div v-if="props.row.time_elapsed">
              <div>
                {{ Math.round(props.row.time_elapsed) }}
                <span v-if="Math.round(props.row.time_elapsed) === 1">
                  {{ $t("minute") }}
                </span>
                <span v-else>{{ $t("minutes") }}</span>
              </div>
            </div>
          </b-table-column>
          <b-table-column label="Date de réalisation">
            {{ new Date(props.row.createdAt).toLocaleDateString() }}
          </b-table-column>
          <b-table-column field="email" label="Heure de réalisation">
            <span v-if="new Date(props.row.createdAt).getMinutes() <= 9">
              {{ new Date(props.row.createdAt).getHours() }}:0{{
                new Date(props.row.createdAt).getMinutes()
              }}
            </span>
            <span v-else>
              {{ new Date(props.row.createdAt).getHours() }}:{{
                new Date(props.row.createdAt).getMinutes()
              }}
            </span>
          </b-table-column>
          <b-table-column field="nb_acces" label="Nb accès">
            {{ props.row.nb_acces }}
          </b-table-column>
          <b-table-column field="estDetaillable" label="Détail">
            <a @click="goToResultatquestionnaire(props.row)">
              <b-button
                v-if="
                  props.row.estQuestionnaire !== undefined &&
                  props.row.estQuestionnaire
                "
                size="is-small"
                type="is-light"
                style="background-color: #218977; color: white"
              >
                {{ $t("détail") }}
              </b-button>
            </a>
            <a @click="sendToCCResult(props.row)">
              <b-button
                v-if="
                  coherencevue == false &&
                  props.row.estModuleExpert !== undefined &&
                  props.row.estModuleExpert
                "
                size="is-small"
                type="is-light"
                style="background-color: #218977; color: white"
              >
                {{ $t("détail") }}
              </b-button>
            </a>
            <a @click="goToResultatcoherence(props.row)">
              <b-button
                v-if="
                  (coherencevue == true || coherencevue == undefined) &&
                  props.row.estModuleExpert !== undefined &&
                  props.row.estModuleExpert
                "
                size="is-small"
                type="is-light"
                style="background-color: #218977; color: white"
              >
                {{ $t("détail") }}
              </b-button>
            </a>
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content" v-if="questionnaire === true">
              <div class="content">
                <p>
                  <strong>
                    Dernière note obtenue :
                    {{ props.row.noteQuestionnaire }}
                  </strong>
                </p>
                <span v-html="props.row.analyseQuestionnaire" />
                <app-histo-note-questionnaire
                  :fileQuestionnaireId="props.row.fileId"
                  :user="UserSelected"
                />
              </div>
            </div>
            <div class="media-content" v-if="coherence === true">
              <div class="content">
                <app-suivieCc
                  v-if="loadProtocoles"
                  :utilisateurs="utilisateurs"
                  :userSelected="userSelected"
                  :folders="folders"
                  :userParticulier="ouvertureSuiviCcUtilisateur"
                  :IUserSelected="UserSelected"
                  :fileCCId="props.row.fileId"
                  :idprotocole="props.row.protocole"
                />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
    <div v-else>
      {{ $t("aucuneActivite") }}
    </div>
  </div>
</template>

<script>
import Parse from "parse";
// import router from "@/router";
import HistoNoteQuestionnaire from "../tableauDeBord/histoNoteQuestionnaire";
import ParseConfig from "@/api/parse.config.js";
import moment from "moment";
import asyncForEach from "@/services/general/asyncForEach.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";
import { getObjectById } from "@/services/parse/getObject.js";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import suivieCc from "../tableauDeBord/SuivieCc.vue";
export default {
  name: "suivi-utilisateur",
  components: {
    appHistoNoteQuestionnaire: HistoNoteQuestionnaire,
    appSuivieCc: suivieCc,
  },
  props: {
    UserSelected: {
      required: true,
    },
  },
  data() {
    return {
      mail: "",
      User: null,
      utilisateurs: [],
      utilisateursAffilie: [],
      nbUsersInMyGroupWithActiveAccess: 0,
      filesDone: [],
      loadProtocoles: false,
      questionnaire: false,
      coherence: false,
      userSelected: "tous",
      sessionsGroup: null,
      utilisateursSessionGroup: null,
      groupSelected: "tous",
      affiliation_en_attente: 0,
      loadChartConnection: false,
      loadFilesDone: false,
      ouvertureSuiviCcUtilisateur: "",
      indefinteToast: null,
      IUserSelected: "",
      fileCCId: "",
      idprotocole: "",
      coherencevue: "",
      usergroup: "",
      openDivId: null,
      userSelectedProfil: "",
    };
  },
  computed: {
    ...User.mapGetters(["group", "folders"]),
  },
  created() {
    this.UserSelectedProfil = this.UserSelected;
  },
  methods: {
    toggleDiv(id) {
      this.openDivId = this.openDivId === id ? null : id;
      let divs = document.getElementById(id);
      let div = null;
      switch (this.openDivId) {
        case "suivie":
          div = "suivi";
          break;
        default:
          div = "null";
          break;
      }
      if (div === this.openDivId) {
        if (divs.style.display === "block") divs.style.display = "none";
        else divs.style.display = "block";
      } else {
        divs.style.display = "none";
      }
    },
    goToResultatquestionnaire(row) {
      this.$refs.table.toggleDetails(row);
      this.questionnaire = true;
      this.coherence = false;
    },
    goToResultatcoherence(row) {
      this.$refs.table.toggleDetails(row);
      this.questionnaire = false;
      this.coherence = true;
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    sendToCCResult() {
      localStorage.objectIdToUse = this.UserSelected.objectId;
      window.location.href =
        "https://www.symbiocenter.com/resultatexpert/WFKMKUWnf4?accesDirect=true";
    },
    async sendMail() {
      var userCourant = await Parse.User.current();
      await Parse.Cloud.run("demandeAutorisationAcces", {
        destinataire: this.UserSelected.mail,
        pro:
          userCourant.attributes.firstname && userCourant.attributes.lastname
            ? userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname
            : userCourant.attributes.username,
        mail: this.mail,
        utilisateur: this.UserSelected.prenom + " " + this.UserSelected.nom,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Demande d'autorisation envoyée avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal");
    },
    close() {
      this.$emit("closeModal");
    },
    async getMyUsers() {
      ParseConfig();
      var tmpUtilisateur = [];
      this.loadChartConnection = false;
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;

      const UsersFromGroupQuery = new Parse.Query(Parse.User);
      UsersFromGroupQuery.equalTo("group", UserGroupTemporary);
      UsersFromGroupQuery.limit(10000);
      const UsersInMyGroup = await UsersFromGroupQuery.find();

      const UsersAffiliesQuery = new Parse.Query(Parse.User);
      UsersAffiliesQuery.equalTo("affiliation", Parse.User.current());
      UsersAffiliesQuery.limit(10000);
      const UserAffiliated = await UsersAffiliesQuery.find();
      await UsersInMyGroup.forEach((user) => {
        if (
          moment().toDate() <
            moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
        tmpUtilisateur.push(user);
      });
      await UserAffiliated.forEach((user) => {
        var tmpUser = tmpUtilisateur.filter((usr) => {
          return usr.id == user.id;
        });
        if (tmpUser.length == 0) tmpUtilisateur.push(user);
      });
      for (var i = 0; i < tmpUtilisateur.length; i++) {
        if (tmpUtilisateur[i].id == this.UserSelected.objectId) {
          return (this.utilisateurs = tmpUtilisateur[i]);
        }
      }
      this.loadChartConnection = true;
    },
    async getUserAffiliateByGroupId() {
      this.affiliation_en_attente = 0;
      ParseConfig();
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("affiliation", Parse.User.current());
      QueryUser.limit(100000);
      const ResponseUser = await QueryUser.find();
      const QueryUserGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryUserGroup.equalTo("objectId", this.group.objectId);
      const ResponseUserGroup = await QueryUserGroup.first();
      const QueryUser2 = new Parse.Query(Parse.User);
      QueryUser2.equalTo("group", ResponseUserGroup);
      QueryUser2.limit(10000);
      const ResponseUserFromGroup = await QueryUser2.find();
      ResponseUserFromGroup.forEach((e) => {
        if (
          e.attributes.date_invitation_affiliation &&
          !e.attributes.date_affiliation
        ) {
          this.affiliation_en_attente += 1;
        }
      });
      const ArrayUtilisateurs = [];
      await asyncForEach(ResponseUser, async (utilisateur) => {
        const newUtilisateur = {
          firstname: utilisateur.attributes.firstname,
          lastname: utilisateur.attributes.lastname,
          id: utilisateur.id,
          date_affiliation: utilisateur.attributes.date_affiliation,
          date_invitation_affiliation:
            utilisateur.attributes.date_invitation_affiliation,
          email: utilisateur.attributes.username,
        };
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        if (utilisateur.attributes.group) {
          QueryUserGroup.equalTo("objectId", utilisateur.attributes.group.id);
          const userGroup = await QueryUserGroup.first();
          if (newUtilisateur.date_affiliation) {
            if (
              (!userGroup.attributes.type_abonnement_perso ||
                userGroup.attributes.type_abonnement_perso === "gratuit") &&
              moment(new Date()).toDate() <=
                moment(newUtilisateur.date_affiliation).add(10, "days").toDate()
            ) {
              newUtilisateur.etat = "essai gratuit";
              this.affiliation_en_attente += 1;
            } else if (userGroup.attributes.type_abonnement_perso === "cplay") {
              newUtilisateur.etat = "valide";
            } else {
              newUtilisateur.etat = "inactif";
            }
          }
        }
        ArrayUtilisateurs.push(newUtilisateur);
      });
      this.utilisateursAffilie = sortInvArrayByParam(
        ArrayUtilisateurs,
        "dateInscription"
      );
    },
    async getFilesDones() {
      this.loadFilesDone = false;
      try {
        var resultat = await Parse.Cloud.run("getFilesDones", {
          groupId: this.group.objectId,
        });
        var jsonResultat = JSON.parse(resultat);
        this.filesDone = jsonResultat;
        this.loadFilesDone = true;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des exercices terminés.",
          type: "is-danger",
        });
      }
    },
    async getAllSessionGroup() {
      try {
        const ResponseUserGroup = await getObjectById(
          "user_group",
          this.group.objectId
        );
        const ObjectSessionGroup = Parse.Object.extend("session_group");
        const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
        QuerySessionGroup.equalTo("user_group", ResponseUserGroup);
        var tmpSessionGroup = await QuerySessionGroup.find();
        this.sessionsGroup = tmpSessionGroup;
      } catch (error) {
        //console.log(error);
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des séances de groupe.",
          type: "is-danger",
        });
      }
    },
  },
  async mounted() {
    const usergroupObject = Parse.Object.extend("user_group");
    const userObject = Parse.Object.extend("_User");
    const queryusergroup = new Parse.Query(usergroupObject);
    const queryuser = new Parse.Query(userObject);

    queryuser.equalTo("objectId", this.UserSelected.objectId);
    var recupgroup = await queryuser.find();
    this.usergroup = recupgroup[0].attributes.group.id;
    queryusergroup.equalTo("objectId", this.usergroup);
    var resultat = await queryusergroup.find();
    this.coherencevue = resultat[0].attributes.CoherenceVue;

    if (this.$route.params.username !== undefined) {
      this.ouvertureSuiviCcUtilisateur = this.$route.params.username;
    }
    // try {
    //   var query = new Parse.Query("site_admin");
    //   await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
    const loadingComponent = this.$buefy.loading.open();
    try {
      this.User = await Parse.User.current();
      await this.actionUpdateUserGroup();
      await this.getMyUsers();
      await this.getUserAffiliateByGroupId();
      await this.getAllSessionGroup();
      await this.getFilesDones();
      loadingComponent.close();
      this.loadProtocoles = true;
    } catch (error) {
      //console.log(error);
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Echec de récupérations des informations.",
        type: "is-danger",
      });
    }
  },
  watch: {
    async groupSelected() {
      if (this.groupSelected === "tous") {
        this.utilisateursSessionGroup = null;
        return;
      }
      const RelationUserFromSessionGroup = this.groupSelected.relation("users");
      let QueryRelationUserFromSessionGroup =
        RelationUserFromSessionGroup.query();
      QueryRelationUserFromSessionGroup.limit(1000);
      this.utilisateursSessionGroup =
        await QueryRelationUserFromSessionGroup.find();
    },
  },
};
</script>
