<template>
    <footer class="sticky-footer">
      <div class="footer-logo">
        <img src="../../../../assets/img/logo_symbiofi.png"
        alt="Symbiocenter" />
      </div>
      <div class="footer-content">
        <div class="footer-section">
          <h3>Mentions légales</h3>
          <ul>
            <li><a href="https://www.symbiocenter.com/assets/Mentions_legales.pdf" target="_blank">Retrouvez les mentions légales</a></li>
            <li><a href="https://symbiocenter.fr/conditions-generales/"
            target="_blank">Consultez les conditions générales d'utilisations</a></li>
            <li><a href="https://symbiocenter.fr/equipe" target="_blank">Crédits Auteurs</a></li>
          </ul>
        </div>
        <div class="footer-section">
          <h3>Contact</h3>
          <ul>
            <li><a @click="ModalTechnique()">Une question technique ?</a></li>
            <li><a @click="ModalBienEtre()
            ">Une question liée au bien-être ?</a></li>
            <li><a href="https://symbiocenter.fr/centre_assistance/" target="_blank">Centre d'aide</a></li>
          </ul>
        </div>
      </div>
      <!-- <b-modal
      :width="1200"
      :active.sync="isBienEtre"
      @close="
        isBienEtre = false;
        userSelected = null;
      "
    >
      <div class="card" v-if="userSelected">
        <div>
          <div class="card editUser">
            <div class="card-content">
              <div class="content">
                <div class="overflow">
                  <h3 class="title has-text-black is-size-5">
                    {{ $t("Question à votre référent professionnel") }}
                  </h3>
                  <div>
                    <p>
                      Vous avez-une question sur votre pratique, vous souhaitez
                      partager avec votre professionnel référent ?
                    </p>
                    <b-field>
                      <b-input
                        v-model="mailBienEtre"
                        maxlength="2000"
                        type="textarea"
                      ></b-input>
                    </b-field>
                  </div>
                  <div>
                    <div class="is-pulled-left">
                      <a class="button is-cyan" @click="sendMailBienEtre()">
                        {{ $t("envoyer") }}
                      </a>
                    </div>
                    <div class="is-pulled-right">
                      <a
                        class="is-pulled-right button is-grey is-margin-r-10"
                        @click="isBienEtre = false"
                      >
                        {{ $t("annuler") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
    <!-- <b-modal
      :width="1200"
      :active.sync="isTechnique"
      @close="
        isTechnique = false;
        userSelected = null;
      "
    >
      <div class="card" v-if="userSelected">
        <div>
          <div class="card editUser">
            <div class="card-content">
              <div class="content">
                <div class="overflow">
                  <h3 class="title has-text-black is-size-5">
                    {{ $t("Question support technique") }}
                  </h3>
                  <div>
                    <p>
                      Vous avez une question sur les fonctionnalités de la
                      plateforme ?
                    </p>
                    <b-field>
                      <b-input
                        v-model="mailTechnique"
                        maxlength="2000"
                        type="textarea"
                      ></b-input>
                    </b-field>
                  </div>
                  <div>
                    <div class="is-pulled-left">
                      <a class="button is-cyan" @click="sendMailTechnique()">
                        {{ $t("envoyer") }}
                      </a>
                    </div>
                    <div class="is-pulled-right">
                      <a
                        class="is-pulled-right button is-grey is-margin-r-10"
                        @click="isTechnique = false"
                      >
                        {{ $t("annuler") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
    </footer>
  </template>

  <script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
import { URL_FRONT } from "@/configuration/urlConf.js";

const User = createNamespacedHelpers("user");

export default {
  name: "FooterA",
  props: ["UserSelected"],
  data() {
    return {
      userSelected: null,
      isTechnique: false,
      isBienEtre: false,
      mailTechnique: "",
      mailBienEtre: "",
      therapeute: null,
    };
  },
  computed: {
    ...User.mapGetters(["user", "group"]),
  },
  methods: {
    async sendMailBienEtre() {
      await Parse.Cloud.run("sendMailContactTherapeutique", {
        destinataire: this.therapeute.attributes.username,
        expediteur: this.user.username,
        messageEcrit: this.mailBienEtre,
      })
        .then((response) => {
          console.log("E-mail envoyé avec succès.", response);
          this.$buefy.toast.open({
            message: "Votre message a bien été envoyé",
            type: "is-success",
            duration: 5000,
          });
        })
        .catch((error) => {
          console.log("Erreur lors de l'envoi de l'e-mail.", error);
          this.$buefy.toast.open({
            message: "Une erreur est survenue lors de l'envoi de votre message",
            type: "is-danger",
            duration: 5000,
          });
          //console.log("Erreur lors de l'envoi de l'e-mail.", error);
        });
      this.isBienEtre = false;
    },
    ModalBienEtre()
    {
      this.isBienEtre = true;
      this.userSelected = this.user;
      //envoyer true et le userSelected au component parent
      this.$emit("openModal");
    },
    ModalTechnique()
    {
      this.isTechnique = true;
      this.userSelected = this.user;
      //envoyer true et le userSelected au component parent
      this.$emit("openModalTechnique");
    },
    EnvoieTest() {
      Parse.Cloud.run("sendMailContactTherapeutique", {
        destinataire: "contact@symbiocenter.com",
        messageEcrit: "Test blabalbalblablablalbla",
        expediteur: "l.hanquiez22@gmail.com",
      })
        .then((response) => {
          console.log("E-mail envoyé avec succès.", response);
        })
        .catch((error) => {
          console.log("Erreur lors de l'envoi de l'e-mail.", error);
        });

      //console.log("Envoie du test...");
    },

    async sendMailTechnique() {
      Parse.Cloud.run("sendMailContactTechnique", {
        destinataire: "contact@symbiocenter.com",
        messageEcrit: this.mailTechnique,
        expediteur: this.user.username,
      })
        .then((response) => {
          console.log("E-mail envoyé avec succès.", response);
          this.$buefy.toast.open({
            duration: 5000,
            message: "Votre message a été envoyé avec succès!",
            type: "is-success",
          });
        })
        .catch((error) => {
          console.log("Erreur lors de l'envoi de l'e-mail.", error);
          this.$buefy.toast.open({
            duration: 5000,
            message: "Erreur lors de l'envoi de l'e-mail.",
            type: "is-danger",
          });
        });
      this.isTechnique = false;
      this.$emit("closeModal");
    },
    async redirectFront(e) {
      this.UpdateConnexion(e.objectId);
      await this.$emit("closeModal");
      localStorage.objectIdToUse = e.objectId;
      if (e.exos_personnaliser) {
        // Avec accès personnalisé aux exercices
        localStorage.setItem("idPatientPerso", e.objectId);
        window.location.href = `${URL_FRONT}?objectId=${localStorage.objectIdToUse}`;
      } // Sans accès personnalisé aux exercices ( Hérite des accès du thérapeute )
      else {
        localStorage.setItem("idUserForSave", e.objectId);
        window.location.href = URL_FRONT;
      }
    },
  },
  async mounted() {
    ParseConfig();
    const currentUser = Parse.User.current();
    const queryUser = new Parse.Query(Parse.User);
    const queryGroup = new Parse.Query("user_group");
    queryGroup.equalTo("objectId", currentUser.attributes.group.id);
    queryUser.matchesQuery("group", queryGroup);
    queryUser.equalTo("therapeute", true);
    this.therapeute = await queryUser.first();
  },
};
</script>


  <style lang="scss" scoped>
  .sticky-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #eaeaea;
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 0.6s forwards 0.2s;
  
    @keyframes fadeInUp {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    .footer-logo {
      flex: 0 0 auto;
      margin-right: 20px;
      transition: transform 0.3s;
  
      &:hover {
        transform: scale(1.05);
      }
  
      img {
        max-width: 150px;
        height: auto;
      }
    }
  
    .footer-content {
      display: flex;
      align-items: center;
  
      .footer-section {
        margin-left: 20px;
        transition: transform 0.3s;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        h3 {
          font-size: 1.2em;
          margin-bottom: 10px;
          color: #333;
        }
  
        ul {
          list-style-type: none;
          padding: 0;
  
          li {
            margin-bottom: 5px;
            transition: transform 0.3s;
  
            &:hover {
              transform: translateY(-3px);
            }
  
            a {
              text-decoration: none;
              color: #489181;
              transition: color 0.3s;
  
              &:hover {
                color: darken(#489181, 10%);
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .sticky-footer {
      flex-direction: column;
      align-items: flex-start;
  
      .footer-logo {
        margin-bottom: 20px;
      }
  
      .footer-content {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  </style>
  