<template>
  <div class="timerDiv">
    <div class="text-app-store" v-if="!isConnected">
      <p>
        Si vous n'avez pas installé SymbioSensor, veuillez
        <a @click="openAppInAppStore" class="text-install-app"
          >cliquer ici pour l'installer.</a
        >.
      </p>
    </div>
    <div class="InformationDivParent">
      <div v-show="timerRunning" class="timer-display">{{ time }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startTime: null,
      currentTime: null,
      timerRunning: false,
      interval: null,
      time: "00:00",
    };
  },
  props: {
    isConnected: {
      type: Boolean,
      default: false,
    },
    lastAni: {
      type: Number,
      required: true,
    },
    lastBPM: {
      type: Number,
      required: true,
    },
  },
  methods: {
    startTimer() {
      if (!this.timerRunning) {
        this.timerRunning = true;
        this.startTime = Date.now();
        this.interval = setInterval(() => {
          const elapsedTime = Date.now() - this.startTime;
          this.time = this.formatTime(elapsedTime);
        }, 1000);
        this.$emit("start-timer");
      }
    },
    // finish() {
    //   this.timerRunning = false;
    //   // clearInterval(this.interval);
    //   this.$emit("finish", this.time);
    // },
    openAppInAppStore() {
      if (navigator.platform === "MacIntel") {
        // Ouvre l'application SymbioSensor dans le Mac App Store si l'application VueJS est exécutée sur un Mac
        window.open(
          "https://apps.apple.com/fr/app/symbio/id1611896454",
          "_blank"
        );
      } else {
        // Ouvre l'application SymbioSensor dans le Microsoft Store si l'application VueJS est exécutée sur un autre système d'exploitation
        window.open("ms-windows-store://pdp/?productid=9NQ9QQ9T3R2W", "_blank");
      }
    },
    formatTime(milliseconds) {
      let totalSeconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;

      minutes = String(minutes).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");

      return `${minutes}:${seconds}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style scoped>
.timerDiv {
  /* margin-bottom: 15px; */
  width: 100%;
}
/*div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.startButton {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #489181;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.startButton:hover {
  background-color: #3b6e5e;
}

.startButton:active {
  background-color: #2e5344;
}

.startButton:focus {
  outline: none;
}

div {
  margin-top: 10px;
  font-size: 2rem;
  color: #333;
}

.text-app-store {
  background-color: #f0f0f0; /* Fond subtil pour distinguer le message */
  border-left: 4px solid #007bff; /* Bordure à gauche pour attirer l'attention */
  padding: 20px; /* Espacement interne pour une meilleure lisibilité */
  margin: 20px 0; /* Marges extérieures pour séparer du contenu adjacent */
  color: #333; /* Couleur du texte sombre pour le contraste */
  border-radius: 5px; /* Coins arrondis pour une esthétique douce */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Ombre subtile pour la profondeur */
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.text-app-store p {
  margin: 0; /* Annule la marge par défaut du paragraphe pour un alignement parfait */
  font-size: 16px; /* Taille de police adaptée pour la lisibilité */
}

.text-install-app {
  color: #007bff; /* Couleur qui se démarque sans être trop agressive */
  text-decoration: none; /* Supprime le soulignement par défaut */
  font-weight: bold; /* Met en évidence le lien d'action */
  padding-bottom: 2px; /* Petit padding pour un effet visuel sous le texte */
  border-bottom: 2px solid #0056b3; /* Souligne légèrement pour indiquer l'interactivité */
}

.text-install-app:hover,
.text-install-app:focus {
  background-color: #e7e7e7; /* Changement subtil au survol pour inviter au clic */
  border-bottom-color: #007bff; /* Couleur de soulignement dynamique */
  cursor: pointer; /* Change le curseur pour indiquer l'action */
}

.timer-display {
  font-size: 1.5rem; /* Taille de police accrue pour une meilleure lisibilité */
  color: #333; /* Couleur de texte pour contraste élevé */
  margin-bottom: 10px; /* Espacement pour séparation visuelle */
  font-weight: bold; /* Rend le texte du timer plus marquant */
  text-align: center; /* Centre le texte du timer pour un alignement visuel */
}

.finish-button {
  display: block; /* Assure que le bouton est bien aligné et facile à cliquer */
  margin: 0 auto; /* Centre le bouton horizontalement */
  padding: 10px 20px; /* Ajoute un padding pour un meilleur aspect et une meilleure sensation au clic */
  font-size: 1rem; /* Taille de police appropriée pour le bouton */
  color: white; /* Couleur de texte pour le bouton */
  background-color: #489181; /* Couleur de fond pour le bouton, peut être ajustée */
  border: none; /* Supprime la bordure par défaut du bouton */
  border-radius: 5px; /* Arrondit les coins pour un look moderne */
  cursor: pointer; /* Change le curseur pour indiquer que le bouton est cliquable */
  transition: background-color 0.3s; /* Transition douce pour l'interaction du bouton */
}

.finish-button:hover {
  background-color: #357f63; /* Assombrit le bouton au survol pour un feedback visuel */
}
.InformationDiv {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease-in-out;
  margin-left: 50px;
  margin-right: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.InformationDiv:hover {
  background-color: #f5f5f5;
}

.InformationDiv small {
  margin-left: 5px;
  color: #777;
}

.InformationDiv .value2 {
  font-size: 24px;
  color: #444;
}

.InformationDiv .icon {
  width: 20px;
  height: 20px;
  fill: #777;
}

.InformationDivAni {
  border: 2px solid rgba(255, 159, 64);
}
.InformationDivBpm {
  /* border:4px solid red; */
}

@media (max-width: 768px) {
  .InformationDiv {
    flex-direction: column;
    align-items: flex-start;
  }

  .InformationDiv .value2 {
    font-size: 18px;
  }

  .InformationDiv .icon {
    display: none;
  }
}

.InformationDivParent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top:5%;
}

</style>
