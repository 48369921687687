<template>
  <div class="modal-backdrop" @click="closeInfoModal">
    <div class="modal-content" @click.stop>
      <div @click="closeInfoModal" class="close-button">
        <font-awesome-icon icon="times" />
      </div>
      <h3 class="title">{{ selectedFolder.name }}</h3>
      <h5 class="title">{{ selectedFolder.author }}</h5>

      <!-- Affiche les vidéos de videosIntro -->
      <div
        v-if="
          selectedFolder.videosIntro && selectedFolder.videosIntro.length > 0
        "
      >
        <div class="video-list">
          <!-- Accède directement à la première vidéo -->
          <div class="video-item">
            <h4>{{ selectedFolder.videosIntro[0].name || "" }}</h4>
            <div
              v-if="
                selectedFolder.videosIntro[0].sources &&
                selectedFolder.videosIntro[0].sources[0].src
              "
            >
              <video controls>
                <source
                  :src="selectedFolder.videosIntro[0].sources[0].src"
                  :type="selectedFolder.videosIntro[0].sources[0].type"
                />
                Votre navigateur ne supporte pas la lecture de cette vidéo.
              </video>
            </div>
            <div v-else>
              <p>Aucune vidéo disponible pour cet élément.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Affiche la description du dossier avec "Lire la suite" -->
      <div v-html="displayedText"></div>
      <div class="buttons-container">
        <button
          v-if="shouldShowButton"
          @click="toggleReadMore"
          class="read-more-button"
        >
          {{ isExpanded ? "Réduire" : "Lire la suite" }}
        </button>
        <button @click="SelectThisBrique" class="selection-button">
          Sélectionner
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUp",
  props: {
    selectedFolder: {
      type: Object,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 600, // Longueur maximale pour l'extrait avant de montrer "Lire la suite"
    },
  },
  data() {
    return {
      isExpanded: false, // Indique si le texte est complètement affiché ou réduit
    };
  },
  computed: {
    displayedText() {
      // Affiche le texte complet si "isExpanded" est vrai
      if (this.isExpanded || !this.selectedFolder.text) {
        return (
          this.selectedFolder.text || "<p>Aucune description disponible</p>"
        );
      }

      // Sinon, affiche un extrait avec une longueur maximale
      const text = this.selectedFolder.text || "";
      const truncatedText = text.slice(0, this.maxLength);
      return truncatedText + (text.length > this.maxLength ? "..." : "");
    },
    shouldShowButton() {
      // Affiche le bouton seulement si le texte dépasse la longueur maximale
      return (
        this.selectedFolder.text &&
        this.selectedFolder.text.length > this.maxLength
      );
    },
  },
  methods: {
    toggleReadMore() {
      this.isExpanded = !this.isExpanded;
    },
    closeInfoModal() {
      this.$emit("close");
    },
    SelectThisBrique() {
      this.$emit("select", this.selectedFolder);
    },
  },
};
</script>

<style scoped>
/* Contexte sombre et flouté pour la modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6); /* Fond sombre légèrement plus opaque */
  backdrop-filter: blur(4px); /* Floutage de l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 10px; /* Espace pour éviter que la modale touche les bords sur petits écrans */
}

/* Contenu de la modal */
.modal-content {
  background-color: #fefefe; /* Blanc cassé pour un effet plus doux */
  padding: 25px;
  border-radius: 15px; /* Coins plus arrondis */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Ombre douce et diffuse */
  max-width: 650px; /* Largeur un peu plus large pour plus de confort visuel */
  width: 100%;
  max-height: 85vh; /* Limiter la hauteur pour éviter le débordement */
  overflow-y: auto; /* Scroll si le contenu dépasse */
  text-align: center;
  transition: all 0.3s ease-in-out; /* Transition fluide pour les interactions */
}

/* Titre de la modal */
.modal-content h3 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #239380; /* Couleur douce et moderne */
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* Auteur de la modal */
.modal-content h5 {
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #239380; /* Couleur douce et moderne */
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

/* Texte de la modal */
.modal-content p {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #555; /* Couleur légèrement assombrie pour un meilleur contraste */
  line-height: 1.6; /* Espacement plus aéré entre les lignes */
}

/* Bouton stylisé */
.modal-content button {
  padding: 12px 24px;
  background-color: #239380;
  color: white;
  border: none;
  border-radius: 25px; /* Bouton avec bords arrondis */
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Légère ombre pour donner de la profondeur */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transition douce */
  margin-top: 15px;
}

/* Effet au survol du bouton */
.modal-content button:hover {
  background-color: #348b7d; /* Teinte plus sombre au survol */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Ombre plus prononcée */
}

/* Effet au clic du bouton */
.modal-content button:active {
  background-color: #346e62; /* Teinte encore plus sombre au clic */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombre réduite au clic */
  transform: scale(0.98); /* Léger effet de réduction au clic */
}

.read-more-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 12px; /* Plus petit que le bouton fermer */
  padding: 0;
}

.read-more-button:hover {
  color: #ffffff;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 1.5rem;
}

.selection-button {
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
}

.video-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.video-item {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

video {
  max-width: 100%;
}
/* Media Queries pour un affichage fluide sur tous les appareils */
@media (max-width: 768px) {
  .modal-content {
    max-width: 95vw; /* Réduire la largeur sur les tablettes */
    padding: 20px; /* Réduire le padding pour les petits écrans */
  }

  .modal-content h3 {
    font-size: 1.6rem; /* Ajuster la taille du titre */
  }

  .modal-content h5 {
    font-size: 1rem; /* Ajuster la taille du titre */
  }

  .modal-content p {
    font-size: 0.95rem; /* Ajuster la taille du texte */
  }

  .modal-content button {
    font-size: 0.9rem;
    padding: 10px 20px; /* Réduire légèrement les dimensions du bouton */
  }
}

@media (max-width: 480px) {
  .modal-content {
    max-width: 100vw; /* Prendre toute la largeur sur les très petits écrans */
    max-height: 80vh; /* Limiter la hauteur sur les petits écrans */
    padding: 15px; /* Réduire le padding pour les très petits écrans */
  }

  .modal-content h3 {
    font-size: 1.4rem; /* Titre légèrement plus petit */
  }

  .modal-content h5 {
    font-size: 1rem; /* Titre légèrement plus petit */
  }

  .modal-content p {
    font-size: 0.9rem; /* Texte légèrement plus petit */
  }

  .modal-content button {
    font-size: 0.85rem;
    padding: 8px 16px; /* Bouton plus petit */
  }
}


.buttons-container {
  display: flex;
  justify-content: space-between; /* Espacement entre les boutons */
  align-items: center;
  margin-top: 15px; /* Ajustez l'espace au besoin */
}

.read-more-button {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.selection-button {
  margin-left: auto; /* Garde le bouton à droite */
}
</style>
