<template>
  <div>
    <canvas
      class="is-margin-b-50"
      ref="chart"
      height="200px"
      width="1000px"
    ></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
import moment from "moment";
import Parse from "parse";

export default {
  name: "histo-note-questionnaire",
  props: ["fileQuestionnaireId", "user"],
  data() {
    return {
      options: {
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              id: "y-axis-1",
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async getHistorique() {
      const queryFile = new Parse.Query("file");
      queryFile.equalTo("objectId", this.fileQuestionnaireId);
      const file = await queryFile.first();

      const queryUser = new Parse.Query("User");
      queryUser.equalTo("objectId", this.user.objectId);
      const monUser = await queryUser.first();

      const queryResultat = new Parse.Query("file_done");
      queryResultat.equalTo("file", file);
      queryResultat.equalTo("user", monUser);
      queryResultat.ascending("createdAt");
      queryResultat.limit(10000);
      const listeResultat = await queryResultat.find();
      const labels = [];
      const datas = [];
      for (var i = 0; i < listeResultat.length; i = i + 1) {
        var jourExo = moment(listeResultat[i].createdAt);
        labels.push(jourExo.format("DD/MM/YYYY"));
        var note =
          listeResultat[i].attributes.note === undefined
            ? 0
            : listeResultat[i].attributes.note;
        datas.push(note);
      }
      const mesDonnees = {
        labels: labels,
        datasets: [
          {
            label: "Note",
            data: datas,
            backgroundColor: "rgba(77, 160, 255, 0.2)",
            borderColor: "rgba(77, 160, 255, 1)",
          },
        ],
      };

      var queryQuestionnaire = new Parse.Query("question");
      queryQuestionnaire.equalTo(
        "questionnaire",
        file.attributes.questionnaire_
      );
      var queryAnswer = new Parse.Query("question_answer");
      queryAnswer.matchesQuery("question", queryQuestionnaire);
      var listeAnswer = await queryAnswer.find();

      var listeMaxPointByQuestion = listeAnswer.reduce((acc, value) => {
        if (acc.length === undefined) {
          acc = [];
          acc.push({
            id: value.attributes.question.id,
            points: value.attributes.points,
          });
          return acc;
        }
        var trouve = false;
        for (var i = 0; i < acc.length; i = i + 1) {
          var item = acc[i];
          if (
            item.id === value.attributes.question.id &&
            item.points < value.attributes.points
          ) {
            item.points = value.attributes.points;
            trouve = true;
          }
          if (item.id === value.attributes.question.id) trouve = true;
        }
        if (!trouve)
          acc.push({
            id: value.attributes.question.id,
            points: value.attributes.points,
          });
        return acc;
      }, {});

      var max = listeMaxPointByQuestion.reduce((acc, valeur) => {
        return acc + valeur.points;
      }, 0);
      this.options.scales.yAxes[0].ticks.max = max;
      var chart = this.$refs.chart;
      var ctx = chart.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: this.options,
      });
    },
  },
  async mounted() {
    await this.getHistorique();
  },
};
</script>
