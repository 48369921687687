var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"chart-result"},[_c('chartFcVue',{attrs:{"valeurX":_vm.valeurX,"tabRowFc":_vm.TabRowFC,"defaultOpenedDetails":_vm.DefaultOpenedDetails}}),_c('chartCcVue',{attrs:{"valeurX":_vm.ValeurCCX,"tabRowCc":_vm.TabRowCC,"defaultOpenedDetails":_vm.DefaultOpenedDetails}})],1),_c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-2"},[_c('div',{staticClass:"circleFCMoyenne1"},[_c('div',{staticClass:"circleFCMoyenne2"},[_c('p',{staticClass:"circleFCMoyenne3 has-text-weight-bold"},[_c('span',{staticClass:"is-size-4"},[_vm._v(" "+_vm._s(_vm.fcMoyenne ? _vm.fcMoyenne : "0")+" ")]),_vm._v(" bpm ")])])]),_c('p',{staticClass:"has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"},[_vm._v(" "+_vm._s(_vm.$t("moyenneFrequenceCardiaque"))+" ")])]),_c('div',{staticClass:"column is-2"},[_c('div',{staticClass:"circleFCMoyenne1"},[_c('div',{staticClass:"circleFCMoyenne4",style:({
              'background-color': _vm.couleurCCMoyenne,
            })},[_c('p',{staticClass:"circleFCMoyenne3 has-text-weight-bold"},[_c('span',{staticClass:"is-size-4"},[_vm._v(" "+_vm._s(_vm.ccMoyenne ? _vm.ccMoyenne : "0")+" ")]),_vm._v(" % ")])])]),_c('p',{staticClass:"has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"},[_vm._v(" "+_vm._s(_vm.$t("moyenneCc"))+" ")])]),_c('div',{staticClass:"column is-5",staticStyle:{"display":"flex","text-align":"center","flex-direction":"column","justify-content":"space-around","padding-bottom":"0%"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-size-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.valeurRouge ? _vm.valeurRouge : "0")+"% "),_c('div',{staticClass:"respi_progress_bar_result"},[_c('div',{staticClass:"cc_red_result",style:({
                  width: _vm.valeurRouge ? _vm.valeurRouge + '%' : 0 + '%',
                })})])]),_c('div',{staticClass:"column is-size-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.valeurOrange ? _vm.valeurOrange : "0")+" % "),_c('div',{staticClass:"respi_progress_bar_result"},[_c('div',{staticClass:"cc_orange_result",style:({
                  width: _vm.valeurOrange ? _vm.valeurOrange + '%' : 0 + '%',
                })})])]),_c('div',{staticClass:"column is-size-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.valeurVerte ? _vm.valeurVerte : "0")+"% "),_c('div',{staticClass:"respi_progress_bar_result"},[_c('div',{staticClass:"cc_green_result",style:({
                  width: _vm.valeurVerte ? _vm.valeurVerte + '%' : 0 + '%',
                })})])])]),_c('p',{staticClass:"has-text-black has-text-centered has-text-weight-semibold"},[_vm._v(" % "+_vm._s(_vm.$t("pourcentTempsPasseZoneCoherence"))+" ")])]),_c('div',{staticClass:"column is-3",staticStyle:{"display":"flex","text-align":"center","flex-direction":"column","justify-content":"space-around","padding-bottom":"4%"}},[_c('div',[_c('span',{staticClass:"has-text-weight-bold has-text-black has-text-centered is-size-2"},[_vm._v(" "+_vm._s(_vm.$t("duree"))+" ")]),_c('span',{staticClass:"has-text-weight-bold has-text-centered is-size-2 labelChrono",staticStyle:{"border":"2px solid rgba(255, 255, 255, 0.5)"}},[_vm._v(" "+_vm._s(_vm.timeOfExercice)+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }