<template>
  <div class="video-container-introduction">
    <div class="video-wrapper">
      <div class="video-player-introduction">
        <video :key="uniqueKey" ref="videoPlayer" controls>
          <source :src="currentVideo" type="video/mp4" />
        </video>
      </div>
      <div class="video-playlist-introduction">
        <div
          v-for="(video, index) in filteredVideos"
          :key="index"
          @click="selectVideo(video)"
        >
          <img
            src="../../../../assets/img/video_playlist.png"
            :class="{
              'selected-video': video.sources[0].src === currentVideo,
            }"
          />
          <span>{{ video.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uniqueKey: Number,
    currentVideo: String,
    videosBrique: Array,
  },
  methods: {
    selectVideo(video) {
      this.$emit("select-video", video);
    },
  },
  computed: {
    filteredVideos() {
      return this.videosBrique.filter((video) => video.name !== "");
    },
  },
};
</script>
