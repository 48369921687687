<template>
    <div class="form-container">
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label for="lastname">Nom</label>
          <input
            type="text"
            id="lastname"
            v-model.trim="user.lastname"
            placeholder="Votre nom complet"
            required
          />
        </div>
  
        <div class="form-group">
          <label for="firstname">Prénom</label>
          <input
            type="text"
            id="firstname"
            v-model.trim="user.firstname"
            placeholder="Votre prénom complet"
            required
          />
        </div>
  
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            v-model.trim="user.email"
            placeholder="Votre email"
            required
          />
        </div>
  
  
        <div class="form-group">
          <button type="submit" class="submit-button">
            Création de l'utilisateur
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: "CreationUserComponent",
    data() {
      return {
        user: {
          lastname: "",
          firstname: "",
          email: "",
          practiceType: "cabinet",
          accessDuration: "",
        },
      };
    },
    methods: {
      submitForm() {
        this.$emit("isLoadingActivitySpace", true);
        console.log(this.user);
        this.$emit("isLoadingActivitySpace", false);
      },
    },
  };
  </script>
  
  
<style scoped>
.form-container {
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus {
  border-color: #239380;
  box-shadow: 0 0 5px rgba(35, 147, 128, 0.5);
  outline: none;
}

.submit-button {
  background-color: #239380;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #218a78;
}

.div-user-licence {
  margin-bottom: 15px;
}
</style>
