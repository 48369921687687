import Parse from "parse";

export default {
  async updateUserLinkRelation(
    selectedUserId,
    addedFolderIds,
    removedFolderIds
  ) {
    try {
      // Fetch the user with the provided objectId
      const user = await this.fetchUser(selectedUserId);
      const linkRelation = user.relation("link");

      // Initialize arrays to keep track of links to add and remove
      let linksToRemove = [];
      let linksToAdd = [];

      // Process removed folder IDs
      await Promise.all(
        removedFolderIds.map(async (folderId) => {
          console.log("folderId in removedFolderIds:", folderId);
          // Query for the folder
          const folderQuery = new Parse.Query("folder");
          const folder = await folderQuery.get(folderId);
          const linkQuery = new Parse.Query("link");
          linkQuery.equalTo("folder", folder);
          const linkFromFolder = await linkQuery.first();
          console.log("linkFromFolder:", linkFromFolder);

          if (linkFromFolder) {
            var id = linkFromFolder.id;
            linksToRemove.push(id);
          }

        //   const fileQuery = new Parse.Query("file");
        //   const file = await fileQuery.get(folderId);
        //   const linkFileQuery = new Parse.Query("link");
        //   linkFileQuery.equalTo("file", file);
        //   const linkFromFile = await linkFileQuery.first();
        //   console.log("linkFromFile:", linkFromFile);

        //   if (linkFromFile) {
        //     var id = linkFromFile.id;
        //     linksToRemove.push(id);
        //   }
         
        })
      );

      // Process added folder IDs
      await Promise.all(
        addedFolderIds.map(async (folderId) => {
          console.log("folderId in addedFolderIds:", folderId);
          // Query for the folder
          const folderQuery = new Parse.Query("folder");
          const folder = await folderQuery.get(folderId);
          const linkQuery = new Parse.Query("link");
          linkQuery.equalTo("folder", folder);
          const linkFromFolder = await linkQuery.first();
          console.log("linkFromFolder:", linkFromFolder);

          if (linkFromFolder) {
            var id = linkFromFolder.id;
            linksToAdd.push(id);
          }

          // Query for the file
        //   const fileQuery = new Parse.Query("file");
        //   const file = await fileQuery.get(folderId);
        //   const linkFileQuery = new Parse.Query("link");
        //   linkFileQuery.equalTo("file", file);
        //   const linkFromFile = await linkFileQuery.first();
        //   console.log("linkFromFile:", linkFromFile);

        //   if (linkFromFile) {
        //     var id = linkFromFile.id;
        //     linksToAdd.push(id);
        //   }
        })
      );
      console.log("Modified links:", linksToRemove, linksToAdd, selectedUserId);
      // Call the Parse Cloud function to modify the user's link relation
      await Parse.Cloud.run("modifyUserLinkRelation", {
        userObjectId: selectedUserId,
        linksToRemove,
        linksToAdd,
      });

      console.log("Link relation updated successfully.");
      return { success: true, linksToRemove, linksToAdd };
    } catch (error) {
      console.error("Error updating link relation:", error);
      return { success: false, error };
    }
  },

  // Fetch user by objectId
  async fetchUser(userObjectId) {
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo("objectId", userObjectId);
    return await userQuery.first();
  },
};
