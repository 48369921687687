import Parse from "parse";

export async function callDataForProgrammes(IdUtilisateur, SeanceSelected) {
  //console.log('CallDataForProgrammes', IdUtilisateur, SeanceSelected);
  const queryFolder = new Parse.Query("folder");
  const queryLink = new Parse.Query("link");
  const queryFile = new Parse.Query("file");
  const queryFileDone = new Parse.Query("file_done");
  const queryUser = new Parse.Query("User");
  const queryGameType = new Parse.Query("game_type");

  queryFolder.equalTo("objectId", SeanceSelected);
  queryLink.matchesQuery("parent_folder", queryFolder);

  try {
    const user = await queryUser.equalTo("objectId", IdUtilisateur).first();
    //console.log('User trouvé:', user);

    let fileDone = [];
    if (user.attributes.therapeute !== true) {
      //console.log('User non therapeute');
      fileDone = await queryFileDone.matchesQuery("user", queryUser).limit(1000).find();
    }

    const [folder, link, file, gameType] = await Promise.all([
      queryFolder.find(),
      queryLink.find(),
      queryFile.find(),
      queryGameType.find(),
    ]);
    console.log(folder, link, file, gameType);
    const element_link2 = link;
    const filesLink = element_link2.map((el) => {
      const file = el.get("file");
      const order = el.attributes.order;
      return {
        attributes: {
          ...file.attributes,
          order: order,
        },
        id: file.id,
      };
    });

    filesLink.sort((a, b) => a.attributes.order - b.attributes.order);

    if (user.attributes.therapeute === true) {
      for (let i = 0; i < filesLink.length; i++) {
        filesLink[i].to_completed = true;
        filesLink[i].completed = false;
      }
    } else {
      const FileDone = fileDone.map((el) => el.get("file"));
      FileDone.forEach((fileDone) => {
        const fileLink = filesLink.find(
          (fileLink) => fileLink.id === fileDone.id
        );
        if (fileLink) {
          fileLink.completed = true;
        }
      });

      for (let i = 0; i < filesLink.length; i++) {
        if (filesLink[i].completed === undefined) {
          filesLink[i].to_completed = true;
          break;
        } else {
          filesLink[i].to_completed = false;
          if (filesLink[i].completed !== true) {
            filesLink[i].completed = false;
          }
        }
      }
    }

    return {
      filesLink,
    };
  } catch (error) {
    console.error("ERROR:", error);
    return null;
  }
}